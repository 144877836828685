import config from "../../config";
import $ from "jquery";
import { useEffect, useState } from "react";
import "./style.css";
import leftImg from "../../images/dashboard/loginImage.png";
// import bottomImg from "../../images/login_bottom.png";
import { useFormik } from "formik";
import loginSchema from "../../validations/loginSchema";
import { inputChangeFocus, inputOnlyNumber } from "../../utils";
import { Link, useLocation } from "react-router-dom";
import btmImg from "../../images/dashboard/botmmsg.png";
import Content from "../../images/dashboard/Content.png";
import Breadcrumb from "../Auth/components/Breadcrumb";

function Login() {
  const location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
  }, [error]);

  const loginFormik = useFormik({
    initialValues: { mobile: location?.state?.mobile || "", pin: [] },
    validationSchema: loginSchema.validateLogin(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setIsSubmit(true);
      await $.post(
        `${config.apiUrl}/validatePin`,
        JSON.stringify({
          user_mob: values.mobile,
          pin: values.pin.join(""),
          envinfo: "Web",
          versioninfo: "1.0",
          otherinfo: "{}",
        })
      )
        .done(function (res) {
          setError("");
          localStorage.setItem(
            "go-ticket",
            JSON.stringify({ token: res.data.token, userData: res.data })
          );
          window.location.href = config.baseUrl + "/dashboard";
        })
        .catch(function (err) {
          setError(err.responseJSON.data.message);
        })
        .always(function () {
          setIsSubmit(false);
        });
    },
  });

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row g-0 vh-100  ">
          <div className="col-sm-7  position-relative">
            {/* <div className="BreadcrumbPaths">
              <img src={Content} className="imgsfluid" alt="" />
              <div style={{ marginTop: "12px", marginLeft: "10px" }}>
                <Breadcrumb path={["goTicket.pro"]} />
              </div>
            </div> */}
            <div className="row h-100 justify-content-center align-items-center my-0">
              <p
                className="position-absolute bottom-0 start-0 img-fluid"
                // style={{ marginLeft: "44px", marginBottom: "25px" }}
              >
                {" "}
                © goticketpro
              </p>
              <p
                className="position-absolute bottom-0 start-0 img-fluid"
                style={{ marginLeft: "650px" }}>
                {" "}
                <img src={btmImg} alt="" /> help@goticketpro
              </p>

              <div className="col-sm-8">
                <form onSubmit={loginFormik.handleSubmit} autoComplete="off">
                  <div className="card py-4 px-3">
                    <div className="card-body">
                      <p className="mb-1">Welcome back</p>
                      <h1 className="mb-4">Login to your account</h1>
                      <div className="mb-4">
                        <label htmlFor="mobile">Mobile Number <span className="text-danger">*</span></label>
                        <div className="row g-0">
                          <div className="col">
                            <input
                              autoFocus={!Boolean(location?.state?.mobile)}
                              type="text"
                              className="form-control"
                              style={{ boxShadow: "none" }}
                              maxLength="12"
                              onKeyDown={inputOnlyNumber}
                              onChange={(event) => {
                                const re = /^[0-9]*$/;

                                if (
                                  event.target.value === "" ||
                                  re.test(event.target.value)
                                ) {
                                  loginFormik.setFieldValue(
                                    "guest_type",
                                    event.target.value
                                  );
                                }
                              }}
                              {...loginFormik.getFieldProps("mobile")}
                            />
                          </div>
                        </div>
                        {loginFormik.touched.mobile &&
                        loginFormik.errors.mobile ? (
                          <div
                            className="alert alert-danger text-center small py-1"
                            style={{ marginTop: "22px" }}>
                            {loginFormik.errors.mobile}
                          </div>
                        ) : null}
                      </div>

                      <div className={`${error ? "mb-4" : "mb-5"}`}>
                        <label htmlFor="pin" className={`mb-2`}>
                          Enter 4 digit PIN below <span className="text-danger">*</span>
                        </label>
                        <div className="row g-0">
                          <div className="col d-flex">
                            <input
                              autoFocus={Boolean(location?.state?.mobile)}
                              type="text"
                              className="form-control otp"
                              maxLength="1"
                              name="pin[0]"
                              id="pin0"
                              onKeyDown={inputOnlyNumber}
                              onKeyUp={(e) =>
                                inputChangeFocus(e, "pin1", "pin0")
                              }
                              onChange={loginFormik.handleChange}
                              onBlur={loginFormik.handleBlur}
                            />
                            <input
                              type="text"
                              // disabled= {loginFormik.pin0 || ""}
                              className="form-control otp"
                              maxLength="1"
                              name="pin[1]"
                              id="pin1"
                              onChange={loginFormik.handleChange}
                              onKeyDown={inputOnlyNumber}
                              onKeyUp={(e) =>
                                inputChangeFocus(e, "pin2", "pin0")
                              }
                              onBlur={loginFormik.handleBlur}
                            />
                            <input
                              type="text"
                              className="form-control otp"
                              maxLength="1"
                              name="pin[2]"
                              id="pin2"
                              onChange={loginFormik.handleChange}
                              onKeyDown={inputOnlyNumber}
                              onKeyUp={(e) =>
                                inputChangeFocus(e, "pin3", "pin1")
                              }
                              onBlur={loginFormik.handleBlur}
                            />
                            <input
                              type="text"
                              className="form-control otp"
                              maxLength="1"
                              name="pin[3]"
                              id="pin3"
                              onChange={loginFormik.handleChange}
                              onKeyDown={inputOnlyNumber}
                              onKeyUp={(e) =>
                                inputChangeFocus(e, "pin3", "pin2")
                              }
                              onBlur={loginFormik.handleBlur}
                            />
                          </div>
                        </div>
                        {loginFormik.touched.pin && loginFormik.errors.pin ? (
                          <div
                            className="alert alert-danger text-center small py-1"
                            style={{ marginTop: "22px" }}>
                            {loginFormik.errors.pin}
                          </div>
                        ) : null}
                      </div>

                      {error ? (
                        <div className="alert alert-danger text-center small py-1">
                          {error}
                        </div>
                      ) : null}

                      <div className="d-grid mb-3">
                        <button
                          style={{ background: "#FE2F7B", borderRadius: "8px" }}
                          className="btn btn-primary"
                          type="submit"
                          disabled={isSubmit}>
                          {isSubmit && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                          )}
                          Login
                        </button>
                      </div>
                      <p className="text-center text-grey">
                        Forgot Your PIN?{" "}
                        <strong className="text-primary" role="button">
                          <Link
                            to="/forgot-pin"
                            // to={`/forgot-pin` + loginFormik.user_mob}
                            // state={{ team: loginFormik }}
                            className="text-primary text-decoration-none">
                            Click Here
                          </Link>
                        </strong>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <img
              src={leftImg}
              className="img-fluid w-100 "
              alt=""
              id="leftImg"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
