import { Link, NavLink } from "react-router-dom";
import twitter from "../../images/twitter.png";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import footerDots from "../../images/footer_dots.png";
import footerHalfCircle2 from "../../images/footer_half-circle-2.png";
import appstore from "../../images/appstore.png";
import playstore from "../../images/playstore.png";
import footerMobile from "../../images/footer_mobile.png";
import footerHalfCircle from "../../images/footer_half-circle.png";
import goTicketLogo from "../../images/goTicketLogo.png";
import { Form } from "react-bootstrap";
import logo from "../../images/icons/LOGO goInvitepro.svg";
// import namelogo from "../../images/icons/namelogo.png";
import namelogo from "../../images/icons/logoName.png";

function Footer() {
  // return (
  //     <>
  //         <section id="footer" className="pt-7">
  //             <div id="newsletter" className="position-relative">
  //                 <img src={footerHalfCircle}
  //                      className="img-fluid d-none d-sm-block position-absolute bottom-0 end-0 p-5" alt=""/>
  //                 <img src={footerHalfCircle}
  //                      className="img-fluid d-none d-sm-block position-absolute bottom-0 start-0 pb-5 img-flip-x"
  //                      alt=""/>
  //                 <div className="container">
  //                     <div className="row py-5" id="ready-to-start">
  //                         <div className="offset-sm-1 col-sm-4">
  //                             <h2>Ready to Start?</h2>
  //                             <p className="mb-3">Indian users can now manage their finances from wherever they are
  //                                 through the Zoho
  //                                 Books app for iOS and Android.</p>
  //                             <p className="mb-2 fw-bold">Download On</p>
  //                             <div>
  //                                 <Link to="/"><img src={playstore} className="img-fluid me-3" alt=""/></Link>
  //                                 <Link to="/"><img src={appstore} className="img-fluid" alt=""/></Link>
  //                             </div>
  //                         </div>
  //                         <div className="col-sm-6 text-end position-relative">
  //                             <img src={footerMobile}
  //                                  className="img-fluid d-none d-sm-block position-absolute bottom-0 end-0"
  //                                  style={{height: "23rem"}}
  //                                  alt=""/>
  //                         </div>
  //                     </div>
  //                     <div className="row py-6" id="subscribe">
  //                         <div className="offset-sm-1 col-sm-5 mb-4 mb-sm-0">
  //                             <h2 className="text-center text-sm-start">Subscribe to our Newsletter!</h2>
  //                             <p className="text-center text-sm-start">Keep up with our latest news and offers.</p>
  //                         </div>
  //                         <div className="offset-sm-1 col-sm-4">
  //                             <input type="text" className="form-control d-inline-block"
  //                                    placeholder="Your email address"/>
  //                             <button type="button" className="btn d-inline-block">Subscribe</button>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>
  //             <div id="navigation">
  //                 <img src={footerDots} className="img-fluid d-none d-sm-block position-absolute top-0 start-0"
  //                      alt=""/>
  //                 <img src={footerHalfCircle2}
  //                      className="img-fluid p-5 d-none d-sm-block position-absolute bottom-0 end-0" alt=""/>
  //                 <div className="container">
  //                     <div className="row pb-5 pt-7 align-items-center">
  //                         <div className="col-sm-3 mb-4 mb-sm-0">
  //                             <h6 className="logo">Logo</h6>
  //                             <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all
  //                                 exercise blessing.</p>
  //                         </div>
  //                         <div className="offset-sm-1 col-sm-6 d-flex justify-content-start nav-list mb-4 mb-sm-0">
  //                             <div className="me-6">
  //                                 <h6>Quick Links</h6>
  //                                 <ol className="list-unstyled">
  //                                     <li><NavLink to="/">Home</NavLink></li>
  //                                     <li><NavLink to="/">Pricing</NavLink></li>
  //                                     <li><NavLink to="/">About Us</NavLink></li>
  //                                     <li><NavLink to="/">Blog</NavLink></li>
  //                                     <li><NavLink to="/">Careers</NavLink></li>
  //                                     <li><NavLink to="/">FAQ</NavLink></li>
  //                                 </ol>
  //                             </div>
  //                             <div className="me-6">
  //                                 <h6>Product</h6>
  //                                 <ol className="list-unstyled">
  //                                     <li><NavLink to="/">Invoicing Platform</NavLink></li>
  //                                     <li><NavLink to="/">Accounting Plateform</NavLink></li>
  //                                     <li><NavLink to="/">Create Proposal</NavLink></li>
  //                                     <li><NavLink to="/">Contracts</NavLink></li>
  //                                 </ol>
  //                             </div>
  //                             <div className="me-6">
  //                                 <h6>Legal</h6>
  //                                 <ol className="list-unstyled">
  //                                     <li><NavLink to="/">Privacy</NavLink></li>
  //                                     <li><NavLink to="/">Invoice Template</NavLink></li>
  //                                     <li><NavLink to="/">Tuturoial</NavLink></li>
  //                                     <li><NavLink to="/">Terms & Conditions</NavLink></li>
  //                                 </ol>
  //                             </div>
  //                         </div>
  //                         <div className="col-sm-2 align-self-start">
  //                             <div>
  //                                 <h6>Follow Us</h6>
  //                                 <ul className="list-unstyled d-flex">
  //                                     <li><Link to="/"><img src={facebook} className="img-fluid" alt=""/></Link></li>
  //                                     <li><Link to="/"><img src={twitter} className="img-fluid mx-3" alt=""/></Link>
  //                                     </li>
  //                                     <li><Link to="/"><img src={instagram} className="img-fluid" alt=""/></Link></li>
  //                                 </ul>
  //                             </div>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>
  //             <div id="copyright">
  //                 <div className="container">
  //                     <div className="row py-4">
  //                         <div className="col text-center">&copy; 2022 Cabbagesoft Technologies Pvt.Ltd. All
  //                             Rights reserved.
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div>
  //         </section>
  //     </>
  // );

  const links1 = ["Overview", "Pricing", "Careers", "Help", "Privacy"];
  const links2 = [
    "Team",
    "Plan",
    "Billing",
    "Email",
    "Notifications",
    "Integrations",
    "API",
  ];

  return (
    <>
      <div className="position-relative bottom-0 px-2 py-4 w-100 text-center">
        <div className="d-flex flex-column gap-2 gam-md-4">
          <div className="pb-3 px-0">
            {/* <img src={goTicketLogo} alt="goTicket Logo" className="p-2" />{" "} */}
            <img
              src={logo}
              alt="goTicket Logo"
              style={{ width: "25px", height: "25px" }}
            />
            {/* <span className="fw-semibold">GoInvite.pro</span> */}
            <img src={namelogo} alt="" style={{ width: "130px",marginLeft:"2px" }} />
          </div>
          {/* <div>
            <ul
              style={{ listStyleType: "none" }}
              className="d-flex justify-content-center gap-2 gap-md-3 px-3 flex-wrap"
            >
              {links1.map((link) => (
                <li className="fw-semibold text-theme-gray">{link}</li>
              ))}
            </ul>
          </div>
          <div>
            <ul
              style={{ listStyleType: "none" }}
              className="d-flex justify-content-center gap-3 px-3 flex-wrap"
            >
              {links2.map((link) => (
                <li className="text-theme-gray" style={{ fontSize: "90%" }}>
                  {link}
                </li>
              ))}
            </ul>
          </div> */}
        </div>
        {/* <hr />
        <div className="d-flex flex-column flex-md-row justify-content-between p-3 gap-4">
          <div className="d-flex gap-3 justify-content-between">
            <Form.Control
              size="sm"
              md="lg"
              style={{ fontFamily: "sans-serif" }}
              className="py-1 px-2"
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
            />
            <button className="bg-theme-pink text-white px-2 py-1 border border-0 rounded-2">
              Subscribe
            </button>
          </div>
          <div className="text-theme-lightgray">
            &copy; 2023 goTicket.pro All rights reserved.
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Footer;
