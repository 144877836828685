import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom/client";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import App from "./App";
import config from "./config";

$.ajaxSetup({
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('go-ticket'))?.token
    }
});

Bugsnag.start({
    apiKey: '4f45ef2a3e0d04c0c4f11f4f79dd3ab1',
    plugins: [new BugsnagPluginReact()],
    autoTrackSessions: false,
    autoDetectErrors: false,
    enabledBreadcrumbTypes: false
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const ErrorView = () =>
    <div className="container">
        <h2>500</h2>
        <h3>Oops, something went wrong...</h3>
        <p>Otherwise, <a href={`${config.baseUrl}/dashboard`}>Click here</a> to redirect to homepage.</p>
    </div>

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ErrorBoundary FallbackComponent={ErrorView}>
        <App/>
    </ErrorBoundary>
);
