import React, { useEffect, useState } from "react";
import { Form as FormikForm, Formik } from "formik";
import { Form, FormControl } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import eventBanner from "../../images/eventBanner.png";
import NavBar from "../../components/partials/Navbar";
import Footer from "../../components/partials/Footer";
import { Calendar4, GeoAlt } from "react-bootstrap-icons";
import { apiGuestRegister, apiPublishedList } from "../../api/master";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Modal } from "react-bootstrap";

import eventsSchema from "../../validations/events";
import { toast, Toaster } from "react-hot-toast";
import moment from "moment";
import { apiState } from "../../api/stateAndCity";

const EventDetails = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA05QbMXzAL_4ouvn7kWzM0o5Fj74kLp6A",
  });
  const [clipped, setClipped] = useState(true);

  const { id } = useParams();
  const location = useLocation();
  const { pathname } = useLocation();
  const [event, setEvent] = useState(location.state?.event || []);
  const [isPast, setIsPast] = useState(location.state?.is_past || 0);
  const [showRegister, setShowRegister] = useState(false);

  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    reffered_by: "",
    event_id: id,
  };

  useEffect(() => {
    apiPublishedList({ event_id: id })
      .then((res) => {
        console.log("event", res.data.data.list[0]);
        setEvent(res.data.data.list[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const today = new Date();
  const regEndDate = new Date(event?.reg_ed_dt);
  const regStartDate = new Date(event?.reg_st_dt);

  const eventStartDate = new Date(event?.st_dt);
  const eventEndDate = new Date(event?.ed_dt);
  const startDay = eventStartDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  const startYear = eventStartDate.toLocaleDateString("en-US", {
    year: "numeric",
  });
  const startTime = eventStartDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  const endDay = eventEndDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  const endTime = eventEndDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const [center, setCenter] = useState({
    lat: 19.076,
    lng: 72.8777,
  });

  const [user, setUser] = useState({});
  useEffect(function () {
    if (localStorage.getItem("go-ticket")) {
      setUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
    } else {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (
      typeof event?.latLng === "string" &&
      event?.latLng !== "[object Object]"
    )
      setCenter(JSON.parse(event?.latLng));
  }, [event]);

  console.log("event", event)

  // useEffect(() => {
  //   const getStateName = async () => {
  //     await apiState().then((result) => {
  //       let stateData = result?.data?.data.list || [];
  //       let newStateArray = stateData.filter((ele, ind) => ele.state_id == event?.state_id).map((item) => {
  //         return {
  //           label: item.name,
  //           value: item.state_id,
  //         };
  //       });
  //       // setStateValue(newStateArray);
  //       console.log("stateData", newStateArray)
  //     });
  //   }
  //   getStateName()
  // }, [event?.state_id])
  const handleClickOpenCalender = () => {
    const title = event.name;
    const location = `${event.address || ""}`;
    // const location = `${event.address || ""}  ${event.cities || ""} ${event.state_id || ""}  ${event.pincode || ""}`;
    const details = '';
    const startDate = moment(event?.st_dt).format("YYYYMMDDTHHmmss");
    const endDate = moment(event?.ed_dt).format("YYYYMMDDTHHmmss");
    const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(title)}&dates=${startDate}/${endDate}&details=${encodeURIComponent(details)}&location=${encodeURIComponent(location)}&sf=true&output=xml`;
    // window.open(googleCalendarUrl, '_blank');
    const anchor = document.createElement('a');
    anchor.href = googleCalendarUrl;
    anchor.target = '_blank';
    anchor.rel = 'noopener noreferrer';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };


  // const handleClickOpenCalender = () => {
  //   const event = {
  //     summary: "vipul event",
  //     // location: "mumbai,maharastra 400101",
  //     description: 'Event Details',
  //     start: {
  //       dateTime: '2024-07-15T10:00:00-07:00',
  //       timeZone: 'America/Los_Angeles',
  //     },
  //     end: {
  //       dateTime: '2024-07-15T11:00:00-07:00',
  //       timeZone: 'America/Los_Angeles',
  //     },
  //   };

  //   ApiCalendar.createEventFromNow(event)
  //     .then(result => {
  //       console.log(result);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };
  return (
    <>
      <div>
        <Toaster
          toastOptions={{
            success: {
              iconTheme: {
                primary: "white",
                secondary: "green",
              },
            },
            error: {
              iconTheme: {
                primary: "white",
                secondary: "red",
              },
            },
          }}
          containerStyle={{
            top: 600,
            left: 600,
            bottom: 600,
            right: 600,
          }}
        />
      </div>
      <NavBar user={user} />
      <div className="my-4 mx-2 mx-md-4 mx-lg-5 px-lg-5 d-flex flex-column gap-3 gap-md-5">
        {/* Banner */}
        <div className="d-none d-xl-block mx-auto">
          <img
            src={event?.event_banner}
            alt=""
            className="rounded-4"
            style={{ width: "78em", height: "30em" }}
          />
        </div>
        <div className="d-none d-lg-block d-xl-none mx-auto">
          <img
            src={event?.event_banner}
            alt=""
            className="rounded-4"
            style={{ width: "52em", height: "25em" }}
          />
        </div>
        <div className="d-none d-md-block d-lg-none mx-auto">
          <img
            src={event?.event_banner}
            alt=""
            className="rounded-4"
            style={{ width: "42em", height: "20em" }}
          />
        </div>
        <div className="d-block d-md-none d-lg-none mx-auto">
          <img
            src={event?.event_banner}
            alt=""
            className="rounded-4"
            style={{ width: "18em", height: "12em" }}
          />
        </div>
        {/* Event Name, Event Details, Book tikcet */}
        <div className="d-flex d-lg-none flex-column gap-3 align-items-center justify-content-center">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h2 className="text-center">
              {event?.name} - {event?.event_tag_line}
            </h2>
            <p
              className="text-theme-darkgray text-center"
              style={{ fontSize: "0.8em" }}
            >
              {startDay}, {startYear} <span>| {event?.address}</span>{" "}
              <span>
                |{" "}
                {event?.is_paid ? (
                  <span className="text-theme-pink">
                    ₹ {event?.amount} Onwards
                  </span>
                ) : (
                  <span className="text-theme-pink">Free</span>
                )}
              </span>
            </p>
          </div>
          <div>
            {!isPast ? (
              event?.is_self_reg === 1 ? (
                today >= regStartDate && today <= regEndDate ? (
                  <button
                    className="px-5 py-3 border border-0 bg-theme-pink rounded-3 text-white"
                    onClick={() => setShowRegister(true)}
                  >
                    Book Ticket
                  </button>
                ) : null
              ) : null
            ) : null}
          </div>
        </div>
        <div className="d-none d-lg-flex gap-3 gap-lg-4 align-items-center justify-content-between">
          <div className="d-flex flex-column justify-content-between align-content-center">
            <h2 className="">
              {event?.name} - {event?.event_tag_line}
            </h2>
            <p className="text-theme-darkgray" style={{ fontSize: "1em" }}>
              {startDay}, {startYear} <span>| {event?.address}</span>{" "}
              <span>
                |{" "}
                {event?.is_paid ? (
                  <span className="text-theme-pink">
                    ₹ {event?.amount} Onwards
                  </span>
                ) : (
                  <span className="text-theme-pink">Free</span>
                )}
              </span>
            </p>
          </div>
          <div>
            {!isPast ? (
              event?.is_self_reg === 1 ? (
                today >= regStartDate && today <= regEndDate ? (
                  <button
                    className="px-5 py-3 border border-0 bg-theme-pink rounded-3 text-white"
                    onClick={() => setShowRegister(true)}
                  >
                    Book Ticket
                  </button>
                ) : null
              ) : null
            ) : null}
          </div>
        </div>
        {/* Time and Location */}
        <div className="d-flex d-md-none flex-column gap-3 align-items-center justify-content-center mt-4 mx-4">
          <h2>When and Where</h2>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column gap-4 align-items-center">
              <div className="d-flex gap-2 align-items-start">
                <div
                  className="bg-theme-lightpink rounded-2"
                  style={{ padding: "0.8em" }}
                >
                  <Calendar4 className="text-theme-pink" size={20} />
                </div>
                <div className="d-flex flex-column">
                  <h6>Date and time</h6>
                  <p style={{ fontSize: "0.8em" }} className="text-theme-gray">
                    {startDay} • {startTime} - {endDay} • {endTime}
                  </p>
                </div>
              </div>
                <button className="text-theme-pink bg-theme-lightpink border border-0 rounded-2 px-3 py-2"
                  onClick={handleClickOpenCalender}
                >
                  Add to Calendar
                </button>
              <div>
              </div>
            </div>
            <hr />
            <div className="d-flex flex-column gap-4 align-items-center">
              <div className="d-flex gap-2 align-items-start">
                <div
                  className="bg-theme-lightpink rounded-2"
                  style={{ padding: "0.8em" }}
                >
                  <GeoAlt className="text-theme-pink" size={20} />
                </div>
                <div className="d-flex flex-column">
                  <h6>Location</h6>
                  <p
                    style={{ fontSize: "0.8em" }}
                    className="text-theme-gray text-wrap"
                  >
                    {event?.map_address ? event?.map_address : event?.address}
                  </p>
                </div>
              </div>
              <div>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${center?.lat},${center?.lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-theme-pink"
                >
                  <button className="text-theme-pink bg-theme-lightpink border border-0 rounded-2 px-3 py-2">
                    Go to Maps
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Medium and large screens time and location */}
        <div className="d-none d-md-flex d-xl-none flex-column gap-3 justify-content-center mt-4">
          <h2>When and Where</h2>
          <div className="d-flex gap-2 justify-content-start">
            <div className="d-flex flex-column gap-4 align-items-center w-50">
              <div className="d-flex gap-2 align-items-start">
                <div
                  className="bg-theme-lightpink rounded-2"
                  style={{ padding: "0.8em" }}
                >
                  <Calendar4 className="text-theme-pink" size={20} />
                </div>
                <div className="d-flex flex-column">
                  <h6>Date and time</h6>
                  <p style={{ fontSize: "0.8em" }} className="text-theme-gray">
                    {startDay} • {startTime} - {endDay} • {endTime}
                  </p>
                </div>
              </div>
              <div>
                <button className="text-theme-pink bg-theme-lightpink border border-0 rounded-2 px-3 py-2">
                  Add to Calendar
                </button>
              </div>
            </div>
            <hr className="vertical-hr" />
            <div className="d-flex flex-column gap-4 align-items-center w-50 text-break">
              <div className="d-flex gap-2 align-items-start">
                <div
                  className="bg-theme-lightpink rounded-2"
                  style={{ padding: "0.8em" }}
                >
                  <GeoAlt className="text-theme-pink" size={20} />
                </div>
                <div className="d-flex flex-column">
                  <h6>Location</h6>
                  <p
                    style={{ fontSize: "0.8em" }}
                    className="text-theme-gray text-wrap"
                  >
                    {event?.map_address ? event?.map_address : event?.address}
                  </p>
                </div>
              </div>
              <div>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${center?.lat},${center?.lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-theme-pink"
                >
                  <button className="text-theme-pink bg-theme-lightpink border border-0 rounded-2 px-3 py-2">
                    Go to Maps
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Extra large screen time and location */}
        <div className="d-none d-xl-flex flex-column gap-3 justify-content-center mt-4">
          <h2>When and Where</h2>
          <div className="d-flex gap-2 justify-content-start">
            <div className="d-flex flex-column gap-5 align-items-center w-50">
              <div className="d-flex gap-2 align-items-start">
                <div
                  className="bg-theme-lightpink rounded-2"
                  style={{ padding: "1em" }}
                >
                  <Calendar4 className="text-theme-pink" size={32} />
                </div>
                <div className="d-flex flex-column">
                  <h6 className="fs-4">Date and time</h6>
                  <p style={{ fontSize: "1.2em" }} className="text-theme-gray">
                    {startDay} • {startTime} - {endDay} • {endTime}
                  </p>
                </div>
              </div>
              <div>
                <button className="text-theme-pink bg-theme-lightpink border border-0 rounded-2 px-4 py-3 fs-5"
                  onClick={handleClickOpenCalender}
                >
                  Add to Calendar
                </button>
              </div>
            </div>
            <hr className="vertical-hr-xl" />
            <div className="d-flex flex-column gap-5 align-items-center w-50 text-break">
              <div className="d-flex gap-2 align-items-start">
                <div
                  className="bg-theme-lightpink rounded-2"
                  style={{ padding: "0.8em" }}
                >
                  <GeoAlt className="text-theme-pink" size={32} />
                </div>
                <div className="d-flex flex-column">
                  <h6 className="fs-4">Location</h6>
                  <p
                    style={{ fontSize: "1.2em" }}
                    className="text-theme-gray text-wrap"
                  >
                    {event?.map_address ? event?.map_address : event?.address}
                  </p>
                </div>
              </div>
              <div>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${center?.lat},${center?.lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-theme-pink"
                >
                  <button className="text-theme-pink bg-theme-lightpink border border-0 rounded-2 px-4 py-3 fs-5">
                    Go to Maps
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* About and Map Small Screens */}
        <div className="d-flex d-md-none flex-column gap-3 align-items-center justify-content-center mt-4 mx-4">
          <div className="d-flex flex-column align-items-center text-center gap-2">
            <h2>About this event</h2>
            <div
              className={`text-theme-gray text-justify ${clipped ? "about-clamp" : ""
                }`}
              style={{ maxHeight: "40em" }}
            >
              {event?.desc ? event?.desc : "No details provided."}
            </div>
            <button
              onClick={() => setClipped(!clipped)}
              className="bg-transparent border border-0 text-theme-pink"
            >
              {clipped ? "Read More" : "Read Less"}
            </button>
          </div>
          <div className="w-100">
            {isLoaded && (
              <GoogleMap
                zoom={10}
                center={center}
                mapContainerStyle={{ width: "100%", height: 250 }}
              >
                {console.log(center)}
                <Marker position={center} draggable={false}></Marker>s
              </GoogleMap>
            )}
          </div>
        </div>
        {/* About and Map Medium and large Screen */}
        <div className="d-none d-md-flex d-xl-none gap-3 align-items-start justify-content-between mt-4">
          <div className="d-flex flex-column align-items-start gap-2 w-50">
            <h2>About this event</h2>
            <div
              className={`text-theme-gray text-justify ${clipped ? "about-clamp" : ""
                }`}
              style={{ maxHeight: "40em" }}
            >
              {event?.desc ? event?.desc : "No details provided."}
            </div>
            <button
              onClick={() => setClipped(!clipped)}
              className="bg-transparent border border-0 text-theme-pink p-0"
            >
              {clipped ? "Read More" : "Read Less"}
            </button>
          </div>
          <div className="w-50">
            {isLoaded && (
              <GoogleMap
                zoom={10}
                center={center}
                mapContainerStyle={{ width: "100%", height: 250 }}
              >
                {console.log(center)}
                <Marker position={center} draggable={false}></Marker>s
              </GoogleMap>
            )}
          </div>
        </div>
        {/* About and map extra large screen */}
        <div className="d-none d-xl-flex gap-5 align-items-start justify-content-between mt-4 mb-3">
          <div
            className="d-flex flex-column align-items-start gap-2"
            style={{ width: "40%" }}
          >
            <h2>About this event</h2>
            <div
              className={`text-theme-gray text-justify ${clipped ? "about-clamp" : ""
                }`}
              style={{ maxHeight: "40em" }}
            >
              {event?.desc ? event?.desc : "No details provided."}
            </div>
            <button
              onClick={() => setClipped(!clipped)}
              className="bg-transparent border border-0 text-theme-pink"
            >
              {clipped ? "Read More" : "Read Less"}
            </button>
          </div>
          <div className="w-50">
            {isLoaded && (
              <GoogleMap
                zoom={10}
                center={center}
                mapContainerStyle={{
                  width: "100%",
                  height: 350,
                }}
              >
                {console.log(center)}
                <Marker position={center} draggable={false}></Marker>s
              </GoogleMap>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        backdrop={"static"}
        keyboard={false}
        show={showRegister}
        size={"md"}
        onHide={() => setShowRegister(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Register for Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={eventsSchema.validateEventRegister()}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              console.log("values", values);
              apiGuestRegister(values)
                .then((res) => {
                  setShowRegister(false);
                  toast.success("Event registration successful", {
                    duration: 5000,
                    style: {
                      backgroundColor: "green",
                      color: "white",
                      padding: "10px",
                      height: "70px",
                      boxShadow: "2px 3px 8px black",
                    },
                  });
                })
                .catch((err) => {
                  console.log(err.response.data.data.message);
                  toast.error(err.response.data.data.message, {
                    duration: 5000,
                    style: { backgroundColor: "red", color: "white" },
                  });
                });
            }}
          >
            {({
              isSubmitting,
              handleChange,
              values,
              setFieldValue,
              errors,
            }) => (
              <>
                <FormikForm>
                  <div className="row  gy-2 mb-3">
                    <div className="col-md-6">
                      <label htmlFor="name">
                        Full Name {<span className="text-danger">*</span>}
                      </label>
                      <FormControl
                        type="text"
                        autoComplete="off"
                        maxLength={100}
                        onChange={(event) => {
                          const re = /^[A-Za-z0-9@#,\-&:()\[\]\s\\]*$/;

                          if (
                            event.target.value === "" ||
                            re.test(event.target.value)
                          ) {
                            setFieldValue("name", event.target.value);
                          }
                        }}
                        value={values.name}
                        name="name"
                        id="name"
                      />
                      {errors?.name ? (
                        <div className="text-danger small">{errors?.name} </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="mobile">
                        Mobile Number {<span className="text-danger">*</span>}
                      </label>
                      <FormControl
                        type="text"
                        autoComplete="off"
                        maxLength={10}
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;

                          if (
                            event.target.value === "" ||
                            re.test(event.target.value)
                          ) {
                            setFieldValue("mobile", event.target.value);
                          }
                        }}
                        value={values.mobile}
                        name="mobile"
                        id="mobile"
                      />
                      {errors?.mobile ? (
                        <div className="text-danger small">
                          {errors?.mobile}{" "}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email {<span className="text-danger">*</span>}</label>
                      <FormControl
                        type="text"
                        autoComplete="off"
                        onChange={(event) => {
                          setFieldValue("email", event.target.value);
                        }}
                        value={values.email}
                        name="email"
                        id="email"
                      />
                      {errors?.email ? (
                        <div className="text-danger small">
                          {errors?.email}{" "}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="reffered_by">Referred By</label>
                      <FormControl
                        type="text"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.reffered_by}
                        name="reffered_by"
                        id="reffered_by"
                      />
                    </div>
                    <div className="d-flex justify-content-end gap-1 mt-3">
                      <button
                        className="branchModalCancel"
                        onClick={() => {
                          setShowRegister(false);
                        }}
                        type="button"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        style={{ backgroundColor: "#FE2F7B" }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </FormikForm>
              </>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EventDetails;
