import { data } from "jquery";
import { request } from "./request";

let loginToken;
loginToken = JSON.parse(localStorage.getItem("go-ticket"))?.token ?? "";
const headers = {
  headers: {
    Authorization: `Bearer ${loginToken}`,
  },
};

//Branch Api Company

export const apiCreateCompany = (data) =>
  request("post", "/company/createCompany", data, headers);

export const apiEditCompany = (data) =>
  request("post", "/company/editCompany", data, headers);

export const apiCancelCompany = (data) =>
  request("post", "/company/cancelCompany", data, headers);

export const apiListCompany = (data) =>
  request("post", "/company/listCompany", data, headers);

//Branch Api user

export const apiCreateUser = (data) =>
  request("post", "/user/createUser", data, headers);

export const apiEditUser = (data) =>
  request("post", "/user/editUser", data, headers);

export const apiCancelUser = (data) =>
  request("post", "/user/cancelUser", data, headers);

export const apiListUser = (data) =>
  request("post", "/user/listUser", data, headers);

//Customer Api Group
// export const apiCreateCustomer = (data) => request('post', '/customer/createCustomer', data, headers);

// export const apiEditCustomer = (data) => request('post', '/customer/editCustomer', data,headers);

// export const apiCancelCustomer = (data) => request('post', '/customer/cancelCustomer', data,headers);

// export const apiListCustomer = data => request('post', '/customer/listCustomer', data, headers);

//Events
//Event list
export const apiEventList = (data) =>
  request("post", "/event/listEvent", data, headers);
//Event add
export const apiEventAdd = (data) =>
  request("post", "/event/createEvent", data, headers);
//Event edit
export const apiEventEdit = (data) =>
  request("post", "/event/editEvent", data, headers);
//Event deletev
export const apiEventDelete = (data) =>
  request("post", "/event/cancelEvent", data, headers);
//Get Assignment List
export const getAssignmentList = (data) =>
  request("post", "/event/listAssignEvent", data, headers);
//User role assign
export const ApiAssignUserRole = (data) =>
  request("post", "/event/assignEvent", data, headers);

// member appreved

export const apiMemberApproved = (data) =>
  request("post", "/transTeam/apprApprovTeamDtl", data, headers);

export const apiMemberApprovedEdit = (data) =>
  request("post", "/transTeam/editTeamDtl", data, headers);

export const apiTeamCon = (data) =>
  request("post", "/transTeam/getTeamConf", data, headers);

//Guests
//User role assign

export const ApiGuestlist = (data) =>
  request("post", "/guest/listGuest", data, headers);
// upload excel API
export const ApiGuestUploadExcel = (data) =>
  request("post", "/guest/guestAddBulk", data, headers);
// Update status excel
export const ApiGuestUpdateStatus = (data) =>
  request("post", "/guest/guestUpdateBulk", data, headers);

export const ApiGuestListType = () =>
  request("post", "/guestType/listGuestType", null, headers);
//Event Guest
export const apiGuestAdd = (data) =>
  request("post", "/guest/createGuest", data, headers);

//Communation
export const apiCommunation = (data) =>
  request("post", "/guest/notification", data, headers);

//Remove Guest
export const apiRemoveGuest = (data) =>
  request("post", "/guest/cancelGuest", data, headers);

//Event Area
//Event area list
export const apiEventAreaList = (data) =>
  request("post", "/eventArea/listEventArea", data, headers);
//Event area add
export const apiEventAreaAdd = (data) =>
  request("post", "/eventArea/createEventArea", data, headers);
//Event area edit
export const apiEventAreaEdit = (data) =>
  request("post", "/eventArea/editEventArea", data, headers);
//Event area delete
export const apiEventAreaDelete = (data) =>
  request("post", "/eventArea/cancelEventArea", data, headers);

//Guest Type
export const apiGuestType = (data) =>
  request("post", "/guestType/listGuestType", data, headers);

export const apiCreateGuestType = (data) =>
  request("post", "/guestType/createGuestType", data, headers);

export const apiEditGuestType = (data) =>
  request("post", "/guestType/editGuestType", data, headers);

export const apiCancelGuestType = (data) =>
  request("post", "/guestType/cancelGuestType", data, headers);

// team
export const apiTeamList = (data) =>
  request("post", "/team/listTeam", data, headers);

export const apiCancelTeam = (data) =>
  request("post", "/team/cancelTeam", data, headers);

export const apiCreateTeam = (data) =>
  request("post", "/team/createTeam", data, headers);

export const apiEditTeam = (data) =>
  request("post", "/team/editTeam", data, headers);

export const apiResendTeamLink = (data) =>
  request("post", "/team/resend", data, headers);

// Trans Team
export const apiGetTeamConf = (data, token) =>
  request(
    "post",
    `/transTeam/getTeamConf?token=${token}`,
    data
    // headers
  );
export const apiCreateTeamDtl = (data, token) =>
  request(
    "post",
    `/transTeam/createteamdtl?token=${token}`,
    data
    // headers
  );

export const apiSetWhatsappImage = (data) =>
  request("post", "/event/whatsappimage", data, headers);

// Published Events

export const apiPublishedList = (data) =>
  request("post", "/event/publishedlist", data, headers);

export const apiGuestRegister = (data) =>
  request("post", "/guest/guestRegister", data, headers);
