// import config from "../../config";
import NavBar from "../../components/partials/Navbar";
import Footer from "../../components/partials/Footer";
// import billing from "../../images/charts_billing.png";
// import checkmarkWhite from "../../images/checkmark_circle_white.png";
// import playstore from "../../images/playstore.png";
// import partners from "../../images/partners.png";
// import inventory from "../../images/manage_inventory.png";
// import checkmarkOrange from "../../images/checkmark_circle_orange.png";
// import salePurchase from "../../images/sale_purchase.png";
// import verifiedCustomer from "../../images/verified_customer.png";
// import gstFilling from "../../images/gst_filling.png";
// import whyUsIcon1 from "../../images/why_us_icon_1.png";
// import whyUsIcon2 from "../../images/why_us_icon_2.png";
// import whyUsIcon3 from "../../images/why_us_icon_3.png";
// import whyUsIcon4 from "../../images/why_us_icon_4.png";
// import whyUsIcon5 from "../../images/why_us_icon_5.png";
// import whyUsIcon6 from "../../images/why_us_icon_6.png";
// import testimonial from "../../images/testimonial.png";
// import doubleQuotes from "../../images/double_quotes.png";
// import star from "../../images/star.png";
import "./style.css";
import { useEffect, useState } from "react";
import HeroCarousel from "../../components/HeroCarousel/HeroCarousel";
import EventCardList from "../../components/EventCardList/EventCardList";
import Hero from "../Homepage/Hero";
import WhyChoose from "../Homepage/WhyChoose";

function Home() {
  const [user, setUser] = useState({});
  useEffect(function () {
    if (localStorage.getItem("go-ticket")) {
      setUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
    } else {
      setUser(null);
    }
  }, []);
  return (
    <>
      <NavBar user={user} />
      {/* <HeroCarousel /> */}
      <Hero />
      <EventCardList />
      <WhyChoose />
      <Footer />
    </>
  );
}

export default Home;
