import config from "../../config";
import $ from "jquery";
import Sidebar from "../../components/partials/Sidebar";
import Header from "../../components/partials/Header";
import Breadcrumb from "../../components/Breadcrumb";
import {useEffect, useState} from "react";
import "./style.css";
import Swal from "sweetalert2";
import {useFormik} from "formik";
import configSchema from "../../validations/configSchema";


const inputKeys = (e) => {
    let allowKeys = ['Tab', 'Backspace', 'Delete', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
    if (!(/[0-9A-Za-z#\-_/]/.test(e.key) || allowKeys.includes(e.key)) || (e.target.value.length > 9 && !allowKeys.includes(e.key)))
        e.preventDefault();
}


function SettingList(props) {
    const [initialState, setInitialState] = useState({
        comp_mode: "",
        configs: [
            {conf_type: "Purchase", prefix: ""},
            {conf_type: "PurchaseReturn", prefix: ""},
            {conf_type: "Payment", prefix: ""},
            {conf_type: "Sale", prefix: ""},
            {conf_type: "SaleReturn", prefix: ""},
            {conf_type: "Receipt", prefix: ""},
            {conf_type: "Expense", prefix: ""},
            {conf_type: "StockAdjustment", prefix: ""},
        ],
    })
    const [changeable, setChangeable] = useState(false);

    const configFormik = useFormik({
        initialValues: initialState,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: configSchema.validateUpdate(),
        onSubmit: (values, actions) => {
            $.post(`${config.apiUrl}/company/updateConfig`, JSON.stringify(values))
                .done(function (res) {
                    $.post(`${config.apiUrl}/company/listCompConfig`, function (res) {
                        props.setCompConfig(res.data);
                    });

                    Swal.fire({
                        title: "Success",
                        text: "Success",
                        confirmButtonText: "OK",
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        focusConfirm: true,
                    });
                })
                .catch(function (err) {
                    Swal.fire({
                        title: "Error",
                        text: err.responseJSON.message,
                        confirmButtonText: "OK",
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        focusConfirm: true,
                    });
                })
                .always(function () {
                    actions.setSubmitting(false)
                })
        }
    })

    function handleInputs(name, value) {
        if (name == "comp_mode") {
            configFormik.setFieldValue(name, value);
        } else {
            let index = configFormik.values.configs.findIndex(c => c.conf_type == name);
            configFormik.setFieldValue(`configs.${index}.prefix`, value);
        }
    }

    useEffect(function () {
        const getConfigList = () => {
            $.post(`${config.apiUrl}/company/listCompConfig`)
                .done(function (res) {
                    if (res.data.length > 0) {
                        setInitialState(prevState => {
                            let newState = {...prevState}
                            newState.comp_mode = res.data[0].comp_mode
                            res.data.forEach((v, i) => {
                                let index = prevState.configs.findIndex(c => c.conf_type == v.conf_type);
                                newState.configs[index].prefix = v.prefix;
                            })
                            return newState
                        })
                    } else {
                        setChangeable(() => true);
                    }
                })
                .catch(function (err) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: err.responseJSON.message,
                        confirmButtonText: "OK",
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        focusConfirm: true,
                    });
                })
        }

        getConfigList();
    }, []);

    return (
        <>
            <div className="d-flex min-vh-100" style={{background: "#DAE5FF"}}>
                <Sidebar/>
                <div className="content m-1 ms-1 p-4">
                    <Header/>
                    <main className="bg-white rounded-4" id="setting">
                        <div className="container-fluid p-4">
                            <div className="row">
                                <div className="col">
                                    <Breadcrumb path={["Setting"]}/>
                                </div>
                            </div>
                            <form onSubmit={configFormik.handleSubmit}>
                                <div className="row my-4">
                                    <div className="col-md-2">
                                        <h6>Mode of Operation:</h6>
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="comp_mode" id="automatic" value="1"
                                                   checked={configFormik.values.comp_mode == 1}
                                                   onChange={e => handleInputs('comp_mode', e.target.value)}
                                                   disabled={!changeable}/>
                                            <label className="form-check-label" htmlFor="automatic">Automatic</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="comp_mode" id="manual" value="0"
                                                   checked={configFormik.values.comp_mode == 0}
                                                   onChange={e => handleInputs('comp_mode', e.target.value)}
                                                   disabled={!changeable}/>
                                            <label className="form-check-label" htmlFor="manual">Manual</label>
                                        </div>
                                        {configFormik.errors.comp_mode && (
                                            <div className="text-danger small">{configFormik.errors.comp_mode}</div>)}
                                    </div>
                                </div>
                                <div className="row gx-5 gy-3 mb-5">
                                    <div className="col-md-4">
                                        <label htmlFor="purchase">
                                            Purchase Prefix <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="purchase"
                                            id="purchase"
                                            onKeyDown={e => inputKeys(e)}
                                            value={configFormik.values.configs[0].prefix}
                                            onChange={e => handleInputs("Purchase", e.target.value)}
                                            disabled={!changeable}
                                        />
                                        {configFormik.errors?.configs?.[0]?.prefix && (
                                            <div className="text-danger small">{configFormik.errors.configs[0].prefix}</div>)}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="purchase_return">
                                            Purchase Return Prefix <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="purchase_return"
                                            id="purchase_return"
                                            onKeyDown={e => inputKeys(e)}
                                            value={configFormik.values.configs[1].prefix}
                                            onChange={e => handleInputs("PurchaseReturn", e.target.value)}
                                            disabled={!changeable}
                                        />
                                        {configFormik.errors?.configs?.[1]?.prefix && (
                                            <div className="text-danger small">{configFormik.errors.configs[1].prefix}</div>)}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="payment">
                                            Payment Prefix <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="payment"
                                            id="payment"
                                            onKeyDown={e => inputKeys(e)}
                                            value={configFormik.values.configs[2].prefix}
                                            onChange={e => handleInputs("Payment", e.target.value)}
                                            disabled={!changeable}
                                        />
                                        {configFormik.errors?.configs?.[2]?.prefix && (
                                            <div className="text-danger small">{configFormik.errors.configs[2].prefix}</div>)}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="sale">
                                            Sale Prefix <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="sale"
                                            id="sale"
                                            onKeyDown={e => inputKeys(e)}
                                            value={configFormik.values.configs[3].prefix}
                                            onChange={e => handleInputs("Sale", e.target.value)}
                                            disabled={!changeable}
                                        />
                                        {configFormik.errors?.configs?.[3]?.prefix && (
                                            <div className="text-danger small">{configFormik.errors.configs[3].prefix}</div>)}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="sale_return">
                                            Sale Return Prefix <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="sale_return"
                                            id="sale_return"
                                            onKeyDown={e => inputKeys(e)}
                                            value={configFormik.values.configs[4].prefix}
                                            onChange={e => handleInputs("SaleReturn", e.target.value)}
                                            disabled={!changeable}
                                        />
                                        {configFormik.errors?.configs?.[4]?.prefix && (
                                            <div className="text-danger small">{configFormik.errors.configs[4].prefix}</div>)}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="receipt">
                                            Receipt Prefix <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="receipt"
                                            id="receipt"
                                            onKeyDown={e => inputKeys(e)}
                                            value={configFormik.values.configs[5].prefix}
                                            onChange={e => handleInputs("Receipt", e.target.value)}
                                            disabled={!changeable}
                                        />
                                        {configFormik.errors?.configs?.[5]?.prefix && (
                                            <div className="text-danger small">{configFormik.errors.configs[5].prefix}</div>)}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="expense">
                                            Expense Prefix <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="expense"
                                            id="expense"
                                            onKeyDown={e => inputKeys(e)}
                                            value={configFormik.values.configs[6].prefix}
                                            onChange={e => handleInputs("Expense", e.target.value)}
                                            disabled={!changeable}
                                        />
                                        {configFormik.errors?.configs?.[6]?.prefix && (
                                            <div className="text-danger small">{configFormik.errors.configs[6].prefix}</div>)}
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="stock_adjustment">
                                            Stock Adjustment Prefix <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="stock_adjustment"
                                            id="stock_adjustment"
                                            onKeyDown={e => inputKeys(e)}
                                            value={configFormik.values.configs[7].prefix}
                                            onChange={e => handleInputs("StockAdjustment", e.target.value)}
                                            disabled={!changeable}
                                        />
                                        {configFormik.errors?.configs?.[7]?.prefix && (
                                            <div className="text-danger small">{configFormik.errors.configs[7].prefix}</div>)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        <button type="submit" className="btn btn-primary me-5" disabled={!changeable || configFormik.isSubmitting}>
                                            {configFormik.isSubmitting && (<span className="spinner-border spinner-border-sm me-2"></span>)}
                                            Update Setting
                                        </button>
                                        <span className="small text-danger">* Configuration settings can't be changed after first update.</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}

export default SettingList;
