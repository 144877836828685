import * as Yup from "yup";

const UserSchema = {};

UserSchema.validateUserAddMaster = () => {
  return Yup.object({
    user_name: Yup.string()
      .trim()
      .required("User name is required.")
      .min(3, "User name minimum length is 3")
      .max(50, "User name should be at most 50 characters.")
      .matches(/^[aA-zZ-\s]+$/, `User name should be alphabetic only`),
    role_id: Yup.string().required("User Role is required."),
    user_mob: Yup.string()
      .required("User mobile is required.")
      .min(9, "User contact Length must be 9 to 10 Digits")
      .max(10, "User contact Length must be 9 to 10 Digits"),
    comp_id: Yup.string().required("Company is required."),
    pin: Yup.number()
      .required("User pin is required.")
      .transform((v, o) => (o === "" ? null : v))
      .typeError(`User pin must be number.`)
      .min(1000, "User pin Length must be 4 Digits")
      .max(9999, "User pin Length must be 4 Digits"),
  });
};
UserSchema.validateUserAdd = () => {
  return Yup.object({
    user_name: Yup.string()
      .trim()
      .required("User name is required.")
      .min(3, "User name minimum length is 3")
      .max(50, "User name should be at most 50 characters.")
      .matches(/^[aA-zZ-\s]+$/, `User name should be alphabetic only`),
    role_id: Yup.string().required("User Role is required."),
    user_mob: Yup.string()
      .required("User mobile is required.")
      .min(9, "User contact Length must be 9 to 10 Digits")
      .max(10, "User contact Length must be 9 to 10 Digits"),
    pin: Yup.number()
      .required("User pin is required.")
      .transform((v, o) => (o === "" ? null : v))
      .typeError(`User pin must be number.`)
      .min(1000, "User pin Length must be 4 Digits")
      .max(9999, "User pin Length must be 4 Digits"),
  });
};

UserSchema.validateUserEdit = () => {
  return Yup.object({
    user_name: Yup.string()
      .trim()
      .required("User name is required.")
      .min(3, "User name minimum length is 3")
      .max(50, "User name should be at most 50 characters.")
      .matches(/^[aA-zZ-\s]+$/, `User name should be alphabetic only`),
    role_id: Yup.string().required("User Role is required."),
    user_mob: Yup.string()
      .required("User mobile is required.")
      .min(9, "User contact Length must be 9 to 10 Digits")
      .max(10, "User contact Length must be 9 to 10 Digits"),
    pin: Yup.number()
      .required("User pin is required.")
      .transform((v, o) => (o === "" ? null : v))
      .typeError(`User pin must be number.`)
      .min(1000, "User pin Length must be 4 Digits")
      .max(9999, "User pin Length must be 4 Digits"),
  });
};

export default UserSchema;
