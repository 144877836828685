import * as $ from "jquery";
import moment from "moment";

let lastKey = null

export const inputOnlyNumber = (e) => {
    let allowKeys = ["Tab", "Backspace", "Delete", "Enter", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
    if (lastKey == "Control" && (['v', 'V', 'a', 'A', 'c', 'C'].includes(e.key))) return true
    lastKey = e.key
    if (e.key == "." && (e.target.value.length == 0 || e.target.value.toString().includes(e.key))) e.preventDefault();
    if (e.target.getAttribute("name") == "qty") {
        if (e.target.value.toString().includes(".")) {
            let digitPlaces = e.target.value.toString().split(".")[0].length;
            let decimalPlaces = e.target.value.toString().split(".")[1].length;
            if (digitPlaces > 4 && !allowKeys.includes(e.key)) e.preventDefault();
            if (decimalPlaces > 1 && !allowKeys.includes(e.key)) e.preventDefault();
        } else {
            let digitPlaces = e.target.value.toString().length;
            if (digitPlaces > 4 && !allowKeys.includes(e.key)) e.preventDefault();
        }
    } else if (e.target.getAttribute("name") == "base_price") {
        if (e.target.value.toString().includes(".")) {
            let digitPlaces = e.target.value.toString().split(".")[0].length;
            let decimalPlaces = e.target.value.toString().split(".")[1].length;
            if (digitPlaces > 11 && !allowKeys.includes(e.key)) e.preventDefault();
            if (decimalPlaces > 3 && !allowKeys.includes(e.key)) e.preventDefault();
        } else {
            let digitPlaces = e.target.value.toString().length;
            if (digitPlaces > 11 && !allowKeys.includes(e.key)) e.preventDefault();
        }
    } else if (e.target.getAttribute("name") == "selling_price") {
        if (e.target.value.toString().includes(".")) {
            let digitPlaces = e.target.value.toString().split(".")[0].length;
            let decimalPlaces = e.target.value.toString().split(".")[1].length;
            if (digitPlaces > 8 && !allowKeys.includes(e.key)) e.preventDefault();
            if (decimalPlaces > 3 && !allowKeys.includes(e.key)) e.preventDefault();
        } else {
            let digitPlaces = e.target.value.toString().length;
            if (digitPlaces > 8 && !allowKeys.includes(e.key)) e.preventDefault();
        }
    }
    if (!($.isNumeric(e.key) || e.key == "." || allowKeys.includes(e.key))) e.preventDefault();
};

export const inputAlphanum = (e) => {
    let allowKeys = ["Tab", "Backspace", "Delete", "Enter", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight",];
    let pattern = /[0-9A-Za-z \@\#\$\&\-\_\.\,\:\'\\\/\*]/;
    if (!(pattern.test(e.key) || allowKeys.includes(e.key))) e.preventDefault();
};

export const inputChangeFocus = (e, next, prev) => {
    if ($.isNumeric(e.key)) {
        $(`#${next}`).focus();
    }
    if (e.key == "Backspace") {
        $(`#${prev}`).focus();
    }
};

export const capitalize = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const createSubstring = (text) => {
    let newText = "";
    if (text?.length > 20) {
        newText = text?.substring(0, 20) + "...";
    } else {
        newText = text;
    }
    return newText;
};

export const dateFormatToBeSent = (date) => {
    return moment(date).format("YYYY-MM-DD H:mm");
};

export const dateFormatToBeShown = (date) => {
    return moment(date).format("DD-MM-YYYY");
};

// export const compareArrays = (arr1, arr2) => {
//   const obj = arr1.reduce((acc, curr) => {
//     const match = arr2.find((el) => el.label === curr.label);
//     acc[curr.label.toLowerCase()] = match ? "y" : "n";
//     return acc;
//   }, {});

//   arr2.forEach((el) => {
//     if (!obj.hasOwnProperty(el.label.toLowerCase())) {
//       obj[el.label.toLowerCase()] = "n";
//     }
//   });

//   return obj;
// };

export const compareArrays = (arr1, arr2) => {
    const keyMappings = {
        photo: "pic", aadhar: "id_1", pan: "id_2",
    };

    const obj = arr1.reduce((acc, curr) => {
        const key = curr.label.toLowerCase();
        const transformedKey = keyMappings[key] || key;

        const match = arr2.find((el) => el.label === curr.label);
        acc[transformedKey] = match ? "y" : "n";
        return acc;
    }, {});

    arr2.forEach((el) => {
        const key = el.label.toLowerCase();
        const transformedKey = keyMappings[key] || key;

        if (!obj.hasOwnProperty(transformedKey)) {
            obj[transformedKey] = "n";
        }
    });

    return obj;
};

export const getFileObjecFromUrl = async (imageUrl) => {
    let fileName = imageUrl.toString().split('/').pop()
    let fileExt = fileName.split('.').pop()

    if (['png', 'jpg', 'jpeg'].includes(fileExt)) {
        let imageData = await fetch(`${imageUrl}`)
        let imageBlob = await imageData.blob()
        let imageFile = new File([imageBlob], fileName, {type: `image/${fileExt}`});
        return imageFile
    }

    return null
}