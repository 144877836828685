import { inputChangeFocus, inputOnlyNumber } from "../../../utils";

function ValidatePin({ pinFormik, error }) {
  return (
    <>
      <form onSubmit={pinFormik.handleSubmit} autoComplete="off">
        <div className="card py-4 px-3">
          <div className="card-body">
            <h1 className="mb-4">Change PIN</h1>

            <div className="mb-3">
              <label htmlFor="pin" className={`mb-2`}>
                New PIN
              </label>
              <div className="row g-0">
                <div className="col d-flex">
                  <input
                    autoFocus
                    type="password"
                    className="form-control otp"
                    maxLength="1"
                    name="pin[0]"
                    id="pin0"
                    onKeyDown={inputOnlyNumber}
                    onKeyUp={(e) => inputChangeFocus(e, "pin1", "pin0")}
                    onChange={pinFormik.handleChange}
                    onBlur={pinFormik.handleBlur}
                  />
                  <input
                    type="password"
                    className="form-control otp"
                    maxLength="1"
                    name="pin[1]"
                    id="pin1"
                    onChange={pinFormik.handleChange}
                    onKeyDown={inputOnlyNumber}
                    onKeyUp={(e) => inputChangeFocus(e, "pin2", "pin0")}
                    onBlur={pinFormik.handleBlur}
                  />
                  <input
                    type="password"
                    className="form-control otp"
                    maxLength="1"
                    name="pin[2]"
                    id="pin2"
                    onChange={pinFormik.handleChange}
                    onKeyDown={inputOnlyNumber}
                    onKeyUp={(e) => inputChangeFocus(e, "pin3", "pin1")}
                    onBlur={pinFormik.handleBlur}
                  />
                  <input
                    type="password"
                    className="form-control otp"
                    maxLength="1"
                    name="pin[3]"
                    id="pin3"
                    onChange={pinFormik.handleChange}
                    onKeyDown={inputOnlyNumber}
                    onKeyUp={(e) => inputChangeFocus(e, "pin3", "pin2")}
                    onBlur={pinFormik.handleBlur}
                  />
                </div>
              </div>
              {pinFormik.touched.pin && pinFormik.errors.pin ? (
                <div className="alert alert-danger text-center small py-1">
                  {pinFormik.errors.pin}
                </div>
              ) : null}
            </div>

            <div className={`${error ? "mb-4" : "mb-5"}`}>
              <label htmlFor="pin_confirm" className={`mb-2`}>
                Confirm PIN
              </label>
              <div className="row g-0">
                <div className="col d-flex">
                  <input
                    type="password"
                    className="form-control otp"
                    maxLength="1"
                    name="pin_confirm[0]"
                    id="pin_confirm0"
                    onKeyDown={inputOnlyNumber}
                    onKeyUp={(e) =>
                      inputChangeFocus(e, "pin_confirm1", "pin_confirm0")
                    }
                    onChange={pinFormik.handleChange}
                    onBlur={pinFormik.handleBlur}
                  />
                  <input
                    type="password"
                    className="form-control otp"
                    maxLength="1"
                    name="pin_confirm[1]"
                    id="pin_confirm1"
                    onChange={pinFormik.handleChange}
                    onKeyDown={inputOnlyNumber}
                    onKeyUp={(e) =>
                      inputChangeFocus(e, "pin_confirm2", "pin_confirm0")
                    }
                    onBlur={pinFormik.handleBlur}
                  />
                  <input
                    type="password"
                    className="form-control otp"
                    maxLength="1"
                    name="pin_confirm[2]"
                    id="pin_confirm2"
                    onChange={pinFormik.handleChange}
                    onKeyDown={inputOnlyNumber}
                    onKeyUp={(e) =>
                      inputChangeFocus(e, "pin_confirm3", "pin_confirm1")
                    }
                    onBlur={pinFormik.handleBlur}
                  />
                  <input
                    type="password"
                    className="form-control otp"
                    maxLength="1"
                    name="pin_confirm[3]"
                    id="pin_confirm3"
                    onChange={pinFormik.handleChange}
                    onKeyDown={inputOnlyNumber}
                    onKeyUp={(e) =>
                      inputChangeFocus(e, "pin_confirm3", "pin_confirm2")
                    }
                    onBlur={pinFormik.handleBlur}
                  />
                </div>
              </div>
              {pinFormik.touched.pin_confirm && pinFormik.errors.pin_confirm ? (
                <div className="alert alert-danger text-center small py-1">
                  {pinFormik.errors.pin_confirm}
                </div>
              ) : null}
            </div>

            {error ? (
              <div className="alert alert-danger text-center small py-1">
                {error}
              </div>
            ) : null}

            <div className="d-grid mb-3">
              <button
                style={{ background: "#FE2F7B", borderRadius: "8px" }}
                className="btn btn-primary"
                type="submit"
                disabled={pinFormik.isSubmitting}>
                {pinFormik.isSubmitting && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}
                Confirm
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default ValidatePin;
