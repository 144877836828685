import config from "../../config";
import $ from "jquery";
import { useEffect, useState } from "react";
import "./style.css";
import leftImg from "../../images/dashboard/loginImage.png";
// import bottomImg from "../../images/login_bottom.png";
import { useFormik } from "formik";
import loginSchema from "../../validations/loginSchema";
import { useNavigate } from "react-router-dom";
import ValidateMobile from "./components/validateMobile";
import ValidateOtp from "./components/validateOtp";
import ValidatePin from "./components/validatePin";
import btmImg from "../../images/dashboard/botmmsg.png";
import Content from "../../images/dashboard/Content.png";
import Breadcrumb from "../Auth/components/Breadcrumb";

function ForgotPin() {
  const navigate = useNavigate();
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [error, setError] = useState("");
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
  }, [error]);

  const loginFormik = useFormik({
    initialValues: { mobile: "" },
    validationSchema: loginSchema.validateMobile(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await $.post(
        `${config.apiUrl}/login`,
        JSON.stringify({
          user_mob: values.mobile,
          reqType: 1,
          envinfo: "Web",
          versioninfo: "1.0",
          otherinfo: "{}",
        })
      )
        .done(function (res) {
          setIsOtpSend(true);
          setIsLogin(false);
          setError("");
        })
        .catch(function (err) {
          setError(err.responseJSON.data.message);
        });
    },
  });

  const otpFormik = useFormik({
    initialValues: { otp: [] },
    validationSchema: loginSchema.validateOtp(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      await $.post(
        `${config.apiUrl}/validateOtp`,
        JSON.stringify({
          user_mob: loginFormik.values.mobile,
          otp: values.otp.join(""),
          fcm: "Web",
        })
      )
        .done(function (res) {
          setIsOtpVerified(true);
          setIsOtpSend(false);
          setError("");
        })
        .catch(function (err) {
          setError(err.responseJSON.data.message);
        });
    },
  });

  const pinFormik = useFormik({
    initialValues: { pin: [], pin_confirm: [] },
    validationSchema: loginSchema.validateForgotPin(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (values.pin.join("") != values.pin_confirm.join("")) {
        return setError("PIN doesn't matched.");
      }
      await $.post(
        `${config.apiUrl}/forgotPin`,
        JSON.stringify({
          user_mob: loginFormik.values.mobile,
          otp: otpFormik.values.otp.join(""),
          pin: values.pin.join(""),
          fcm: "Web",
        })
      )
        .done(function (res) {
          navigate("/login", { state: { mobile: loginFormik.values.mobile } });
          setIsLogin(false);
          setError("");
        })
        .catch(function (err) {
          setError(err.responseJSON.data.message);
        });
    },
  });

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row g-0 vh-100">
          <div className="col-sm-7 position-relative">
            <div className="row h-100 justify-content-center align-items-center mx-0">
              {/* <div className="BreadcrumbPaths">
              <img src={Content} className="imgsfluid" alt="" />
              <div style={{ marginTop: "12px", marginLeft: "10px" }}>
                <Breadcrumb path={["goTicket.pro"]} />
              </div>
            </div> */}
              <p
                className="position-absolute bottom-0 start-0 "
                // style={{ marginLeft: "44px", marginBottom: "25px" }}
                >
                {" "}
                © goticketpro
              </p>
              <p
                className="position-absolute bottom-0 start-0 "
                style={{ marginLeft: "650px" }}>
                {" "}
                <img src={btmImg} alt="" /> help@goticketpro
              </p>

              <div className="col-sm-8">
                {isOtpVerified && (
                  <ValidatePin
                    loginFormik={loginFormik}
                    otpFormik={otpFormik}
                    pinFormik={pinFormik}
                    error={error}
                  />
                )}
                {isOtpSend && (
                  <ValidateOtp
                    loginFormik={loginFormik}
                    otpFormik={otpFormik}
                    error={error}
                    changeState={() => {
                      setIsOtpVerified(false);
                      setIsOtpSend(false);
                    }}
                  />
                )}
                {!isOtpVerified && !isOtpSend && (
                  <ValidateMobile
                    loginFormik={loginFormik}
                    error={error}
                    // changeState={() => {
                    //   setIsLogin(false)
                    // }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <img
              src={leftImg}
              className="img-fluid w-100 "
              alt=""
              id="leftImg"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPin;
