import * as Yup from "yup";
import moment from "moment/moment";

const eventsSchema = {};

eventsSchema.validateEventsAdd = () => {
  return Yup.object({
    name: Yup.string()
      .trim()
      .required("Events name is required.")
      .min(2, "Events name minimum length is 2")
      .max(100, "Event name should be at most 100 characters.")
      .matches(
        /^[a-zA-Z0-9@#,\-&:()\[\]\s\\^$]*$/,
        `Events name should contain Alphanumeric or [@], [#], [-], [&], [:], [ ], [[]], [()] only`
      ),
    organiser: Yup.string()
      .trim()
      .required("Events organiser is required.")
      .max(100, "Event organiser should be at most 100 characters."),
    event_tag_line: Yup.string()
      .trim()
      // .required("Event Tag Line is required.")
      .min(2, "Event Tag Line minimum length is 2")
      .max(100, "Event Tag Line should be at most 100 characters.")
      .matches(
        /^[a-zA-Z0-9@#,.\-&:()\[\]\s\\^$]*$/,
        `Event Tag Line should contain Alphanumeric or [@], [#], [-], [,], [.], [&], [:], [ ], [[]], [()] only`
      ),
    event_banner: Yup.string().trim().required("Event Banner is required."),
    // whatsapp_pics: Yup.string()
    //   .trim()
    //   .required("Whatsapp BG File is required."),
    // email_pics: Yup.string().trim().required("Email BG File is required."),
    desc: Yup.string()
      .trim()
      .min(2, "Events name minimum length is 2")
      .max(500, "Event name should be at most 500 characters."),
    st_dt: Yup.string().trim().required("Event Start Date & Time is required."),
    ed_dt: Yup.string().trim().required("Event End Date & Time is required."),
    reg_st_dt: Yup.string()
      .trim()
      .when("is_self_reg", (is_self_reg) => {
        if (is_self_reg[0] === false) {
          return Yup.string();
        } else {
          return Yup.string()
            .trim()
            .required("Registration start date and time are required");
        }
      }),
    // .required("Registration Start Date & Time is required."),
    reg_ed_dt: Yup.string()
      .trim()
      .when("is_self_reg", (is_self_reg) => {
        if (is_self_reg[0] === false) {
          return Yup.string();
        } else {
          return Yup.string()
            .trim()
            .required("Registration end date and time are required");
        }
      }),
    // .required("Registration End Date & Time is required."),

    amount: Yup.string().when("is_paid", (is_paid) => {
      if (is_paid[0] === false) {
        return Yup.string();
      } else {
        return (
          Yup.string()
            .trim()
            .required("Amount is required")
            // .max(5, "Amount should be at most 5 characters.")
            .matches(/^[0-9.\\]*$/, `Amount should be Numeric only`)
        );
      }
    }),
    youTube_link: Yup.string().max(
      500,
      "Youtube Link should be at most 500 characters."
    ),
    address: Yup.string()
      .trim()
      .max(200, "Address should be at most 200 characters."),
    // city_id: Yup.string().trim().required("City is required."),
    // state_id: Yup.string().trim().required("State is required."),
    pincode: Yup.string()
      .trim()
      .min(6, "Please Enter a valid 6 digit pin code")
      .max(6, "Please Enter a valid 6 digit pin code"),
    email_subject: Yup.string().max(
      200,
      "Email subject should be at most 200 characters."
    ),
  });
};

eventsSchema.validateEventsEdit = () => {
  return Yup.object({
    comp_name: Yup.string()
      .trim()
      .required("Company name is required.")
      .min(2, "Company name minimum length is 2")
      .max(45, "Company name should be at most 45 characters.")
      .matches(/^[aA-z0-9Z-\s]+$/, `Company name should be Alphanumeric only`),
    comp_pincode: Yup.string()
      .trim()
      .required("Pincode is required.")
      .max(9, "Pincode symbol should be at most 9 characters.")
      .min(5, "Pincode symbol should be at most 5 characters.")
      .matches(/^[0-9\s]+$/, `Pincode code should be number`),
    comp_state_id: Yup.string().required("Company state is required."),
    comp_city_id: Yup.string().required("Company city is required."),
  });
};

eventsSchema.validateEventCencel = () => {
  return Yup.object({
    reason: Yup.string()
      .trim()
      .required("Reason is required.")
      .min(5, "Reason must be minimum 5 characters long.")
      .max(250, "Reason must be maximum 250 characters long."),
  });
};

eventsSchema.validateEventChangeStatus = (data = [], eventAreaList) => {
  let fromDate = new Date(new Date().getTime() - 60 * 60 * 1000);
  if (data?.sec_st_dt) {
    fromDate =
      fromDate < new Date(data?.sec_st_dt)
        ? fromDate
        : new Date(data?.sec_st_dt);
  }

  return Yup.object({
    user_id: Yup.string().required("User is required."),
    event_area_id:
      eventAreaList.length > 0
        ? Yup.array()
            .of(Yup.number().required())
            .min(1, "Event area is required.")
        : Yup.array().of(Yup.number().required()),
    sec_st_dt: Yup.date()
      .nullable()
      .required("Start date is required.")
      .transform((value, originalValue) => {
        if (originalValue) {
          return new Date(originalValue);
        }
        return new Date(moment().subtract(1, "day").format("YYYY-MM-DD H:mm"));
      })
      .min(fromDate, "Start date cannot be less than today."),
    sec_end_dt: Yup.date()
      .nullable()
      .required("End date is required.")
      .transform((value, originalValue) => {
        if (originalValue) {
          return new Date(originalValue);
        }
        return new Date(moment().subtract(1, "day").format("YYYY-MM-DD H:mm"));
      })
      .min(Yup.ref("sec_st_dt"), "End date cannot be less than start date."),
  });
};

eventsSchema.validateEventRegister = () => {
  return Yup.object({
    name: Yup.string()
      .trim()
      .required("Full name is required.")
      .min(2, "Full name minimum length is 2")
      .max(100, "Full name should be at most 100 characters.")
      .matches(
        /^[a-zA-Z0-9@#,\-&:()\[\]\s\\^$]*$/,
        `Full name should contain Alphanumeric or [@], [#], [-], [&], [:], [ ], [[]], [()] only`
      ),
    mobile: Yup.string()
      .trim()
      .required("Mobile number is required")
      .min(9, "Mobile number  should be at atleast 9 digit")
      .max(10, "Mobile number  should be at maximum 10 digit"),
    email: Yup.string()
      .trim()
      .required("Email address is required")
      .email("Invalid email address"),
  });
};

export default eventsSchema;
