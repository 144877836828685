const formatRow = (keys, data) => {
    return keys.map(key => data?.[key] || "")
}

const Row = (props) => {
    const keys = props.keys;
    const data = props.data;

    const formattedRow = formatRow(keys, data)

    return (
        <>
            <tr>
                {formattedRow?.map(value => (
                    <>
                        <td>{value}</td>
                    </>
                ))}
            </tr>
        </>
    )
}

export default Row;