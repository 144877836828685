import { useEffect, useState } from "react";
import "./style.css";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import TeamSchema from "../../validations/TeamSchema";
import {
  compareArrays,
  dateFormatToBeSent,
  dateFormatToBeShown,
  inputOnlyNumber,
} from "../../utils";
import { apiCreateTeam, apiCreateUser, apiEventList } from "../../api/master";
import Select from "react-select";
import config from "../../config";
import DatePicker from "react-datepicker";
import { SketchPicker } from "react-color";

function TeamAdd(props) {
  const { companyListMap } = props;
  const [user, setUser] = useState({});

  useEffect(function () {
    if (localStorage.getItem("go-ticket")) {
      setUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
    }
  }, []);

  const TODAY = new Date();

  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [selectedcode, setSelectedCode] = useState("");
  const [shownew, setShownew] = useState(false);
  const [roleSelect, setRoleSelect] = useState();

  const [eventList, setEventList] = useState([]);
  const [selectedEventOption, setSelectedEventOption] = useState(null);

  const [teamValidFrom, setTeamValidFrom] = useState("");
  const [teamValidTo, setTeamValidTo] = useState("");
  const [linkValidFrom, setLinkValidFrom] = useState("");
  const [linkValidTo, setLinkValidTo] = useState("");

  const TeamFormik = useFormik({
    initialValues: {
      team_name: "",
      team_color: "#ffffff",
      slots: "",
      event_id: null,
      team_valid_from: "",
      team_valid_to: "",
      manager_name: "",
      mobile: "",
      link_valid_from: "",
      link_valid_to: "",
      email: "",
      req_fields: "",
    },

    validationSchema: TeamSchema.validateTeamAdd(),
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: async (values) => {
      let abc = JSON.stringify(compareArrays(reqValue, config.reqFields));
      const payload = {
        team_name: values?.team_name.trim(),
        team_color: values?.team_color,
        slots: values?.slots.trim(),
        event_id: values?.event_id ? Number(values?.event_id) : null,
        team_valid_from: dateFormatToBeSent(values?.team_valid_from),
        team_valid_to: dateFormatToBeSent(values?.team_valid_to),
        manager_name: values?.manager_name.trim(),
        mobile: values?.mobile.trim(),
        link_valid_from: dateFormatToBeSent(values?.link_valid_from),
        link_valid_to: dateFormatToBeSent(values?.link_valid_to),
        email: values?.email.trim(),
        req_fields: abc,
      };
      console.log(payload);
      setIsSubmit(true);
      await apiCreateTeam(payload)
        .then((data) => {
          setSuccess(data.data.message);
          setError("");
          setShownew(true);
          props.list();
        })
        .catch((err) => {
          setError(err.response.data.data.message);
          setSuccess("");
        })
        .finally((result) => {
          setIsSubmit(false);
          setTimeout(() => {
            setSuccess("");
            setError("");
          }, 5000);
        });
    },
    onReset: async (values) => {
      setSelectedEventOption(null);
      setLinkValidFrom("");
      setLinkValidTo("");
      setTeamValidFrom("");
      setTeamValidTo("");
      setReqValue([config.reqFields[0]]);
      setShownew(false);
      setSuccess("");
    },
  });

  async function getEventList() {
    let payload = {
      // is_active: false,
      paginate: 0,
    };
    await apiEventList(payload).then((result) => {
      let eventData = result?.data?.data.list;
      let newEventArray = eventData.map((item) => {
        return {
          label: item.name,
          value: item.event_id,
          end_date: item.ed_dt,
          isDisabled: item.is_active == true ? false : true,
        };
      });
      setEventList(newEventArray);
    });
  }

  useEffect(() => {
    getEventList();
  }, []);

  useEffect(() => {}, []);

  const orderOptions = (values) => {
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
  };

  const [reqValue, setReqValue] = useState(orderOptions([config.reqFields[0]]));

  const onChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = config.reqFields.filter((v) => v.isFixed);
        break;
    }

    setReqValue(orderOptions(newValue));
  };

  return (
    <>
      <Modal.Header closeButton>
        <h4 style={{ marginLeft: "12rem", height: "4px" }} id="modalhead">
          Add Team
        </h4>
      </Modal.Header>

      <div className="container-fluid p-4" id="userAdd">
        <form
          className="row gx-5 gy-2"
          onSubmit={TeamFormik.handleSubmit}
          onReset={TeamFormik.handleReset}
          autoComplete="off"
        >
          {/* team name */}
          <div className="col-md-6">
            <label htmlFor="team_name">
              Team Name <span className="text-danger">*</span>
            </label>
            <input
              maxLength={50}
              type="text"
              className="form-control"
              id="team_name"
              {...TeamFormik.getFieldProps("team_name")}
            />
            {TeamFormik.errors.team_name ? (
              <div className="text-danger small">
                {TeamFormik.errors.team_name}
              </div>
            ) : null}
          </div>

          {/* team color */}
          <div className="col-md-6">
            <label htmlFor="team_color">
              Team Colour <span className="text-danger">*</span>
            </label>

            <datalist id="colorOptions">
              <option>#FF0000</option> {/* Red */}
              <option>#00FF00</option> {/* Green */}
              <option>#0000FF</option> {/* Blue */}
              <option>#FFC0CB</option> {/* Pink */}
              <option>#FFFF00</option> {/* Yellow */}
              <option>#FFA500</option> {/* Orange */}
              <option>#800080</option> {/* Purple */}
              <option>#008000</option> {/* Dark Green */}
              <option>#000000</option> {/* Black */}
              <option>#FFFFFF</option> {/* White */}
              <option>#FF6347</option> {/* Tomato */}
              <option>#FF4500</option> {/* Orange Red */}
              <option>#FF8C00</option> {/* Dark Orange */}
              <option>#FFD700</option> {/* Gold */}
              <option>#ADFF2F</option> {/* Green Yellow */}
              <option>#32CD32</option> {/* Lime Green */}
              <option>#00FFFF</option> {/* Cyan */}
              <option>#1E90FF</option> {/* Dodger Blue */}
              <option>#8A2BE2</option> {/* Blue Violet */}
              <option>#FF1493</option> {/* Deep Pink */}
              <option>#696969</option> {/* Dim Gray */}
              <option>#808080</option> {/* Gray */}
              <option>#A9A9A9</option> {/* Dark Gray */}
              <option>#D3D3D3</option> {/* Light Gray */}
              <option>#F0F8FF</option> {/* Alice Blue */}
              <option>#FFDAB9</option> {/* Peach Puff */}
              <option>#FFEFD5</option> {/* Papaya Whip */}
              <option>#F0FFF0</option> {/* Honeydew */}
              <option>#E6E6FA</option> {/* Lavender */}
              <option>#FAEBD7</option> {/* Antique White */}
            </datalist>

            <input
              list="colorOptions"
              style={{ height: "41px" }}
              className="form-control"
              type="color"
              id="team_color"
              {...TeamFormik.getFieldProps("team_color")}
            />

            {TeamFormik.errors.team_color ? (
              <div className="text-danger small">
                {TeamFormik.errors.team_color}
              </div>
            ) : null}
          </div>

          {/* slots */}
          <div className="col-md-6">
            <label htmlFor="slots">
              Team Size <span className="text-danger">*</span>
            </label>
            <input
              maxLength={5}
              type="text"
              onKeyDown={inputOnlyNumber}
              className="form-control"
              id="slots"
              {...TeamFormik.getFieldProps("slots")}
            />
            {TeamFormik.errors.slots ? (
              <div className="text-danger small">{TeamFormik.errors.slots}</div>
            ) : null}
          </div>

          {/* event selection */}
          <div className="col-md-6">
            <label htmlFor="event_id">
              Event <span className="text-danger">*</span>
            </label>
            <Select
              options={eventList}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: "0px",
                }),
                input: (baseStyles, state) => ({
                  ...baseStyles,
                  paddingLeft: "0px",
                }),
              }}
              value={selectedEventOption}
              classNamePrefix="react-select"
              tabSelectsValue
              onChange={(selectedOption) => {
                setSelectedEventOption(selectedOption);
                console.log(selectedOption);
                TeamFormik.setFieldValue("event_id", selectedOption.value);
              }}
              name="event_id"
            />
            {TeamFormik.errors.event_id ? (
              <div className="text-danger small">
                {TeamFormik.errors.event_id}
              </div>
            ) : null}
          </div>

          {/* team link from */}
          <div className="col-md-6">
            <label htmlFor="team_valid_from">
              Team Valid From <span className="text-danger">*</span>
            </label>
            <DatePicker
              id="team_valid_from"
              autoComplete="off"
              className="form-control datePicker"
              minDate={TODAY}
              placeholderText="DD/MM/YYYY"
              selected={teamValidFrom}
              dateFormat="dd/MM/yyyy"
              name="team_valid_from"
              onChange={(date) => {
                setTeamValidFrom(date);
                TeamFormik.setFieldValue("team_valid_from", date);
              }}
            />
            {TeamFormik.errors.team_valid_from ? (
              <div className="text-danger small">
                {TeamFormik.errors.team_valid_from}
              </div>
            ) : null}
          </div>

          {/* team link to */}
          <div className="col-md-6">
            <label htmlFor="ed_dt">
              Team Valid To <span className="text-danger">*</span>
            </label>
            <DatePicker
              id="team_valid_to"
              autoComplete="off"
              className="form-control datePicker"
              disabled={teamValidFrom === ""}
              placeholderText="DD/MM/YYYY"
              minDate={teamValidFrom}
              selected={teamValidTo}
              dateFormat="dd/MM/yyyy"
              name="team_valid_to"
              onChange={(date) => {
                setTeamValidTo(date);
                TeamFormik.setFieldValue("team_valid_to", date);
              }}
            />
            {TeamFormik.errors.team_valid_to ? (
              <div className="text-danger small">
                {TeamFormik.errors.team_valid_to}
              </div>
            ) : null}
          </div>

          {/*link from */}
          <div className="col-md-6">
            <label htmlFor="link_valid_from">
              Link Valid From <span className="text-danger">*</span>
            </label>
            <DatePicker
              id="link_valid_from"
              autoComplete="off"
              className="form-control datePicker"
              disabled={selectedEventOption ? false : true}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              minDate={TODAY}
              maxDate={
                selectedEventOption
                  ? new Date(selectedEventOption?.end_date)
                  : TODAY
              }
              selected={linkValidFrom}
              name="link_valid_from"
              onChange={(date) => {
                setLinkValidFrom(date);
                TeamFormik.setFieldValue("link_valid_from", date);
              }}
            />
            {TeamFormik.errors.link_valid_from ? (
              <div className="text-danger small">
                {TeamFormik.errors.link_valid_from}
              </div>
            ) : null}
          </div>

          {/* link to */}
          <div className="col-md-6">
            <label htmlFor="link_valid_to">
              Link Valid To <span className="text-danger">*</span>
            </label>
            <DatePicker
              id="link_valid_to"
              autoComplete="off"
              className="form-control datePicker"
              disabled={linkValidFrom === ""}
              placeholderText="DD/MM/YYYY"
              minDate={linkValidFrom}
              maxDate={
                selectedEventOption
                  ? new Date(selectedEventOption?.end_date)
                  : TODAY
              }
              selected={linkValidTo}
              dateFormat="dd/MM/yyyy"
              name="link_valid_to"
              onChange={(date) => {
                setLinkValidTo(date);
                TeamFormik.setFieldValue("link_valid_to", date);
              }}
            />
            {TeamFormik.errors.link_valid_to ? (
              <div className="text-danger small">
                {TeamFormik.errors.link_valid_to}
              </div>
            ) : null}
          </div>

          {/* Manager name */}
          <div className="col-md-6">
            <label htmlFor="manager_name">
              Team Leader Name <span className="text-danger">*</span>
            </label>
            <input
              maxLength={50}
              type="text"
              className="form-control"
              id="manager_name"
              {...TeamFormik.getFieldProps("manager_name")}
            />
            {TeamFormik.errors.manager_name ? (
              <div className="text-danger small">
                {TeamFormik.errors.manager_name}
              </div>
            ) : null}
          </div>

          {/* Manager mobile */}
          <div className="col-md-6">
            <label htmlFor="mobile">
              Team Leader Mobile <span className="text-danger">*</span>
            </label>
            <input
              maxLength={10}
              type="text"
              onKeyDown={inputOnlyNumber}
              className="form-control"
              id="mobile"
              {...TeamFormik.getFieldProps("mobile")}
            />
            {TeamFormik.errors.mobile ? (
              <div className="text-danger small">
                {TeamFormik.errors.mobile}
              </div>
            ) : null}
          </div>

          {/* email */}
          <div className="col-md-12">
            <label htmlFor="email">Team Leader Email</label>
            <input
              maxLength={50}
              type="text"
              className="form-control"
              id="email"
              placeholder="john.doe@example.com"
              {...TeamFormik.getFieldProps("email")}
            />
          </div>

          {/* fileds selection */}
          <div className="col-md-12">
            <label htmlFor="req_fields">
              Required Fileds <span className="text-danger">*</span>
            </label>
            <Select
              options={config.reqFields}
              isMulti
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: "0px",
                }),
                valueContainer: (baseStyles, state) => ({
                  ...baseStyles,
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }),
                input: (baseStyles, state) => ({
                  ...baseStyles,
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }),
                dropdownIndicator: (baseStyles, state) => ({
                  ...baseStyles,
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }),
              }}
              value={reqValue}
              classNamePrefix="react-select"
              tabSelectsValue
              onChange={onChange}
              closeMenuOnSelect={false}
              // onChange={(selectedOption) => {
              //   console.log(selectedOption);
              //   selectedOption[0].value != 1 && handleChange(selectedOption);
              // }}
              // getOptionLabel={getOptionLabel}
              // getOptionValue={getOptionValue}
              // isOptionDisabled={isOptionDisabled}
              // name="event_id"
            />
          </div>

          <div className="col-12 mt-4">
            {(error || success) && (
              <div
                className={`alert alert-${
                  error ? "danger" : "success"
                } py-2 small`}
                role="alert"
              >
                {error || success || ""}
              </div>
            )}
            <div style={{ float: "right" }}>
              <button
                className="branchModalCancel"
                onClick={props.hide}
                type="button"
              >
                Cancel
              </button>
              {shownew ? (
                <button
                  style={{ backgroundColor: "#4263EB", color: "#fff" }}
                  id="createNew"
                  type="reset"
                >
                  Add New
                </button>
              ) : (
                <button type="submit" disabled={isSubmit}>
                  {isSubmit && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default TeamAdd;
