import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import { CloseIcon } from "./Icons";
import $ from "jquery";

export const TagsInput = ({
  setFieldValue,
  data,
  name,
  resetTag,
  setResetTag,
  id,
  readonly,
  placeholder,
}) => {
  const [showError, setShowError] = useState(false);
  const removeTags = (e) => {
    let updateTag = data.filter((item) => item !== e.target.id);
    setFieldValue("tags", updateTag);
    setShowError();
  };

  if (resetTag) {
    let updateTag = data.filter((item) => false);
    setFieldValue("tags", updateTag);
    $("#tags").val("");
    setResetTag(false);
  }

  return (
    <>
      {!readonly && (
        <FormControl
          id={id}
          className="mb-2"
          autoComplete="off"
          name={name}
          placeholder={placeholder}
          readOnly={readonly}
          // onReset={setFieldValue("")}
          maxLength={20}
          onKeyDown={(event) => {
            if (data.length < 5) {
              if (event.key === "Enter") {
                event.preventDefault();
                if (event.target.value !== "") {
                  setShowError();
                  if (event.target.value.length <= 20) {
                    let isAlready = data.filter(
                      (e) => e === event.target.value
                    );
                    if (isAlready.length <= 0) {
                      data.push(event.target.value);
                      setFieldValue("tags", data);
                    } else {
                      setShowError("Tag already added");
                    }
                  } else {
                    setShowError("Tag can be of at most 20 characters");
                  }
                  event.target.value = "";
                } else {
                  setShowError("Please Enter Tag");
                }
              }
            } else {
              event.preventDefault();
              setShowError("You can add maximum 5 tags");
              setTimeout(() => {
                setShowError();
              }, [5000]);
            }
          }}
        />
      )}
      {showError && <div className="text-danger small">{showError}</div>}
      <ul className="tags mb-2">
        {!resetTag &&
          data.map((tags, i) => {
            return (
              <li key={"tags_" + i}>
                {tags}{" "}
                {!readonly && (
                  <span
                    id={tags}
                    onClick={(e) => removeTags(e)}
                    role={readonly ? "text" : "button"}
                    className="ms-2"
                  >
                    <CloseIcon size={16} color="#6c6c6c" />
                  </span>
                )}
              </li>
            );
          })}
      </ul>
    </>
  );
};
