import Sidebar from "../../components/partials/Sidebar";
import Header from "../../components/partials/Header";
import Breadcrumb from "../../components/Breadcrumb";
import { Suspense, lazy } from "react";

import StatsLoading from "../../components/Stats/StatsLoading";
import BarChartLoadingComp from "../../components/BarChart/BarChartLoading";
import TeamMembersLoading from "../../components/TeamMembers/TeamMembersLoading";

const Stats = lazy(() => import("../../components/Stats/Stats"));
const BarChartComp = lazy(() => import("../../components/BarChart/BarChart"));
const TeamMembers = lazy(() =>
  import("../../components/TeamMembers/TeamMembers")
);

function Dashboard() {
  return (
    <>
      <div className="d-flex min-vh-100" style={{ background: "#DAE5FF" }}>
        <Sidebar />
        <div className="content m-1 ms-2 p-4">
          <Header />
          <Breadcrumb path={[]} />
          <main
            id="dashboard"
            className="overflow-auto"
            style={{ maxHeight: "90vh" }}
          >
            <div className="container-fluid py-2">
              <div className="row mb-4 ms-2">
                <Suspense fallback={<StatsLoading />}>
                  <Stats />
                </Suspense>
              </div>
              {/* <div className="row mb-4 ms-2 d-flex align-items-start">
                <div className={`ms-1 me-1 col-sm-6`}>
                  <div className={`row  mb-5`}>
                    <Suspense fallback={<BarChartLoadingComp />}>
                      <BarChartComp />
                    </Suspense>
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="row mb-5 bg-white ms-3 px-2 rounded-4 shadow-sm text-center">
                    <h5 className="pt-3" style={{ color: "#4318FF" }}>
                      Your Team Members
                    </h5>
                    <div>
                      <Suspense fallback={<TeamMembersLoading />}>
                        <TeamMembers />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row mb-4 ms-2">
                <div className={`ms-1 col-sm-12`}>
                  <div className={`row mb-5`}>
                    <Suspense fallback={<BarChartLoadingComp />}>
                      <BarChartComp />
                    </Suspense>
                  </div>
                </div>
              </div>
              {/* <div className="row ">
                <div className="row  bg-white ms-3 px-2 rounded-4 shadow-sm text-center">
                  <h5 className="pt-3" style={{ color: "#4318FF" }}>
                    Your Team Members
                  </h5>
                  <div>
                    <Suspense fallback={<TeamMembersLoading />}>
                      <TeamMembers />
                    </Suspense>
                  </div>
                </div>
              </div> */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
