import { useState, useEffect } from "react";
import Select from "react-select";

const Selects = (props) => {
  const data = props.data;
  const error = props.error;
  const state = props.state;
  const filterData = props.filterData;
  const isclear = props.isclear;
  const reportId = props.reportId;

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (state?.[data?.f_name]) {
      const option = options.find(
        (option) => option.value === state[data.f_name]
      );
      setSelected(option || null);
    }
  }, [state, data?.f_name, props.data]);

  useEffect(() => {
    setSelected(null);
  }, [isclear,reportId]);

  let options = data?.options?.filter((option) => {
    if (state?.[data?.extra?.depends]) {
      return option?.[data?.extra?.depends] == state?.[data?.extra?.depends];
    }
    return true;
  });

  return (
    <div className="col-md-3">
      <label htmlFor={data?.f_name}>
        {data?.f_lable || ""}{" "}
        {data?.extra?.required && <span className="text-danger">*</span>}
      </label>
      {/* <select
        className={data?.class || "form-select"}
        name={data?.f_name}
        id={data?.f_name}
        value={state?.[data?.f_name]}
        onChange={(e) => props?.handleInput(e)}
      >
        <option value="">Select...</option>
        {options?.map((option) => (
          <option key={option?.value} value={option?.value || ""}>
            {option?.label || ""}
          </option>
        ))}
      </select> */}
      <Select
        isClearable
        isSearchable
        name={data?.f_name}
        id={data?.f_name}
        options={options}
        value={selected}
        onChange={(val) => {
          setSelected(val);

          let e = {
            target: {
              name: data?.f_name,
              value: val ? val.value : "", // Handle clear case
            },
          };
          props?.handleInput(e);
        }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            minHeight: "0px",
            height: "40px",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            paddingTop: "0px",
            paddingBottom: "0px",
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            paddingLeft: "0px",
            // lineHeight: "26px",
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            paddingTop: "0px",
            paddingBottom: "0px",
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            paddingTop: "0px",
            paddingBottom: "0px",
          }),
        }}
        classNamePrefix="react-select"
      />
      <span className="text-danger">{error}</span>
    </div>
  );
};

export default Selects;
