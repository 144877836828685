import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/icons/2.jpg";
import { FaWhatsapp } from "react-icons/fa";

const Hero = () => {
  const [isHovered, setIsHovered] = useState(false);

  const textBoxStyle = {
    width: "90%",
    color: "#e90e0e",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };

  const headingStyle = {
    fontSize: "62px",
    fontWeight: "600",
    color: "#e90e0e",
    lineHeight: 1.2,
  };

  const paragraphStyle = {
    paddingLeft: "20px",
    paddingBottom: "25px",
    margin: "20px 0 40px",
    fontSize: "22px",
    letterSpacing: "1.2px",
    lineHeight: "30px",
    // color: "rgba(193, 182, 182, 0.92)",
  };

  const heroButtonStyle = {
    display: "inline-block",
    textDecoration: "none",
    color: "#fff",
    border: "1px solid #fff",
    padding: "12px 34px",
    fontSize: "25px",
    background: "#00AB66",
    position: "relative",
    cursor: "pointer",
    marginRight: "20px",
    transition: "0.5s",
    ...(isHovered && {
      border: "1px solid #00AB66",
      background: "#00AB66",
    }),
  };

  const header = {
    minHeight: "100vh",
    width: "100%",
    // backgroundImage:
    //   "linear-gradient(rgba(4,9,30,0.7), rgba(4,9,30,0.7)), url(Images/GIP_HBG.png)",
    background: "#fff3f3",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
    overflow: "hidden",
  };

  // Function to open WhatsApp with a predefined message
  const openWhatsApp = () => {
    const phoneNumber = "+917710058282";  
    const message = "Hi";
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div style={header}>
      <img
        src={logo}
        alt=""
        style={{
          position: "absolute",
          top: "20px",
          left: "50px",
          height: "150px",
          width: "150px",
          borderRadius: "10px",
          boxShadow: "0 0 2px gray",
        }}
      />
      <div style={textBoxStyle}>
        <h1 style={headingStyle}>
          "No more manual entries,
          <br /> elevate your events experience"
        </h1>
        <p style={paragraphStyle}>
          goInvite.pro is a Software As A Solution (SAAS) that is super easy to
          use for Event Crew, Team Management and Event Registration. We create
          QR Coded and Easily Customizable Invitations and Event Crew Batches/ID
          Cards for any Event (Private and Government), Exhibition Invitation,
          Registration & Event Team Management needs.
        </p>
        <Link
          // to="/contact"
          style={heroButtonStyle}
          onClick={openWhatsApp}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          let's Connect <FaWhatsapp />
        </Link>
      </div>
    </div>
  );
};

export default Hero;
