import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import NavBar from "../../components/partials/Navbar";
import Footer from "../../components/partials/Footer";
import logo from "../../images/icons/2.jpg";
import { FaWhatsapp } from "react-icons/fa";

const Contact = () => {
  const [user, setUser] = useState({});
  const [isHovered, setIsHovered] = useState(false); // For the regular button
  const [isWhatsAppHovered, setIsWhatsAppHovered] = useState(false); // For WhatsApp button
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    const storedUser = localStorage.getItem("go-ticket");
    if (storedUser) {
      setUser(JSON.parse(storedUser).userData);
    } else {
      setUser(null);
    }
  }, []);

  // Function to open WhatsApp with a predefined message
  const openWhatsApp = () => {
    const phoneNumber = "+917710058282";
    const message = "Hi";
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const subHeaderStyle = {
    height: "50vh",
    width: "100%",
    // backgroundImage:
    //   "linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)), url(Images/GIP_HBG.png)",
    backgroundPosition: "center",
    background: "#fff3f3",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#fff",
  };

  const subHeaderTextStyle = {
    color: "#f44336",
    margin: "0",
  };

  const locationStyle = {
    width: "80%",
    margin: "auto",
    padding: "80px 0",
    height: "700px",
  };

  const iframeStyle = {
    width: "100%",
    height: "100%",
  };

  const contactUsStyle = {
    width: "80%",
    margin: "auto",
  };

  const contactColStyle = {
    flexBasis: "48%",
    marginBottom: "30px",
  };

  const contactColDivStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "40px",
  };

  const faStyle = {
    fontSize: "28px",
    color: "#f44336",
    margin: "10px",
    marginRight: "30px",
  };

  const contactTextStyle = {
    padding: "0",
    fontSize: "25px",
  };

  const h5Style = {
    fontSize: "20px",
    marginBottom: "5px",
    color: "#555",
    fontWeight: "400",
  };

  const inputStyle = {
    width: "100%",
    padding: "15px",
    marginBottom: "17px",
    outline: "none",
    border: "1px solid #ccc",
  };

  const buttonStyle = {
    display: "inline-block",
    padding: "15px 30px",
    fontSize: "16px",
    border: `2px solid ${isHovered ? "#f44336" : "transparent"}`,
    color: isHovered ? "#f44336" : "#fff",
    backgroundColor: isHovered ? "#fff" : "#f44336",
    cursor: "pointer",
    textDecoration: "none",
    transition: "all 0.3s ease",
  };

  const heroButtonStyle = {
    display: "inline-block",
    textDecoration: "none",
    color: "white",
    border: `2px solid ${isWhatsAppHovered ? "#25D366" : "#25D366"}`,
    padding: "15px 30px",
    fontSize: "16px",
    background: isWhatsAppHovered ? "#25D366" : "#25D366",
    position: "relative",
    cursor: "pointer",
    marginRight: "20px",
    // marginLeft: "10px",
    transition: "0.5s",
  };

  const tamID = "template_lfr9qy8";
  const servicID = "service_qf64zig";
  const publicKey = "lXnYLIEtQQnWgyjxh";

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(servicID, tamID, e.target, publicKey)
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message.");
        }
      )
      .finally(() => {
        setLoading(false);
        e.target.reset();
      });

    e.target.reset();
  };

  return (
    <div>
      <NavBar user={user} />
      <section style={subHeaderStyle}>
        <img
          src={logo}
          alt=""
          style={{
            position: "absolute",
            top: "100px",
            left: "50px",
            height: "150px",
            width: "150px",
            borderRadius: "10px",
            boxShadow: "0 0 2px gray",
          }}
        />
        <h1 style={subHeaderTextStyle}>CONTACT US</h1>
      </section>

      <section style={locationStyle}>
        <iframe
           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.9026290200804!2d72.873907676093!3d19.199454782030756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b76aa24da097%3A0xa9c8730aeff5f963!2sDaffodil%20CHS!5e0!3m2!1sen!2sin!4v1725970502335!5m2!1sen!2sin"
          style={iframeStyle}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </section>
     
      <section style={contactUsStyle}>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={contactColStyle}>
            <div style={contactColDivStyle}>
              <i className="fa fa-home" style={faStyle}></i>
              <span>
                <h5 style={h5Style}>A Unit of Burange MediaWorks Pvt. Ltd</h5>
                <p style={contactTextStyle}>
                  601/A, Daffodils CHS Building No. 2, Lokhandwala Complex,
                  Kandivali East, Mumbai, Maharashtra, 400101
                </p>
              </span>
            </div>
            <div style={contactColDivStyle}>
              <i className="fa fa-phone" style={faStyle}></i>
              <span>
                <h5 style={h5Style}>+91 7710058282</h5>
                {/* <p style={contactTextStyle}>Monday to Saturday 10 AM to 6 PM</p> */}
                <button
                  // to="/contact"
                  style={heroButtonStyle}
                  onClick={openWhatsApp}
                  onMouseEnter={() => setIsWhatsAppHovered(true)}
                  onMouseLeave={() => setIsWhatsAppHovered(false)}
                >
                  Let's Connect <FaWhatsapp />
                </button>
              </span>
            </div>
            <div style={contactColDivStyle}>
              <i className="fa fa-envelope-o" style={faStyle}></i>
              <span>
                <h5 style={h5Style}>mail@goinvitepro.in </h5>
                <p style={contactTextStyle}>Email us your query</p>
              </span>
            </div>
          </div>
          <div style={contactColStyle}>
            <form onSubmit={sendEmail}>
              <input
                type="text"
                name="from_name"
                placeholder="Enter your name"
                required
                style={inputStyle}
              />
              <input
                type="email"
                name="email"
                placeholder="Enter email address"
                required
                style={inputStyle}
              />
              <input
                type="text"
                name="mobile"
                placeholder="Enter your Mobile"
                required
                onKeyDown={(e) => {
                  const key = e.key;
                  if (
                    !/[\d]/.test(key) &&
                    key !== "Backspace" &&
                    key !== "ArrowLeft" &&
                    key !== "ArrowRight"
                  ) {
                    e.preventDefault();
                  }
                }}
                style={inputStyle}
              />
              <textarea
                rows="8"
                name="message"
                placeholder="Message"
                required
                style={inputStyle}
              ></textarea>
              <button
                type="submit"
                disabled={loading}
                style={buttonStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                ) : (
                  "Send Message"
                )}
              </button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
