import React, { useState } from "react";

const WhyChoose = () => {
  const uspStyle = {
    width: "80%",
    margin: "auto",
    textAlign: "center",
    paddingTop: "100px",
  };

  const headingStyle = {
    fontSize: "50px",
    fontWeight: "600",
    color: "#e90e0e",
  };

  const paragraphStyle = {
    color: "#777",
    fontSize: "25px",
    fontWeight: "300",
    lineHeight: "22px",
    padding: "10px",
  };

  const rowStyle = {
    marginTop: "5%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: window.innerWidth <= 700 ? "column" : "row",
  };

  const uspColBaseStyle = {
    flexBasis: "31%",
    background: "#fff3f3",
    color: "#090a0c",
    borderRadius: "10px",
    marginBottom: "5%",
    padding: "20px 12px",
    boxSizing: "border-box",
    transition: "0.5s",
  };

  const uspColHoverStyle = {
    boxShadow: "0 0 20px 0px rgba(0, 0, 0, 0.2)",
  };

  const subheadingStyle = {
    textAlign: "center",
    fontWeight: "600",
    margin: "10px 0",
  };

  // State management for each column's hover status
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div>
      <section style={uspStyle}>
        <h1 style={headingStyle}>Why goInvite.pro</h1>
        <p style={paragraphStyle}>USP's of our Software</p>
        <div style={rowStyle} className="row">
          {[
            {
              title: "Send QR Code",
              description:
                "Invitations via WhatsApp, Email along with follow-up Reminders in just one click (To Guests and Event Crew).",
              index: 1,
            },
            {
              title: "Smart Cost-Effective Check In",
              description:
                "No need for any third-party hardware, just use your mobile (Android) phone as your QR scanners.",
              index: 2,
            },
            {
              title: "Complete Data Privacy",
              description:
                "You can even easily host your guest database on any of your servers too.",
              index: 3,
            },
            {
              title: "Manage Authorization",
              description:
                "Create Organization, Manage Members and Invite and Crew Authorization.",
              index: 4,
            },
            {
              title: "Generate Reports",
              description:
                "Generate reports of your Guests and Event Team Attendance.",
              index: 5,
            },
            {
              title: "Customizable & White Labelling",
              description:
                "You can customize and design to suit any invitations and registration needs with your own company name.",
              index: 6,
            },
          ].map((col) => (
            <div
              className="col-6"
              key={col.index}
              style={{
                ...uspColBaseStyle,
                ...(hoveredIndex === col.index ? uspColHoverStyle : {}),
              }}
              onMouseEnter={() => setHoveredIndex(col.index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <h2 style={subheadingStyle}>{col.title}</h2>
              <p style={{ color: "#090a0c" }}>{col.description}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default WhyChoose;
