import * as Yup from 'yup';

const loginSchema = {}

loginSchema.validateLogin = () => {
    return Yup.object({
        mobile: Yup.string()
            .required('Mobile No is required.')
            .min(9, 'Mobile No should be at least 9 characters.')
            .max(12, 'Mobile No should be at most 12 characters.')
            .matches(/^[0-9\\]*$/, `Mobile No should be Numeric only`),
        pin: Yup.array().of(Yup.string())
            .required('PIN is required.')
            .min(4, 'PIN is required.')
            // .max(4, 'PIN should be 4 characters.'),
            // .matches(/^[0-9\\]*$/, ` PIN should be Numeric only`),
    })
}

loginSchema.validateMobile = () => {
    return Yup.object({
        mobile: Yup.string()
            .required('Mobile No is required.')
            .min(9, 'Mobile No should be at least 9 characters.')
            .max(12, 'Mobile No should be at most 12 characters.')
            .matches(/^[0-9\\]*$/, `Mobile No should be Numeric only`),
    })
}

loginSchema.validateOtp = () => {
    return Yup.object({
        otp: Yup.array().of(Yup.string())
            .required('OTP is required.')
            .min(4, 'OTP should be 4 characters.')
            .max(4, 'OTP should be 4 characters.'),
    })
}

loginSchema.validateForgotPin = () => {
    return Yup.object({
        pin: Yup.array().of(Yup.string())
            .required('PIN is required.')
            .min(4, 'PIN should be 4 characters.')
            .max(4, 'PIN should be 4 characters.'),
        pin_confirm: Yup.array().of(Yup.string())
            .required('Confirm PIN is required.')
            .min(4, 'Confirm PIN should be 4 characters.')
            .max(4, 'Confirm PIN should be 4 characters.'),
    })
}

export default loginSchema