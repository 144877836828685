import Row from "./Row";

const NO_DATA_MSG = "No Data Found"

const Table = (props) => {
    const columns = props.columns;
    const headers = props.headers;
    const rows = props.rows;

    return (
        <>
            <div className="row mt-4">
                <div className="col">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                {headers?.map(value => (<th>{value}</th>))}
                            </tr>
                            </thead>
                            <tbody>
                            {rows?.length
                                ? rows?.map(row => (<Row keys={columns} data={row}/>))
                                : <td colSpan={columns?.length} className="text-center">{NO_DATA_MSG}</td>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Table;