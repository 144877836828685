import * as Yup from "yup";

const guestsSchema = {};

guestsSchema.validateGuestAdd = () => {
  let mob = /^[0-9\b]+$/;
  return Yup.object({
    name: Yup.string()
      .trim()
      .required("Guest name is required.")
      .min(2, "Guest name minimum length is 2")
      .max(100, "Guest name should be at most 100 characters.")
      .matches(
        /^[A-Za-z0-9\[\]#()&:@ -]*$/,
        `Guest name should be Alphanumeric or [,],#,(,),&,:,@,- only`
      ),
    mobile_no: Yup.string()
      .trim()
      .required("Mobile number is required")
      .min(9, "Mobile number  should be at atleast 9 digit")
      .max(10, "Mobile number  should be at maximum 10 digit"),
    email: Yup.string()
      // .required("Email  is required")
      .email("Invalid Email"),
    event_id: Yup.string().required("Event name is required"),
    guest_type_id: Yup.string().required("Guest Type name is required"),
  });
};

// guestsSchema.validateEventsEdit = () => {
//   return Yup.object({
//     comp_name: Yup.string()
//       .trim()
//       .required('Company name is required.')
//       .min(2, 'Company name minimum length is 2')
//       .max(45, 'Company name should be at most 45 characters.')
//       .matches(/^[aA-z0-9Z-\s]+$/, `Company name should be Alphanumeric only`),
//       comp_pincode: Yup.string()
//       .required('Pincode is required.')
//       .max(9, 'Pincode symbol should be at most 9 characters.')
//       .min(5,'Pincode symbol should be at most 5 characters.')
//       .matches(/^[0-9\s]+$/, `Pincode code should be number`),
//       comp_state_id: Yup.string().required('Company state is required.'),
//       comp_city_id: Yup.string().required('Company city is required.'),
//   })
// }

export default guestsSchema;
