import * as Yup from 'yup'

const configSchema = {}


configSchema.validateUpdate = () => {
    return Yup.object({
        comp_mode: Yup.string().required('Company Mode is required.'),
        configs: Yup.array().of(Yup.object({
            conf_type: Yup.string().required('Config Type is required.'),
            prefix: Yup.string().required('Prefix is required.'),
        })).required('Configs is required.')
    })
}

export default configSchema
