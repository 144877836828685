import React from "react";
import {Button, Modal} from "react-bootstrap";

const CommonModal = ({
                         title,
                         isOpen,
                         setIsOpen,
                         modalType,
                         modalSize = 'lg',
                         setFormData,
                         children,
                         eventId,
                         className,
                         pageType,
                     }) => {
    const handleClose = () => {
        setIsOpen(false);
        setFormData("");
    };
    const modaltitle = (modalType) => {
        if (modalType === "isAdd" || modalType === "uploadExcel") {
            return "Add";
        } else if (modalType === "isView") {
            return "View";
        } else if (modalType === "isEdit") {
            return "Edit";
        } else if (modalType === "isDelete" || modalType === "isRemove") {
            return "Delete";
        } else if (modalType === "addMember") {
            return `Assign User to ${eventId?.name}`;
        }
    };

    let modalDefaultSize = (modalType === "uploadExcel" || pageType === "eventArea" || pageType === "guestType") ? "md" : modalSize

    return (
        <Modal
            backdrop={"static"}
            keyboard={false}
            show={isOpen}
            size={modalDefaultSize}
            className={className ? className : ""}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {modaltitle(modalType)} {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
};

export default CommonModal;
