import React, { useState } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";

import {
  datasetToday,
  datasetDaily,
  datasetWeekly,
  datasetMonthly,
} from "./charConfig";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ArrowsAngleContract, ArrowsAngleExpand } from "react-bootstrap-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const BarChartLoadingComp = ({ isExpand, setIsExpand }) => {
  return (
    <div
      className="bg-white py-4 px-0 rounded-5"
      style={{ boxShadow: " 2px 5px 10px gray" }}
    >
      {isExpand ? (
        <div className="text-end pe-4">
          <ArrowsAngleContract
            color="gray"
            onClick={() => setIsExpand(false)}
          />
        </div>
      ) : (
        <div className="text-end pe-4">
          <ArrowsAngleExpand color="gray" onClick={() => setIsExpand(true)} />
        </div>
      )}

      <Tab.Container id="dateRange" defaultActiveKey="today">
        <Row>
          <Col>
            <Nav variant="pills" className="justify-content-center gap-2">
              <Nav.Item>
                <Nav.Link eventKey="today">Today</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="daily">Daily</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="weekly">Weekly</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="monthly">Monthly</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Tab.Content>
          <Tab.Pane eventKey="today">
            <div className="py-3 mt-3 px-2">
              <Skeleton count={10} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="daily">
            <div className="py-3 mt-3 px-2">
              <Skeleton count={10} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="weekly">
            <div className="py-3 mt-3 px-2">
              <Skeleton count={10} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="monthly">
            <div className="py-3 mt-3 px-2">
              <Skeleton count={10} />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default BarChartLoadingComp;
