import React from "react";
import Header from "../partials/Header";
import Sidebar from "../partials/Sidebar";
import toast, { Toaster } from 'react-hot-toast';

const Layout = ({children})=>{
   
    return (
        <>
          <div className="d-flex min-vh-100" style={{ background: "#DAE5FF" }}>
        <Sidebar />
        <div className="content m-1 ms-1 p-4">
          <Header />
          <main className="bg-white rounded-4" id="buyer">
            <div className="container-fluid p-3">
                {children}
            </div>
          </main>
        </div>
      </div>
      <Toaster 
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },
      
          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
        </>
    )
}
export default Layout;