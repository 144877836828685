import React, {useEffect, useState} from "react";

import Draggable from "react-draggable";

import qrCode from "../../images/qrcode.png";
import "../../pages/Events/style.css";

const AddQR = ({
                 file,
                 addQrWA,
                 setAddQrWA,
                 addQrEmail,
                 setAddQrEmail,
                 waData,
                 setWaData,
                 emailData,
                 setEmailData,
                 waImageLink,
                 emailImageLink,
               }) => {
  // const location = useLocation();
  // const navigate = useNavigate();
  // const { file } = location.state;
  const [deltaPosition, setDeltaPosition] = useState({x: 0, y: 0});
  const [display, setDisplay] = useState(false);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [wmWidth, setWmWidth] = useState(null);
  const [wmHeight, setWmHeight] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      setImageWidth(img.width);
      setImageHeight(img.height);
    };

    const wmImg = new Image();
    wmImg.src = qrCode;
    wmImg.onload = () => {
      setWmWidth(wmImg.width);
      setWmHeight(wmImg.height);
    };
  }, []);

  const containerStyle = {
    width: imageWidth ? `${imageWidth}px` : "auto",
    background: `url(${URL.createObjectURL(file)}) no-repeat`,
    backgroundSize: "cover",
    position: "relative",
    // left: "2%",
    height: imageHeight ? `${imageHeight}px` : "auto",
  };

  const handleDrag = (e, ui) => {
    setDisplay(false);

    const {x, y} = deltaPosition;
    const newDeltaPosition = {
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    };

    setDeltaPosition(newDeltaPosition);
  };

  // const bgImg = '../../public/bgImg.png'
  // const qrImg = '../../public/qrImg.png'

  // const displayPos = () => {
  //   setDisplay(true);
  // };

  const setPos = async () => {
    const curPos = {
      xAxis: deltaPosition.x.toFixed(0),
      yAxis: deltaPosition.y.toFixed(0),
      logoW: wmWidth,
      logoH: wmHeight,
    };
    // const res = await axios.post("http://localhost:5000/image-update", curPos);
    addQrWA && setWaData(curPos);

    addQrEmail && setEmailData(curPos);

    setAddQrWA && setAddQrWA(false);
    setAddQrEmail && setAddQrEmail(false);
  };

  return (
      <div className="main-div">
        <div style={containerStyle}>
          <Draggable bounds="parent" onDrag={handleDrag}>
            <img
                draggable="false"
                src={qrCode}
                alt="draggable front pic"
                className="small-img"
            />
          </Draggable>
        </div>
        <div className="pos-div">
          <button className="btn btn-primary py-1 px-2 mt-3" onClick={setPos}>
            Set {addQrWA ? "Whatsapp" : "Email"} Image
          </button>
          {/* <p className={`py-2 mb-3 ${display ? "display" : "hide"}`}>
          Current Position: x: {deltaPosition.x.toFixed(0)}, y:{" "}
          {deltaPosition.y.toFixed(0)}
        </p>
        <button
          className={`btn btn-primary ${display ? "display" : "hide"}`}
          onClick={generateImage}
        >
          Generate Image
        </button> */}
        </div>
      </div>
  );
};

export default AddQR;
