import axios from "axios";
import Tippy from "@tippyjs/react";
import excelIcon from "../../../images/icons/excel 1 (1).png";
import { useState } from "react";

const ExcelDownload = (props) => {
    let link = props.link
    let filters = props.filters
    let filename = props.filename

    const [loader, setLoader] = useState(false)

    const download = async () => {
        setLoader(true)
        try {
            let response = await axios.post(link, filters, {
                headers: {
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('go-ticket'))?.token
                },
                responseType: "arraybuffer",
            });

            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(new Blob([response.data]));
            a.setAttribute("download", filename + ".xlsx");
            document.body.appendChild(a);
            setLoader(false)
            a.click();
        } catch (err) {
            console.log(err)
            setLoader(false)
        }
    }

    return (
        <>
            <Tippy content="Download" trigger="mouseenter">
                {
                    loader ?
                        <span className="spinner-border spinner-border-sm me-2"></span>
                        :
                        <img
                            src={excelIcon}
                            className="img-fluid mx-2 icon"
                            width="30px"
                            height="30px"
                            alt=""
                            role="button"
                            onClick={(e) => download()}
                        />
                }
            </Tippy>
        </>
    )
}

export default ExcelDownload;