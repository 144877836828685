import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb";
import Commontable from "../../components/Common/CommonTable";
import Layout from "../../components/Common/Layout";
import {
  apiEventAreaList,
  apiEventList,
  apiEventAreaDelete,
} from "../../api/master";
import CommonModal from "../../components/Common/Modal";
import EventAreaForm from "../../components/EventArea/EventAreaForm";

import Filter from "../../images/icons/filter.png";
import Tippy from "@tippyjs/react";
import ActionMenuItem from "../../components/EventArea/ActionMenuItem";
// import AsyncSelect from "react-select/async";
import Select from "react-select";

const EventAreaList = () => {
  const initialState = {
    event_area: "",
    event_id: null,
    is_active: "",
    past_event: "",
  };
  const [checked, setChecked] = useState(false);
  const [userRole, setUserRole] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState("");
  const [modalType, setModalType] = useState("isAdd");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [formInputs, setFormInputs] = useState(initialState);
  const [allEventList, setAllEventList] = useState([]);
  const [activeEventList, setActiveEventList] = useState([]);
  const [eventAreaList, getEventAreaList] = useState(false);
  const [selectedEventOption, setSelectedEventOption] = useState(null);
  const [error, setError] = useState("");

  const status = [
    { value: false, label: "In-Active", className: "danger" },
    { value: true, label: "Active", className: "success" },
  ];

  function changeStatus(event_area_id, status) {
    let target = window.event.target;
    let oldText = target.innerText;
    target.innerText = "Updating...";
    apiEventAreaDelete({ event_area_id: event_area_id, is_active: status })
      .then((data) => {
        setError("");
      })
      .catch((err) => {
        setError(err.response.data.data.message);
      })
      .finally(() => {
        target.innerText = oldText;
        getList(filtersVisible);
      });
  }

  const formatData = (eventObj) => {
    return eventObj.name;
  };
  const columns = [
    { feild: "event_area", header: "Event Area" },
    { feild: "mst_event", header: "Event", cellRenderer: formatData },
  ];
  const getList = (isFilter = true, pageNo = null) => {
    let json = { paginate: 1, page_no: (pageNo || currentPage) - 1 };
    if (isFilter) {
      json = {
        ...json,
        ...formInputs,
      };
    }
    apiEventAreaList(json)
      .then((res) => {
        setCurrentPage(pageNo || currentPage);
        setIsLoading(true);
        setList(res.data.data.list);
        setIsLoading(false);
        setTotalPage(res.data.data.count);
      })
      .catch((err) => setError(err.response.data.data.message));
  };
  const checkEventActive = (event) => {
    if (event.is_active) {
      return event;
    }
  };
  async function getEventList() {
    let json = { paginate: 0, past_event: formInputs.past_event };
    // setIsLoading(true);
    await apiEventList(json)
      .then((result) => {
        let allEventData = result?.data?.data.list;
        let activeEventData = result?.data?.data.list.filter(checkEventActive);
        let newAllEventArray = allEventData.map((item) => {
          return {
            label: item.name,
            value: item.event_id,
          };
        });
        let newActiveEventArray = activeEventData.map((item) => {
          return {
            label: item.name,
            value: item.event_id,
          };
        });
        setAllEventList(newAllEventArray);
        setActiveEventList(newActiveEventArray);
        // setIsLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.data.message);
        // setIsLoading(false);
      });
  }
  useEffect(() => {
    getEventList();
  }, [formInputs.past_event]);

  useEffect(() => {
    getEventAreaList(false);
  }, [eventAreaList]);
  useEffect(() => {
    if (localStorage.getItem("go-ticket")) {
      setUserRole(
        JSON.parse(localStorage.getItem("go-ticket")).userData.role_id
      );
    }
  }, [currentPage]);

  useEffect(() => {
    if (eventAreaList === false) getList();
  }, [eventAreaList, currentPage]);

  function handleInputs(e) {
    setFormInputs({ ...formInputs, [e.target.id]: e.target.value });
  }

  const handleFilterVisibility = () => {
    if (filtersVisible) {
      setFiltersVisible(false);
      setFormInputs(initialState);
    } else {
      setFiltersVisible(true);
    }
  };

  const onClickActionItemClick = (data, e) => {
    const { id } = e.currentTarget;
    const type = id;
    if (type === "isView") {
      setIsOpen(true);
      setFormData(data);
      setModalType("isView");
    } else if (type === "isEdit") {
      setIsOpen(true);
      setFormData(data);
      setModalType("isEdit");
    }
  };

  return (
    <>
      <Layout>
        <Row className="align-items-center">
          <Col>
            <Breadcrumb path={["Event Area"]} />
          </Col>
          <Col className="text-end">
            <Tippy content="Filter">
              <img
                src={Filter}
                onClick={() => handleFilterVisibility()}
                className="img-fluid me-3"
                alt=""
                role="button"
                style={{ width: "2.5rem" }}
              />
            </Tippy>
            {(userRole === 2 || userRole === 3) && (
              <Button
                type="button"
                onClick={() => {
                  setIsOpen(true);
                  setModalType("isAdd");
                }}
              >
                Add Event Area
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {filtersVisible && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  currentPage > 1 ? setCurrentPage(1) : getList(true, 1);
                }}
                autoComplete="off"
              >
                <div className="row filter">
                  <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="event_area">Area Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="event_area"
                        value={formInputs.event_area}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="event_id">Select Event</label>
                      <Select
                        options={allEventList}
                        value={selectedEventOption}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "40px",
                            borderRadius: "8px",
                          }),

                          input: (baseStyles, state) => ({
                            ...baseStyles,
                            paddingLeft: "0px",
                          }),
                        }}
                        classNamePrefix="react-select"
                        tabSelectsValue
                        onChange={(selectedOption) => {
                          setFormInputs({
                            ...formInputs,
                            event_id: selectedOption.value,
                          });
                          setSelectedEventOption(selectedOption);
                        }}
                        name="event_id"
                        id="event_id"
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="is_active">Status</label>
                      <Select
                        options={status}
                        value={
                          status.find(
                            (el) => el?.value === formInputs.is_active
                          ) || ""
                        }
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "40px",
                            borderRadius: "8px",
                          }),

                          input: (baseStyles, state) => ({
                            ...baseStyles,
                            paddingLeft: "0px",
                          }),
                        }}
                        classNamePrefix="react-select"
                        tabSelectsValue
                        onChange={(selectedOption) => {
                          setFormInputs({
                            ...formInputs,
                            is_active: selectedOption.value,
                          });
                        }}
                        name="is_active"
                        id="is_active"
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12">
                    <div className="mt-4 pt-1">
                      <Form.Check
                        label="Past Event"
                        name="past_event"
                        id="past_event"
                        type="checkbox"
                        onChange={() => {
                          setChecked(!checked);
                          setFormInputs({
                            ...formInputs,
                            past_event: formInputs.past_event ? "" : 1,
                          });
                        }}
                        checked={checked}
                      />
                    </div>
                  </div>

                  <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12 ">
                    <label className="d-none d-lg-block"> &nbsp;</label>
                    <div className="mb-3">
                      <button className="btn btn-primary" type="submit">
                        {isLoading && (
                          <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        Search
                      </button>
                      <button
                        className="btn btn-primary rounded-3 px-3 ms-2"
                        type="button"
                        onClick={(e) => {
                          setFormInputs(initialState);
                          setChecked(false);
                          setSelectedEventOption(null);
                          currentPage > 1
                            ? setCurrentPage(1)
                            : getList(false, 1);
                        }}
                        style={{ background: "#4263eb", fontSize: "14px" }}
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Col>
        </Row>
        <Commontable
          statusChangeKey={"event_area_id"}
          changeStatus={(id, status) => {
            changeStatus(id, status);
          }}
          showStatusEnable={true}
          isLoading={isLoading}
          columns={columns}
          data={list}
          totalPage={totalPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          showNumberOrder={true}
          userRole={userRole}
          context={{
            userRole: { userRole },
            editAccess: [2, 3],
            deleteAccess: [2, 3],
            onClickActionItemClick: onClickActionItemClick,
          }}
          ActionMenuItem={ActionMenuItem}
          error={error}
        />
        <CommonModal
          isOpen={isOpen}
          title="Event Area"
          modalType={modalType}
          setIsOpen={setIsOpen}
          setFormData={setFormData}
          pageType="eventArea"
        >
          <EventAreaForm
            getEventAreaList={getEventAreaList}
            {...formData}
            modalType={modalType}
            setIsOpen={setIsOpen}
            setFormData={setFormData}
            allEventList={allEventList}
            activeEventList={activeEventList}
          />
        </CommonModal>
      </Layout>
    </>
  );
};
export default EventAreaList;
