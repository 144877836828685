import React from "react";
import Tippy from "@tippyjs/react";
import { EyeIcon, RemoveUserIcon, SendNotificationIcon } from "../Common/Icons";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import view from "../../images/view.png";
import editIcon from "../../images/icons/edit.png";
import removeUser from "../../images/icons/removeUser.png";
import "./style.css";

const ActionMenuItem = ({ data, context }) => {
  return (
    <div>
      <Tippy content="View" trigger="mouseenter">
        <img
          id="isView"
          data-name="isView"
          onClick={(e) => {
            context.onClickActionItemClick(data, e);
          }}
          src={view}
          className="img-fluid me-2"
          alt=""
        />
      </Tippy>

      {context.removedUser.map((e, i) => {
        return (
          <React.Fragment key={"at_" + i}>
            {e === context.userRole.userRole && data.is_active !== 0 && (
              <Tippy content="Remove Guest" trigger="mouseenter">
                <img
                  width={22}
                  height={20}
                  data-name="isRemove"
                  id="isRemove"
                  onClick={(e) => {
                    context.onClickActionItemClick(data, e);
                  }}
                  src={removeUser}
                  className="img-fluid me-2"
                  alt=""
                />
              </Tippy>
            )}
          </React.Fragment>
        );
      })}
      {context.communation.map((e, i) => {
        return (
          <React.Fragment key={"at_" + i}>
            {e === context.userRole.userRole && data.is_active !== 0 && (
              <Tippy content="Send Invitation" trigger="mouseenter">
                <Dropdown className="d-inline " >
                  <Dropdown.Toggle
                    className="ps-0 pe-0"
                    variant="none"
                    id={"a_" + i}
                    style={{ padding: "0", margin: "-0.25rem -0.5rem" }}
                  >
                    <SendNotificationIcon size="18" color="#7f8192" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="div"
                      role="button"
                      data-name="notification"
                      id="notification"
                      data-type="2"
                      onClick={(e) => context.onClickActionItemClick(data, e)}
                      style={{ padding: "0.25rem 1rem" }}
                    >
                      Email
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      role="button"
                      data-name="notification"
                      id="notification"
                      data-type="1"
                      onClick={(e) => context.onClickActionItemClick(data, e)}
                      style={{ padding: "0.25rem 1rem" }}
                    >
                      Whatsapp
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      role="button"
                      data-name="notification"
                      id="notification"
                      data-type="3"
                      onClick={(e) => context.onClickActionItemClick(data, e)}
                      style={{ padding: "0.25rem 1rem" }}
                    >
                      Send SMS
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Tippy>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
export default ActionMenuItem;
