import React from "react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const StatsLoading = () => {
  return (
    <>
      <div
        style={{ width: "22%" }}
        className="col-sm-2 mx-2 rounded-4 p-3 shadow-sm bg-theme-lavendar"
        id="buyers"
      >
        {/* <img src={dashboard1} className="img-fluid" alt=""/> */}
        <p className="mb-1 text-white fs-small">Events</p>
        {/* <p className="fs-4 ps-2 text-white">20</p> */}
        <Skeleton />
      </div>
      <div
        style={{ width: "22%" }}
        className="col-sm-2 mx-2 rounded-4 p-3 shadow-sm bg-white"
        id="sellers"
      >
        {/* <img src={dashboard1} className="img-fluid" alt=""/> */}
        <p className="mb-1 text-secondary fs-small">Guests</p>
        {/* <p className="fs-4 ps-2 text-black">15</p> */}
        <Skeleton />
      </div>
    </>
  );
};

export default StatsLoading;
