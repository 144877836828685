import * as Yup from 'yup'

const companySchema = {}

companySchema.validateCompanyAdd = () => {
  return Yup.object({
    comp_name: Yup.string()
      .trim()
      .required('Company name is required.')
      .min(2, 'Company name minimum length is 2')
      .max(45, 'Company name should be at most 45 characters.')
      // .matches(/^[aA-z0-9Z-\s]+$/, `Company name should be Alphanumeric only`),
      // comp_logo: Yup.string().trim().required("Company Logo is required."),
      
  })
}

companySchema.validateCompanyEdit = () => {
  return Yup.object({
    comp_name: Yup.string()
      .trim()
      .required('Company name is required.')
      .min(2, 'Company name minimum length is 2')
      .max(45, 'Company name should be at most 45 characters.')
      // .matches(/^[aA-z0-9Z-\s]+$/, `Company name should be Alphanumeric only`),
      // comp_logo: Yup.string().trim().required("Company Logo is required."),
     
  })
}

export default companySchema
