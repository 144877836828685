import "./style.css";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { apiListCompany } from "../../../api/master";
import config from "../../../config";

function UserEditView(props) {
  const [user, setUser] = useState({});

  const [compList, setCompList] = useState([]);

  async function CompanyList() {
    await apiListCompany({ paginate: 0 }).then((result) =>
      setCompList(result?.data?.data.list)
    );
  }

  useEffect(function () {
    if(user.role_id === 1){
      CompanyList();
    }
  }, []);

  // const filterCompName = compList?.filter(el => Number(el?.role_id) === Number(selectedcode?.value));
  const companyListMap = compList?.flatMap((el) => {
      return {
        value: el?.comp_id,
        label: el.comp_name,
        // label: el.name,

      };
  });
  useEffect(function () {
    if (localStorage.getItem("go-ticket")) {
      setUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
    }
  }, []);
  
  const roleListIng = [{id:1,title:'Super Admin'},{id:2,title:'Admin'},
  
  ]

  return (
    <>
      <Modal.Header closeButton>
        <h4 style={{ marginLeft: "12rem", height: "4px" }} id="modalhead">
          View User
        </h4>
      </Modal.Header>
      <div className="container-fluid p-4" id="userAdd">
        <form className="row gx-5 gy-2">
          <div className="col-md-6">
            <label htmlFor="user_name">User Name</label>
            <input
              disabled
              type="text"
              className="form-control"
              id="user_name"
              value={props.id?.user_name}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="mobile">Mobile No</label>
            <input
              disabled
              type="text"
              className="form-control"
              id="mobile"
              maxLength={10}
              value={props.id?.user_mob}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="role_id">User Role</label>
            <input
              disabled
              type="text"
              className="form-control"
              id="mobile"
              // value={props.id?.role_id}
              value={config.roleListIng.find((el)=> el?.id === props.id?.role_id )?.title || "N/A" }
            />
          </div>
          {user.role_id === 1 ?  <div className="col-md-6">
            <label htmlFor="comp_name">Company Name</label>
            <input
              disabled
              type="text"
              className="form-control"
              id="comp_name"
              value={props.id?.mst_comp?.comp_name}
            />
          </div> : null} 

          <div className="col-md-6">
            <label htmlFor="mobile">Pin</label>
            <input
              disabled
              type="text"
              className="form-control"
              id="mobile"
              maxLength={10}
              value={props.id?.pin}
            />
          </div>
                                            
          <div className="col-12 mt-4">
            <button
              onClick={props.hide}
              style={{ float: "right" }}
              type="button"
              className="branchModalCancel">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default UserEditView;
