import Modal from "react-bootstrap/Modal";
import "./style.css";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { apiEditCompany } from "../../../api/master";
import companySchema from "../../../validations/Company";
import UploadIcon from "../../../images/upload.svg";
import { CloseIcon } from "../../../components/Common/Icons";

function CompanyEdit(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [shownew, setShownew] = useState(false);

  const [logo, setLogo] = useState("");
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const companyFormik = useFormik({
    initialValues: {
      comp_id: props.id?.comp_id,
      comp_name: props.id?.comp_name,
      comp_logo: props.id?.comp_logo,
    },
    validationSchema: companySchema.validateCompanyEdit(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      // values = {
      //   comp_logo: values.comp_logo,
      //   comp_id: Number(values.comp_id),
      //   comp_name: values.comp_name,
      // };
      if (!file && !logo) {
        setFileError("Please Select Logo");
        setIsSubmit(false);
        return;
      }

      const formData = new FormData();
      formData.append("comp_name", values.comp_name.trim());
      formData.append("comp_id", Number(values.comp_id));
      formData.append("comp_logo", file ? file : logo);
      setIsSubmit(true);
      await apiEditCompany(formData)
        .then((data) => {
          setSuccess(data.data.message);
          setError("");
          setFileError("");
          props.list();
        })
        .catch((err) => {
          setFileError("");
          setError(err.response.data.data.message);
          setSuccess("");
        })
        .finally((result) => {
          setIsSubmit(false);
          setFileError("");
          setTimeout(() => {
            setSuccess("");
            setError("");
          }, 5000);
        });
    },
  });

  useEffect(function () {}, [success, error]);

  useEffect(function () {
    props?.id?.comp_logo && setLogo(props?.id?.comp_logo);
  }, []);

  return (
    <>
      <Modal.Header closeButton>
        <h4 style={{ marginLeft: "10rem", height: "4px" }} id="modalhead">
          Edit Company
        </h4>
      </Modal.Header>
      <div className="container-fluid p-4" id="buyerAdd">
        <form
          className="row gx-5 gy-2"
          onSubmit={companyFormik.handleSubmit}
          autoComplete="off"
        >
          <div className="col-md-6">
            <label htmlFor="comp_name">
              Company Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              maxLength={100}
              className="form-control"
              id="comp_name"
              {...companyFormik.getFieldProps("comp_name")}
            />
            {companyFormik.errors.comp_name && shownew != true ? (
              <div className="text-danger small">
                {companyFormik.errors.comp_name}
              </div>
            ) : null}
          </div>
          {/* <div className="col-md-6">
            <label htmlFor="comp_logo">Company logo</label>
            <input
              type="file"
              // maxLength={100}
              className="form-control"
              id="comp_logo"
              onChange={handleFileChange}
              // {...companyFormik.getFieldProps("comp_logo")}
            />

{logo && (
              <img
                className="img-fluid rounded"
                src={logo}
                width="100px"
                height="100px"
                style={{marginTop:"10px"}}
                alt=""
              />
            )}
            {/* {companyFormik.errors.comp_logo && shownew != true ? (
              <div className="text-danger small">
                {companyFormik.errors.comp_logo}
              </div>
            ) : null} */}
          {/* </div> */}
          <div className="col-md-6">
            <label htmlFor="comp_logo">
              Company logo <span className="text-danger">*</span>
            </label>
            <div className="uploadBox mt-1">
              {!logo ? (
                <label className="" for="comp_logo">
                  <div className="uploadThumbnial">
                    <img src={UploadIcon} alt="" />
                  </div>
                </label>
              ) : (
                <>
                  <span
                    className="removeImage"
                    role="button"
                    onClick={() => {
                      setLogo("");
                      setFile("");
                    }}
                  >
                    <CloseIcon />
                  </span>
                  <label className="" for="comp_logo">
                    <div className="previewBox">
                      <img src={logo} alt="" />
                    </div>
                  </label>
                </>
              )}
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={handleFileChange}
                id="comp_logo"
                autoComplete="off"
                name="comp_logo"
              />
            </div>
            {fileError ? (
              <div className="text-danger small">{fileError}</div>
            ) : null}
          </div>
          <div className="col-12 mt-4">
            {(error || success) && (
              <div
                className={`alert alert-${
                  error ? "danger" : "success"
                } py-2 small`}
                role="alert"
              >
                {error || success || ""}
              </div>
            )}
            <div style={{ float: "right" }}>
              <button
                className="branchModalCancel"
                onClick={props.hide}
                type="button"
              >
                Cancel
              </button>
              {shownew ? (
                <button
                  id="createNew"
                  onClick={() => {
                    companyFormik.resetForm();
                    setShownew(false);
                    setSuccess("");
                  }}
                >
                  Add New
                </button>
              ) : (
                <button type="submit" disabled={isSubmit}>
                  {isSubmit && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CompanyEdit;
