import { useEffect, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { FormControl } from "react-bootstrap";

import Select from "react-select";
import { apiGuestAdd } from "../../api/master";
import guestsSchema from "../../validations/guest";

function GuestForm(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [shownew, setShownew] = useState(false);
  const [allEventList, setAllEventList] = useState([]);
  const [activeEventList, setActiveEventList] = useState([]);
  const [allGuestTypeList, setAllGuestTypeList] = useState([]);
  const [activeGuestTypeList, setActiveGuestTypeList] = useState([]);
  const [error, setError] = useState("");
  const [readonly, setReadonly] = useState(
    props.modalType === "isView" || false
  );
  const status = [
    { id: 1, name: "new", className: "primary" },
    { id: 2, name: "invitation sent", className: "secondary" },
    { id: 3, name: "may be", className: "warning " },
    { id: 4, name: "confirm", className: "success" },
    { id: 5, name: "declined", className: "danger" },
    { id: 6, name: "paid", className: "success" },
    { id: 7, name: "attended", className: "success" },
  ];

  const [initialValues1, setInitialValues] = useState({
    event_id: props?.event_id || "",
    name: props?.name || "",
    mobile_no: props?.mobile || "",
    email: props?.email || "",
    guest_type_id: props?.mst_guest_type?.guest_type_id || "",
    guest_type: props?.mst_guest_type?.guest_type || "",
    reffered_by: props?.reffered_by || "",

    is_active: props?.is_active || "",
  });

  const checkStatus = (id) => {
    let statusObj = status.filter((e) => e.id === id)[0];
    return (
      <span
        name="status"
        id="status"
        className={`text-capitalize badge rounded-pill bg-${statusObj?.className} `}
      >
        {statusObj?.name}
      </span>
    );
  };

  useEffect(() => {
    setAllEventList(props.allEventList);
    setActiveEventList(props.activeEventList);
  }, [props.allEventList, props.activeEventList]);

  useEffect(() => {
    setAllGuestTypeList(props.allGuestTypeList);
    setActiveGuestTypeList(props.activeGuestTypeList);
  }, [props.allGuestTypeList, props.activeGuestTypeList]);

  useEffect(function () { }, [error]);
  console.log("initialValues1", initialValues1)
  return (
    <Formik
      initialValues={initialValues1}
      validationSchema={guestsSchema.validateGuestAdd()}
      validateOnChange={false}
      validateOnBlur={false}
      onReset={(values) => {
        props.setCurrentEvent(null);
        setShownew(false);
        setSuccess("");
        props.setFormData({
          event_id: "",
          name: "",
          mobile_no: "",
          email: "",
          guest_type_id: "",
          reffered_by: "",
          is_active: "",
        });
      }}
      onSubmit={(values) => {
        const reqData = {
          name: values.name.trim(),
          event_id: values.event_id,
          mobile: values.mobile_no.trim(),
          email: values.email.trim(),
          reffered_by: values.reffered_by.trim(),
          guest_type_id: values.guest_type_id,
        };

        apiGuestAdd(reqData)
          .then((data) => {
            setSuccess(data.data.message);
            setError("");
            setShownew(true);
            props.setFormData("");
            props.setIsGuestList();
            props.setCurrentEvent(null);
          })
          .catch((err) => {
            setError(err.response.data.data.message);
          })
          .finally((result) => {
            setIsSubmit(false);
          });
      }}
    >
      {({
        isSubmitting,
        handleChange,
        values,
        setFieldValue,
        errors,
        touched,
      }) => (
        <>
          <FormikForm>
            <div className="row  gy-2 mb-3">
              <div className="col-md-12">
                <label htmlFor="name">
                  Guest Name{" "}
                  {!readonly && <span className="text-danger">*</span>}
                </label>
                <FormControl
                  type="text"
                  autoComplete="off"
                  maxLength={100}
                  onChange={(event) => {
                    const re = /^[A-Za-z0-9 ]*$/;

                    if (
                      event.target.value === "" ||
                      re.test(event.target.value)
                    ) {
                      setFieldValue("name", event.target.value);
                    }
                  }}
                  value={values.name}
                  name="name"
                  id="name"
                  readOnly={readonly}
                />
                {errors?.name && touched?.name ? (
                  <div className="text-danger small">{errors?.name} </div>
                ) : null}
              </div>
              <div className="col-md-12">
                <label htmlFor="mobile_no">
                  Mobile Number{" "}
                  {!readonly && <span className="text-danger">*</span>}
                </label>
                <FormControl
                  type="text"
                  autoComplete="off"
                  maxLength={10}
                  onChange={(event) => {
                    const re = /^[0-9\b]+$/;

                    if (
                      event.target.value === "" ||
                      re.test(event.target.value)
                    ) {
                      setFieldValue("mobile_no", event.target.value);
                    }
                  }}
                  value={values.mobile_no}
                  name="mobile_no"
                  id="mobile_no"
                  readOnly={readonly}
                />
                {errors?.mobile_no && touched.mobile_no ? (
                  <div className="text-danger small">{errors?.mobile_no} </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <label htmlFor="event_id">
                  {props.modalType === "isAdd" && "Select"} Event{" "}
                  {!readonly && <span className="text-danger">*</span>}
                </label>
                <Select
                  isDisabled={
                    readonly || props.modalType === "isEdit" ? true : false
                  }
                  options={activeEventList}
                  value={
                    (props.modalType === "isAdd"
                      ? activeEventList.find(
                        (el) => el?.value === values.event_id
                      )
                      : allEventList.find(
                        (el) => el?.value === values.event_id
                      )) || ""
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "0px",
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "0px",
                    }),
                  }}
                  classNamePrefix="react-select"
                  tabSelectsValue
                  onChange={(selectedOption) => {
                    setFieldValue("event_id", selectedOption.value);
                    props.setCurrentEvent(selectedOption.value);
                  }}
                  name="event_id"
                  id="event_id"
                />
                {errors?.event_id && touched.event_id ? (
                  <div className="text-danger small">{errors?.event_id} </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <label htmlFor="guest_type_id">
                  {props.modalType === "isAdd" && "Select"} Guest Type{" "}
                  {!readonly && <span className="text-danger">*</span>}
                </label>
                <Select
                  isDisabled={readonly ? true : false || values.event_id === ""}
                  options={activeGuestTypeList}
                  value={
                    (props.modalType === "isAdd"
                      ? activeGuestTypeList.find(
                        (el) => el?.value === values.guest_type_id
                      )
                      : {
                        label: values.guest_type,
                        value: values.guest_type_id,
                      }) || ""
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "0px",
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "0px",
                    }),
                  }}
                  classNamePrefix="react-select"
                  tabSelectsValue
                  onChange={(selectedOption) => {
                    setFieldValue("guest_type_id", selectedOption.value);
                  }}
                  name="guest_type_id"
                  id="guest_type_id"
                />
                {errors?.guest_type_id && touched.guest_type_id ? (
                  <div className="text-danger small">
                    {errors?.guest_type_id}{" "}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <label htmlFor="email">Email</label> {" "}
                {/* {!readonly && <span className="text-danger">*</span>} */}
                <FormControl
                  type="text"
                  autoComplete="off"
                  onChange={(event) => {
                    setFieldValue("email", event.target.value);
                  }}
                  value={values.email}
                  name="email"
                  id="email"
                  readOnly={readonly}
                />
                {errors?.email && touched.email ? (
                  <div className="text-danger small">{errors?.email} </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <label htmlFor="reffered_by">Referred By</label>
                <FormControl
                  type="text"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.reffered_by}
                  name="reffered_by"
                  id="reffered_by"
                  readOnly={readonly}
                />
              </div>
            </div>
            {props?.modalType === "isView" && props?.is_active ? (
              <div className="col-md-6">
                <label htmlFor="status">Status</label>
                <br />
                {checkStatus(props?.is_active)}
              </div>
            ) : null}
            <div className="col-12 mt-4">
              {(error || success) && (
                <div
                  className={`alert alert-${error ? "danger" : "success"
                    } py-2 small`}
                  role="alert"
                >
                  {error || success || ""}
                </div>
              )}
              <div style={{ float: "right" }}>
                <button
                  className="branchModalCancel"
                  onClick={() => {
                    props.setFormData("");
                    props.setIsOpen(false);
                    props.setCurrentEvent(null);
                  }}
                  type="button"
                >
                  Cancel
                </button>
                {props.modalType !== "isView" &&
                  (shownew ? (
                    <button id="createNew" type="reset">
                      Add New
                    </button>
                  ) : (
                    <button type="submit" disabled={isSubmit}>
                      {isSubmit && (
                        <span className="spinner-border spinner-border-sm me-2"></span>
                      )}
                      Save
                    </button>
                  ))}
              </div>
            </div>
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

export default GuestForm;
