import { Button, Col, FormControl, Row, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
  ApiAssignUserRole,
  apiEventAreaList,
  apiListUser,
  getAssignmentList,
} from "../../api/master";
import { SaveIcon, TrashIcon } from "../../components/Common/Icons";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import eventsSchema from "../../validations/events";

export default function AssignUserTable({ eventId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [isShowRow, setIsShowRow] = useState(false);
  const [isEditShowRow, setIsEditShowRow] = useState(false);
  const [editShowRowData, setEditShowRowData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [eventAreaList, setEventAreaList] = useState([]);
  const [eventArea, setEventArea] = useState([]);
  const [selectedEventAreas, setSelectedEventAreas] = useState([]);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [user, setUser] = useState({});

  const [currUser, setCurrUser] = useState({});

  const day = new Date();
  const today = day.setDate(new Date().getDate());

  useEffect(() => {
    if (localStorage.getItem("go-ticket")) {
      setCurrUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
    } else {
      setCurrUser(null);
    }
  }, []);

  useEffect(() => {
    getList();
  }, []);
  const getList = () => {
    let json = {
      event_id: eventId.event_id,
    };
    setIsLoading(true);
    getAssignmentList(json).then((res) => {
      let data = res.data.data.list;

      let newArr = data;
      setList(newArr);
      setIsLoading(false);
      getUserList(newArr);
    });
  };

  const getEventAreaList = () => {
    let json = {
      event_id: eventId.event_id,
      is_active: true,
    };
    apiEventAreaList(json).then((res) => {
      let data = res.data.data.list;
      let newStateArray = res.data.data.list.map((item) => {
        return {
          label: item.event_area,
          value: item.event_area_id,
        };
      });

      setEventAreaList(newStateArray);
    });
  };
  useEffect(() => {
    // getList();
    getEventAreaList();
  }, []);
  console.log("eventAreaList", eventAreaList);
  const getUserList = async (newArr) => {
    let json = { paginate: 0 };
    await apiListUser(json)
      .then((data) => {
        let newStateArray = data.data.data.list.map((item) => {
          return {
            user_id: item.user_id,
            label: item.user_name,
            value: item.user_id,
            mst_role: item.mst_role,
            sec_st_dt: item.sec_st_dt,
            sec_end_dt: item.sec_end_dt,
            is_active: item.is_active,
          };
        });

        let filteredArr = newStateArray.filter(
          (obj1) => !newArr.some((obj2) => obj1.user_id === obj2.user_id)
        );
        filteredArr = filteredArr.filter((e) => e.is_active);
        setUserList(filteredArr);
      })
      .catch(function (err) {});
  };

  function changeStatus(user_id, status, user_event_id, event_area_id) {
    let target = window.event.target;
    let oldText = target.innerText;
    target.innerText = "Updating...";

    if (userRole?.mst_role?.role_id == 4 || status == 2) {
      try {
        eventsSchema
          .validateEventChangeStatus(editShowRowData, eventAreaList)
          .validateSync(
            {
              user_id: user_id,
              event_area_id: eventArea || [],
              sec_st_dt:
                moment(fromDate).format("YYYY-MM-DD H:mm") == "Invalid date"
                  ? null
                  : moment(fromDate).format("YYYY-MM-DD H:mm"),
              sec_end_dt:
                moment(endDate).format("YYYY-MM-DD H:mm") == "Invalid date"
                  ? null
                  : moment(endDate).format("YYYY-MM-DD H:mm"),
            },
            { abortEarly: false }
          );
      } catch (e) {
        console.log(e);
        setError(e?.errors?.[0]);
        target.innerText = oldText;
        setTimeout(() => {
          setSuccess("");
          setError("");
        }, 5000);

        return false;
      }
    }

    let payload = {};
    payload.user_id = user_id;
    payload.event_area_id = eventArea || event_area_id;
    payload.event_id = eventId.event_id;
    payload.user_event_id = user_event_id;
    payload.type = status;
    payload.sec_st_dt = null;
    payload.sec_end_dt = null;
    if (userRole?.mst_role?.role_id == 4 || status == 2) {
      payload.sec_st_dt = moment(fromDate).format("YYYY-MM-DD H:mm");
    }
    if (userRole?.mst_role?.role_id == 4 || status == 2) {
      payload.sec_end_dt = moment(endDate).format("YYYY-MM-DD H:mm");
    }

    ApiAssignUserRole(payload)
      .then((data) => {
        setIsLoading(false);
        setIsEditShowRow(false);
        setError("");
        setSuccess("success");
        handleDeleteRow();
        setFromDate("");
        setEndDate("");
      })
      .catch((err) => {
        setError(err.response.data.data.message);
        setTimeout(function () {
          setError("");
        }, 5000);
      })
      .finally(() => {
        target.innerText = oldText;
        getList();
        setTimeout(() => {
          setSuccess("");
          setError("");
        }, 5000);
      });
  }

  const isToggleRow = () => {
    setIsShowRow(true);
  };

  const handleDeleteRow = () => {
    setIsShowRow(false);
    setUserRole("");
    setEventArea("");
  };

  const handleChange = (event) => {
    console.log(event);
    setShowError(false);
    // setIsShowRow(false);
    setUserRole(event);
    // setUserRole("");
  };

  useEffect(() => {
    const initialSelectedAreas = eventAreaList.filter((area) =>
      editShowRowData.event_area_id?.includes(area.value)
    );
    setSelectedEventAreas(initialSelectedAreas);
    let eventAreaArray = editShowRowData.event_area_id;

    try {
      eventAreaArray = JSON.parse(eventAreaArray);
    } catch (error) {
      // console.error("Failed to parse event_area_id:", error);
    }

    if (!Array.isArray(eventAreaArray)) {
      // console.error("event_area_id is not an array:", eventAreaArray);
      eventAreaArray = [];
    }

    setEventArea(eventAreaArray);
  }, [eventAreaList, editShowRowData]);

  const handleChangeEventarea = (selectedOptions) => {
    const eventAreaValues = selectedOptions.map((area) => area.value);
    setEventArea(eventAreaValues);
    setSelectedEventAreas(selectedOptions);
  };

  const handleUserEventEdit = (user, e) => {
    console.log("user", user);
    setEditShowRowData(user);
    setFromDate(new Date(moment(user?.sec_st_dt).format("YYYY-MM-DD H:mm")));
    setEndDate(new Date(moment(user?.sec_end_dt).format("YYYY-MM-DD H:mm")));
    setIsEditShowRow(true);
  };

  // const filterPassedTimeStart = (time) => {
  //   const currentDate = new Date();
  //   const selectedDate = new Date(time);

  //   return currentDate.getTime() < selectedDate.getTime();
  // };
  // const filterPassedTimeStart = (time) => {
  //   const currentDate = new Date();
  //   const oneHourAgo = new Date(currentDate.getTime() - 60 * 60 * 1000);
  //   const selectedDate = new Date(time);

  //   return selectedDate.getTime() >= oneHourAgo.getTime();
  // };

  const filterPassedTimeStart = (time) => {
    const currentDate = new Date();
    const thirtyMinutesAgo = new Date(currentDate.getTime() - 60 * 60 * 1000);
    const selectedDate = new Date(time);

    return selectedDate.getTime() >= thirtyMinutesAgo.getTime();
  };

  const filterPassedTimeEnd = (time) => {
    const currentDate = fromDate;
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <Row>
        <Col className="text-end">
          <Button
            type="button"
            className="add"
            variant="primary"
            size="sm"
            onClick={isToggleRow}
            disabled={isShowRow}
          >
            {" "}
            Add User
          </Button>
        </Col>
      </Row>

      <Table className="table rounded dataTable user-assign-table commonTable">
        <colgroup>
          <col style={{ width: 140 }} />
          <col style={{ width: 90 }} />
          <col style={{ width: 150 }} />
          <col style={{ width: 150 }} />
          <col style={{ width: 150 }} />
          <col style={{ width: 88 }} />
        </colgroup>
        <thead>
          <tr>
            <th>User Name</th>
            <th>Role</th>
            <th>Event Area</th>
            <th>Start Date & Time</th>
            <th>End Date & Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <span className="spinner-border spinner-border-sm me-2"></span>
          ) : list && list.length > 0 ? (
            <>
              {list &&
                list.map((item, i) => {
                  return (
                    <tr key={"key_" + i}>
                      <td>{item.mst_user?.user_name}</td>
                      <td>{item.mst_user?.mst_role?.role_name}</td>
                      <td>
                        {(() => {
                          const eventAreaIds = JSON.parse(item.event_area_id);

                          return (
                            <Tippy
                              content={
                                eventAreaIds?.[0] === 1
                                  ? "All"
                                  : eventAreaList
                                      .filter((area) =>
                                        eventAreaIds?.includes(area.value)
                                      )
                                      .map((area) => area.label)
                                      .join(", ")
                              }
                              placement="top"
                            >
                              <span>
                                {eventAreaIds?.[0] === 1
                                  ? "All"
                                  : eventAreaList
                                      .filter((area) =>
                                        eventAreaIds?.includes(area.value)
                                      )
                                      .map((area) => area.label)
                                      .join(", ")
                                      .slice(0, 30)}
                                {eventAreaIds?.[0] === 1
                                  ? ""
                                  : eventAreaList
                                      .filter((area) =>
                                        eventAreaIds?.includes(area.value)
                                      )
                                      .map((area) => area.label)
                                      .join(", ").length > 30
                                  ? "..."
                                  : ""}
                              </span>
                            </Tippy>
                          );
                        })()}
                      </td>

                      <td>
                        {item?.sec_st_dt
                          ? moment(item?.sec_st_dt).format("DD-MM-YYYY h:mma ")
                          : null}
                      </td>
                      <td>
                        {item?.sec_end_dt
                          ? moment(item?.sec_end_dt).format("DD-MM-YYYY h:mma ")
                          : null}
                      </td>

                      <td>
                        {item.mst_user.user_id !== user.user_id ? (
                          <>
                            {item.is_active ? (
                              <>
                                <Tippy content="Change Status">
                                  <span
                                    className="badge text-bg-success"
                                    role="button"
                                    onClick={() =>
                                      changeStatus(
                                        item?.mst_user?.user_id,
                                        0,
                                        item.user_event_id,
                                        item.event_area_id
                                      )
                                    }
                                  >
                                    Assign
                                  </span>
                                </Tippy>
                                {item.mst_user.mst_role.role_id == 4 && (
                                  <>
                                    <span
                                      className="ms-2 text-decoration-underline"
                                      role="button"
                                      onClick={(e) =>
                                        handleUserEventEdit(item, e)
                                      }
                                    >
                                      Edit
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              <Tippy content="Change Status">
                                <span
                                  className="badge text-bg-danger"
                                  role="button"
                                  onClick={() =>
                                    changeStatus(
                                      item?.mst_user?.user_id,
                                      1,
                                      item.user_event_id
                                    )
                                  }
                                >
                                  Unassign
                                </span>
                              </Tippy>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
            </>
          ) : (
            <>
              <tr>
                <td colSpan={5} className="text-center">
                  No user assigned yet
                </td>
              </tr>
            </>
          )}
          {isShowRow && (
            <tr>
              <td>
                <Select
                  options={userList.filter(
                    (user) => user.user_id !== currUser.user_id
                  )}
                  placeholder="Select User"
                  isSearchable={true}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "0px",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "0px",
                      lineHeight: "26px",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }),
                  }}
                  classNamePrefix="react-select"
                  tabSelectsValue
                  onChange={handleChange}
                  name="user_id"
                  id="user_id"
                />
                {showError && (
                  <div className={`text-danger small`}>
                    {" "}
                    Please Select User{" "}
                  </div>
                )}
              </td>
              <td>
                <FormControl
                  placeholder="User Role"
                  readOnly
                  value={userRole?.mst_role?.role_name}
                />
              </td>
              {userRole?.mst_role?.role_id == 4 ? (
                <td>
                  <Select
                    options={eventAreaList}
                    placeholder="Select Area"
                    isSearchable={true}
                    isMulti
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: "0px",
                      }),
                      valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }),
                      input: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingLeft: "0px",
                        lineHeight: "26px",
                      }),
                      dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }),
                    }}
                    classNamePrefix="react-select"
                    tabSelectsValue
                    onChange={handleChangeEventarea}
                    name="event_area_id"
                    id="event_area_id"
                  />
                </td>
              ) : (
                <td style={{ width: "20%", fontSize: "10px" }}></td>
              )}
              {userRole?.mst_role?.role_id == 4 ? (
                <td style={{ width: "27%" }}>
                  <DatePicker
                    id="sec_st_dt"
                    autoComplete="off"
                    showTimeSelect
                    placeholderText="DD-MM-YYYY HH:MM"
                    dateFormat="dd-MM-yyyy h:mma"
                    className="form-control datePicker"
                    selected={fromDate}
                    minDate={today}
                    // maxDate={userRole.sec_end_dt && userRole.sec_end_dt}
                    maxDate={userRole.sec_end_dt && userRole.sec_end_dt}
                    filterTime={filterPassedTimeStart}
                    //   minTime={day.getTime()}
                    //   maxTime={
                    //     userRole.sec_end_dt &&
                    //     userRole.sec_end_dt.setHours(
                    //       userRole.sec_end_dt.setMinutes(
                    //         userRole.sec_end_dt,
                    //         userRole.sec_end_dt.getMinutes()
                    //       ),
                    //       userRole.sec_end_dt.getHours()
                    //     )
                    //   }
                    name="sec_st_dt"
                    onChange={(date) => {
                      console.log(date);
                      setFromDate(date);
                    }}
                  />
                  {error?.sec_st_dt ? (
                    <div className="text-danger small">{error?.sec_st_dt} </div>
                  ) : null}
                </td>
              ) : (
                <td style={{ width: "20%" }}></td>
              )}
              {userRole?.mst_role?.role_id == 4 ? (
                <td style={{ width: "26%", fontSize: "10px" }}>
                  <DatePicker
                    id="sec_end_dt"
                    disabled={fromDate === ""}
                    autoComplete="off"
                    showTimeSelect
                    className="form-control datePicker"
                    placeholderText="DD-MM-YYYY HH:MM"
                    dateFormat="dd-MM-yyyy h:mma"
                    minDate={fromDate}
                    // minTime={TimeRanges}
                    selected={endDate}
                    filterTime={filterPassedTimeEnd}
                    name="sec_end_dt"
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                  />
                  {error?.sec_end_dt ? (
                    <div className="text-danger small">
                      {error?.sec_end_dt}{" "}
                    </div>
                  ) : null}
                </td>
              ) : (
                <td style={{ width: "20%", fontSize: "10px" }}></td>
              )}

              <td>
                <Button
                  variant="none px-2"
                  type="button"
                  onClick={() => {
                    userRole?.user_id ? (
                      <>{changeStatus(userRole.user_id, 1)}</>
                    ) : (
                      <>{changeStatus(userRole.user_id, 0)}</>
                    );
                  }}
                >
                  {" "}
                  <SaveIcon size="24" color="#7f8192" />
                </Button>
                <Button variant="none px-2" onClick={handleDeleteRow}>
                  <TrashIcon size="24" color="#f00" />
                </Button>
              </td>
            </tr>
          )}
          {isEditShowRow && (
            <tr>
              <td>
                <FormControl
                  placeholder="User Name"
                  readOnly
                  value={editShowRowData?.mst_user?.user_name}
                />
              </td>
              <td>
                <FormControl
                  placeholder="User Role"
                  readOnly
                  value={editShowRowData?.mst_user?.mst_role?.role_name}
                />
              </td>
              <td>
                <Select
                  options={eventAreaList}
                  placeholder="Select Area"
                  isSearchable={true}
                  isMulti
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "0px",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "0px",
                      lineHeight: "26px",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }),
                  }}
                  classNamePrefix="react-select"
                  tabSelectsValue
                  onChange={handleChangeEventarea}
                  value={selectedEventAreas}
                  name="event_area_id"
                  id="event_area_id"
                />
              </td>

              <td style={{ width: "27%" }}>
                <DatePicker
                  id="sec_st_dt"
                  autoComplete="off"
                  showTimeSelect
                  placeholderText="DD-MM-YYYY HH:MM"
                  dateFormat="dd-MM-yyyy h:mma"
                  className="form-control datePicker"
                  selected={fromDate}
                  minDate={
                    today < new Date(editShowRowData?.sec_st_dt)
                      ? today
                      : new Date(editShowRowData?.sec_st_dt)
                  }
                  // minTime={
                  //   today < new Date(editShowRowData?.sec_st_dt)
                  //     ? today
                  //     : new Date(editShowRowData?.sec_st_dt)
                  // }
                  // maxDate={userRole.sec_end_dt && userRole.sec_end_dt}
                  // maxDate={userRole.sec_end_dt && userRole.sec_end_dt}
                  name="sec_st_dt"
                  onChange={(date) => {
                    console.log(date);
                    setFromDate(date);
                  }}
                />
                {error?.sec_st_dt ? (
                  <div className="text-danger small">{error?.sec_st_dt} </div>
                ) : null}
              </td>
              <td style={{ width: "26%", fontSize: "10px" }}>
                <DatePicker
                  id="sec_end_dt"
                  disabled={fromDate === ""}
                  autoComplete="off"
                  showTimeSelect
                  className="form-control datePicker"
                  placeholderText="DD-MM-YYYY HH:MM"
                  dateFormat="dd-MM-yyyy h:mma"
                  minDate={fromDate}
                  // minTime={fromDate}
                  // minTime={TimeRanges}
                  selected={endDate}
                  name="sec_end_dt"
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
                {error?.sec_end_dt ? (
                  <div className="text-danger small">{error?.sec_end_dt} </div>
                ) : null}
              </td>

              <td>
                <Button
                  variant="none px-2"
                  type="button"
                  onClick={() =>
                    changeStatus(
                      editShowRowData?.user_id,
                      2,
                      editShowRowData?.user_event_id
                    )
                  }
                >
                  {" "}
                  <SaveIcon size="24" color="#7f8192" />
                </Button>
                <Button
                  variant="none px-2"
                  onClick={() => setIsEditShowRow(false)}
                >
                  <TrashIcon size="24" color="#f00" />
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {(error || success) && (
        <div
          className={`alert alert-${error ? "danger" : "success"} py-2 small`}
          role="alert"
        >
          {error || success || ""}
        </div>
      )}
    </>
  );
}
