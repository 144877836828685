import { useEffect, useState } from "react";
import { Form as FormikForm, Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { apiEventDelete } from "../../api/master";
import { Button } from "react-bootstrap";
import eventsSchema from "../../validations/events";

function EventCencelForm(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [readonly, setReadonly] = useState(
    props.modalType === "isView" || false
  );
  const [error, setError] = useState("");
  const [initialValues1, setInitialValues] = useState({
    event_id: props?.event_id || "",
  });
  useEffect(function () {}, [error]);
  const cancelled = () => {
    props.setIsOpen(false);
    props.setFormData("");
  };
  return (
    <Formik
      initialValues={initialValues1}
      validationSchema={eventsSchema.validateEventCencel()}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        const formData = new FormData();
        formData.append("event_id", values.event_id);
        formData.append("is_active", values.is_active);
        formData.append("reason", values.reason);
        apiEventDelete(formData)
          .then((data) => {
            setSuccess(data.data.message);
            setError("");
            props.setFormData({ reason: values.reason });
            props.getEventList();
            setIsSubmit(true);
          })
          .catch((err) => {
            setError(err.response.data.data.message);
            setSuccess("");
            // setIsSubmit(true);
          })
          .finally((result) => {
            // setIsSubmit(true);
            setTimeout(() => {
              setSuccess("");
              setError("");
            }, 5000);
          });
      }}>
      {({ isSubmitting, handleChange, values, setFieldValue, errors }) => (
        <>
          <FormikForm>
            <div className="row  gy-2 mb-3">
              <div className="col-md-12">
                <label htmlFor="reason">
                  Reason {!readonly && <span className="text-danger">*</span>}
                </label>
                <textarea
                  className="form-control"
                  onChange={handleChange}
                  value={values.reason}
                  minLength={5}
                  maxLength={250}
                  name="reason"
                  id="reason"></textarea>
              </div>
            </div>
            <div className="col-12">
              {errors.reason && (
                <div className="alert alert-danger py-2 small" role="alert">
                  {errors.reason}
                </div>
              )}
            </div>
            <div className="col-12 mt-4">
              {(error || success) && (
                <div
                  className={`alert alert-${
                    error ? "danger" : "success"
                  } py-2 small`}
                  role="alert">
                  {error || success || ""}
                </div>
              )}
              <div style={{ float: "right" }}>
                <Button
                  type="button"
                  className="mx-2"
                  variant="secondary rounded"
                  onClick={() => cancelled()}>
                  Cancel
                </Button>
                {props.modalType !== "isView" && (
                  <Button
                    variant="danger me-2 rounded"
                    type="submit px-4"
                    disabled={isSubmit}>
                    {/* {isSubmit && (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    )} */}
                    Save
                  </Button>
                )}
              </div>
            </div>
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

export default EventCencelForm;
