import $ from "jquery";
import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { apiCreateTeamDtl } from "../../api/master";
import "./style.css";
import imageCompression from 'browser-image-compression';

const Editmodal = ({ validateFormData, reqFields, handleClose, linktoken, getlist, data, isKeyPresentAndEqualToY }) => {

    const openPicRef = useRef(null);
    const openAadharRef = useRef(null);
    const openPanRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const handleFileSelect = (ref) => {
        if (ref.current) {
            ref.current.click();
        }
    };


    // const handleFileChange = (e, setImagePreview) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setImagePreview(URL.createObjectURL(file));
    //         setForminputs((prev) => ({
    //             ...prev,
    //             [e.target.name]: file
    //         }));
    //     }
    // };



    const initialValues = {
        team_dtl_id: data?.team_dtl_id,
        name: data?.name,
        mobile: data?.mobile,
        pic: data?.pic,
        id_1: data?.id_1,
        id_2: data?.id_2,
    };

    const initialValues1 = {
        name: "",
        mobile: "",
        pic: "",
        id_1: "",
        id_2: "",
    };
    const [forminputs, setForminputs] = useState(initialValues)
    const [error, setError] = useState(initialValues1);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [picPreview, setPicPreview] = useState(data?.pic);
    const [id1Preview, setId1Preview] = useState(data?.id_1);
    const [id2Preview, setId2Preview] = useState(data?.id_2);

    const [success, setSuccess] = useState("");
    const [apierror, setApiError] = useState("");

    const filePicRef = useRef();
    const fileID1Ref = useRef();
    const fileID2Ref = useRef();

    const handlePaste = (event) => {
        const pastedText = event.clipboardData.getData("text/plain");
        const onlyNumbers = pastedText.replace(/\D/g, "");
        event.preventDefault();
        document.execCommand("insertText", false, onlyNumbers);
    };

    let lastKey = null;
    function inputMobile(e) {
        if (lastKey == "Control" && e.key == "v") {
            lastKey = e.key;
            return true;
        }
        if (lastKey == "Control" && e.key == "c") {
            lastKey = e.key;
            return true;
        }
        if (e.key == "Enter") {
            e.preventDefault()
        }
        lastKey = e.key;
        let allowKeys = [
            "Tab",
            "Backspace",
            "Delete",
            "Enter",
            "ArrowUp",
            "ArrowDown",
            "ArrowLeft",
            "ArrowRight",
            "Control",
        ];
        if (!($.isNumeric(e.key) || allowKeys.includes(e.key))) e.preventDefault();
    }

    const handleCreate = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("team_dtl_id", forminputs?.team_dtl_id)
        formData.append("name", forminputs?.name);
        formData.append("mobile", forminputs.mobile);
        formData.append("pic", forminputs.pic);
        formData.append("id_1", forminputs.id_1);
        formData.append("id_2", forminputs.id_2);
        const errors = validateFormData(formData, reqFields);

        if (Object.keys(errors).length) {
            setError(errors);
            return;
        }
        setIsSubmit(true)
        await apiCreateTeamDtl(formData, linktoken.split("_").slice(1).join("_"))

            .then((data) => {
                setSuccess(data.data.message);
                setApiError("");
                getlist();
                setIsSubmit(false);
                setIsEdit(true)
            })
            .catch((err) => {
                setApiError(err.response.data.data.message);
                setSuccess("");
                setIsSubmit(false);
                setIsEdit(false)
            })
            .finally(() => {
                setIsSubmit(false);
                setError(initialValues1)
                setIsEdit(true)
                setTimeout(() => {
                    setSuccess("");
                    setApiError("");
                }, 5000);
            });

    }

    // const handleImageChange = (e, setImagePreview) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setImagePreview(URL.createObjectURL(file));
    //         setForminputs((prev) => ({
    //             ...prev,
    //             [e.target.name]: file
    //         }));
    //     }
    // };
    const handleImageChange = async (e, setImagePreview) => {
        const file = e.target.files[0];
        if (file) {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            };
            setLoading(true);
            try {
                setImagePreview(URL.createObjectURL(file));
                const compressedBlob = await imageCompression(file, options);
                const compressedFile = new File([compressedBlob], file.name, {
                    type: file.type,
                    lastModified: Date.now()
                });
                setForminputs((prev) => ({
                    ...prev,
                    [e.target.name]: compressedFile
                }));
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };
    const handleFileChange = async (e, setImagePreview) => {
        const file = e.target.files[0];
        if (file) {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            };
            setLoading(true);

            console.log("file", file)
            try {
                setImagePreview(URL.createObjectURL(file));
                const compressedBlob = await imageCompression(file, options);
                const compressedFile = new File([compressedBlob], file.name, {
                    type: file.type,
                    lastModified: Date.now()
                });
                setForminputs((prev) => ({
                    ...prev,
                    [e.target.name]: compressedFile
                }));
            } catch (error) {
                console.log(error);
            }
            finally {
                setLoading(false);
            }
        }
    };
    return (
        <>
            <Modal.Header closeButton>
                <h4 style={{ height: "4px" }} id="modalhead">
                    Edit Member
                </h4>
            </Modal.Header>
            <hr />
            <div className="container-fluid p-4" id="userAdd">
                <form
                    className="row gx-5 gy-2"
                    autoComplete="off"
                >
                    <div className="row">
                        {
                            isKeyPresentAndEqualToY("name", reqFields) && (
                                <div className="col-md-6">
                                    <label htmlFor="name">Name</label> {isKeyPresentAndEqualToY("name", reqFields) && (
                                        <span className="text-danger">*</span>
                                    )}
                                    <Form.Control
                                        autoComplete="off"
                                        // required={reqFields.name === "y"}
                                        value={forminputs?.name}
                                        className="mb-2"
                                        name="name"
                                        maxLength="50"
                                        id="name"
                                        size="sm"
                                        type="text"
                                        placeholder="Enter name"
                                        onKeyDown={(e) => {
                                            if (e.key == "Enter") {
                                                e.preventDefault()
                                            }
                                        }}
                                        onChange={(e) => {
                                            setForminputs((prev) => ({
                                                ...prev,
                                                name: e.target.value
                                            }));
                                        }
                                        }
                                    />
                                    {error?.name && (
                                        <text
                                            className="small "
                                            style={{
                                                color: "red",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {error?.name}
                                        </text>
                                    )}
                                </div>

                            )
                        }
                        {
                            isKeyPresentAndEqualToY("mobile", reqFields) && (
                                <div className="col-md-6">
                                    <label htmlFor="mobile">Mobile</label>
                                    {isKeyPresentAndEqualToY("mobile", reqFields) && (
                                        <span className="text-danger">*</span>
                                    )}
                                    <Form.Control
                                        autoComplete="off"
                                        // required={reqFields.mobile === "y"}
                                        value={forminputs?.mobile}
                                        name="mobile"
                                        id="mobile"
                                        size="sm"
                                        type="mobile"
                                        maxLength="12"
                                        inputMode="numeric"
                                        // pattern="[0-9]"
                                        placeholder="Enter mobile"
                                        onPaste={handlePaste}
                                        onKeyDown={inputMobile}
                                        onChange={(e) => {
                                            setForminputs((prev) => ({
                                                ...prev,
                                                mobile: e.target.value
                                            }));
                                        }
                                        }
                                    />
                                    {error?.mobile && (
                                        <text
                                            className="small "
                                            style={{
                                                color: "red",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {error?.mobile}
                                        </text>
                                    )}
                                </div>
                            )
                        }
                        {
                            isKeyPresentAndEqualToY("pic", reqFields) && (

                                <div className="col-md-6">
                                    <label htmlFor="pic">Photo</label>
                                    {isKeyPresentAndEqualToY("pic", reqFields) && (
                                        <span className="text-danger">*</span>
                                    )} <br />


                                    <input
                                        autoComplete="off"
                                        // required={reqFields?.pic === "y"}
                                        name="pic"
                                        id="pic"
                                        // size="sm"
                                        style={{ width: "100%" }}
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        ref={filePicRef}
                                        onChange={(e) => handleImageChange(e, setPicPreview)}

                                    />

                                    <input
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        name="pic"
                                        onChange={(e) => handleFileChange(e, setPicPreview)}
                                        ref={openPicRef}
                                        style={{ display: 'none' }}
                                    />
                                    <button className="Responsive" type="button" onClick={() => handleFileSelect(openPicRef)} style={{ width: "100%", border: "none", background: "blue", borderRadius: "5px", marginTop: "5px", color: "white" }}>Open Camera</button>



                                    {error?.pic && (
                                        <text
                                            className="small "
                                            style={{
                                                color: "red",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {error?.pic}
                                        </text>
                                    )}
                                </div>
                            )
                        }
                        {
                            isKeyPresentAndEqualToY("id_1", reqFields) && (

                                <div className="col-md-6">

                                    <label htmlFor="aadhar">Aadhar</label>
                                    {isKeyPresentAndEqualToY("id_1", reqFields) && (
                                        <span className="text-danger">*</span>
                                    )}

                                    <input
                                        autoComplete="off"
                                        // required={reqFields.id_1 === "y"}
                                        name="id_1"
                                        id="id_1"
                                        style={{ width: "100%" }}
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        ref={fileID1Ref}
                                        onChange={(e) => handleImageChange(e, setId1Preview)}

                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        name="id_1"
                                        onChange={(e) => handleFileChange(e, setId1Preview)}
                                        ref={openAadharRef}
                                        style={{ display: 'none' }}
                                    />
                                    <button className="Responsive" type="button" onClick={() => handleFileSelect(openAadharRef)} style={{ width: "100%", border: "none", background: "blue", borderRadius: "5px", marginTop: "5px", color: "white" }}>Open Camera</button>
                                    {error?.id_1 && (
                                        <text
                                            className="small "
                                            style={{
                                                color: "red",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {error?.id_1}
                                        </text>
                                    )}
                                </div>
                            )
                        }
                        {
                            isKeyPresentAndEqualToY("id_2", reqFields) && (
                                <div className="col-md-6 mt-2">
                                    <label htmlFor="pan">Pan</label>
                                    {isKeyPresentAndEqualToY("id_2", reqFields) && (
                                        <span className="text-danger">*</span>
                                    )}

                                    <input
                                        autoComplete="off"
                                        // required={reqFields.id_2 === "y"}
                                        name="id_2"
                                        id="id_2"
                                        style={{ width: "100%" }}
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        ref={fileID2Ref}
                                        onChange={(e) => handleImageChange(e, setId2Preview)} />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        name="id_2"
                                        onChange={(e) => handleFileChange(e, setId2Preview)}
                                        ref={openPanRef}
                                        style={{ display: 'none' }}
                                    />
                                    <button className="Responsive" type="button" onClick={() => handleFileSelect(openPanRef)} style={{ width: "100%", border: "none", background: "blue", borderRadius: "5px", marginTop: "5px", color: "white" }}>Open Camera</button>
                                    {error?.id_2 && (
                                        <text
                                            className="small "
                                            style={{
                                                color: "red",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {error?.id_2}
                                        </text>
                                    )}
                                </div>
                            )
                        }
                    </div>


                    <div className="d-flex gap-2">
                        {/* {loading && <div>Loading...</div>} */}
                        {
                            isKeyPresentAndEqualToY("pic", reqFields) && (
                                <div className="picPreview">
                                    {picPreview &&
                                        <>
                                            <label htmlFor="">Photo</label> <br />
                                            <img src={picPreview} alt="Preview" width={60} className="preview-image" />

                                        </>}

                                </div>
                            )
                        }
                        {
                            isKeyPresentAndEqualToY("id_1", reqFields) && (

                                <div className="id_1Preview">
                                    {id1Preview &&
                                        <>
                                            <label htmlFor="">Aadhar</label><br />
                                            <img src={id1Preview} alt="Preview" width={60} className="preview-image" />
                                        </>
                                    }

                                </div>
                            )
                        }
                        {
                            isKeyPresentAndEqualToY("id_2", reqFields) && (
                                <div className="id_2Preview">
                                    {id2Preview &&
                                        <>
                                            <label htmlFor="">Pan</label> <br />
                                            <img src={id2Preview} alt="Preview" width={60} className="preview-image" />
                                        </>
                                    }
                                </div>
                            )
                        }

                    </div>


                    <div className="col-12 mt-4">
                        {(apierror || success) && (
                            <div
                                className={`alert alert-${apierror ? "danger" : "success"
                                    } py-2 small`}
                                role="alert"
                            >
                                {apierror || success || ""}
                            </div>
                        )}
                        <div style={{ float: "right" }}>
                            <button
                                className="branchModalCancel"
                                onClick={handleClose}
                                type="button"
                            >
                                Cancel
                            </button>

                            <button disabled={isEdit} onClick={handleCreate} className="btn btn-primary rounded-1">
                                {isSubmit && (
                                    <span className="spinner-border spinner-border-sm me-2"></span>
                                )}
                                Save
                            </button>

                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Editmodal
