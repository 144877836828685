import * as Yup from "yup";

const TeamSchema = {};

TeamSchema.validateTeamAdd = () => {
  return Yup.object({
    team_name: Yup.string()
      .trim()
      .required("Team name is required.")
      .min(3, "Team name minimum length is 3")
      .max(30, "Team name should be at most 30 characters."),
      // .matches(/^[aA-z0-9Z-\s]+$/, `Team name should be alphanumeric`),
    team_color: Yup.string().required("Team Colour is required."),
    // .min(3, "Team Colour minimum length is 3")
    // .max(15, "Team Colour should be at most 15 characters.")
    // .matches(/^[aA-z0-9Z-\s]+$/, `Team Colour should be alphanumeric`),
    slots: Yup.string()
      .trim()
      .required("Slots is required.")
      .max(5, "Slots should be at most 5 characters."),
    event_id: Yup.string().trim().required("Event is required."),
    team_valid_from: Yup.string().required("From Date required."),
    team_valid_to: Yup.string().required("To Date required."),
    manager_name: Yup.string()
      .trim()
      .required("Manager name is required.")
      .min(3, "Manager name minimum length is 3")
      .max(50, "Manager name should be at most 50 characters.")
      .matches(/^[aA-z0-9Z-\s]+$/, `Manager name should be alphanumeric`),
    mobile: Yup.string()
      .trim()
      .required("Mobile is required.")
      .min(9, "Mobile Length must be 9 to 15 Digits")
      .max(15, "Mobile Length must be 9 to 15 Digits"),
    link_valid_from: Yup.string().required("From Date required."),
    link_valid_to: Yup.string().required("To Date required."),
  });
};

TeamSchema.validateTeamEdit = () => {
  return Yup.object({
    team_name: Yup.string()
      .trim()
      .required("Team name is required.")
      .min(3, "Team name minimum length is 3")
      .max(30, "Team name should be at most 30 characters."),
      // .matches(/^[aA-z0-9Z-\s]+$/, `Team name should be alphanumeric`),
    team_color: Yup.string().required("Team Colour is required."),
    // .min(3, "Team Colour minimum length is 3")
    // .max(15, "Team Colour should be at most 15 characters.")
    // .matches(/^[aA-z0-9Z-\s]+$/, `Team Colour should be alphanumeric`),
    slots: Yup.string()
      .trim()
      .required("Slots is required.")
      .max(5, "Slots should be at most 5 characters."),
    event_id: Yup.string().trim().required("Event is required."),
    team_valid_from: Yup.string().required("From Date required."),
    team_valid_to: Yup.string().required("To Date required."),
    manager_name: Yup.string()
      .trim()
      .required("Manager name is required.")
      .min(3, "Manager name minimum length is 3")
      .max(50, "Manager name should be at most 50 characters.")
      .matches(/^[aA-z0-9Z-\s]+$/, `Manager name should be alphanumeric`),
    mobile: Yup.string()
      .trim()
      .required("Mobile is required.")
      .min(9, "Mobile Length must be 9 to 15 Digits")
      .max(15, "Mobile Length must be 9 to 15 Digits"),
    link_valid_from: Yup.string().required("From Date required."),
    link_valid_to: Yup.string().required("To Date required."),
  });
};

export default TeamSchema;
