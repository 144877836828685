import { useEffect, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { apiRemoveGuest } from "../../api/master";
import { toast } from "react-hot-toast";
import { Button } from "react-bootstrap";

function GuestCancelForm(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [initialValues1, setInitialValues] = useState({
    guest_id: props?.guest_id || "",
    is_active: props?.is_active || "",
  });
  useEffect(function () {}, [error]);
  const cancelled = () => {
    props.setIsOpen(false);
    props.setFormData("");
    props.setIsGuestList();
  };
  return (
    <Formik
      initialValues={initialValues1}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        const formData = new FormData();
        formData.append("guest_id", values.guest_id);
        formData.append("is_active", 0);
        apiRemoveGuest(formData)
          .then((data) => {
            setSuccess(data.data.message);
            setError("");
            props.setFormData("");
            props.setIsGuestList();
          })
          .catch((err) => {
            setError(err.response.data.data.message);
            setSuccess("");
          })
          .finally((result) => {
            setIsSubmit(true);
          });
      }}
    >
      {({ isSubmitting, handleChange, values, setFieldValue, errors }) => (
        <>
          <FormikForm>
            <div className="row  gy-2 mb-3">
              <div className="col-md-12">
                Are you sure you want to remove the guest?
              </div>
            </div>

            {error && (
              <div className={`alert alert-danger py-2 small`} role="alert">
                {error || ""}
              </div>
            )}
            <div className="col-12 mt-4">
              {(error || success) && (
                <div
                  className={`alert alert-${
                    error ? "danger" : "success"
                  } py-2 small`}
                  role="alert"
                >
                  {error || success || ""}
                </div>
              )}
              <div style={{ float: "right" }}>
                <Button
                  type="button"
                  variant="secondary rounded"
                  onClick={() => cancelled()}
                >
                  Cancel
                </Button>
                {props.modalType !== "isView" && (
                  <Button
                    type="submit px-4"
                    className="mx-2"
                    variant="danger me-2 rounded"
                    disabled={isSubmit}
                  >
                    Sure
                  </Button>
                )}
              </div>
            </div>
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

export default GuestCancelForm;
