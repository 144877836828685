import { useEffect, useRef, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import eventAreaSchema from "../../validations/eventarea";
import "react-datepicker/dist/react-datepicker.css";
import { FormControl } from "react-bootstrap";
import Select from "react-select";
import { apiEventAreaAdd, apiEventAreaEdit } from "../../api/master";

function EventAreaForm(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [shownew, setShownew] = useState(false);
  const [allEventList, setAllEventList] = useState([]);
  const [activeEventList, setActiveEventList] = useState([]);
  const [error, setError] = useState("");
  const [readonly, setReadonly] = useState(
    props.modalType === "isView" || false
  );
  const [initialValues1, setInitialValues] = useState({
    event_area: props?.event_area || "",
    event_area_id: props?.event_area_id || "",
    event_id: props?.event_id || "",
    is_active: props?.is_active || "",
  });

  useEffect(() => {
    setAllEventList(props.allEventList);
    setActiveEventList(props.activeEventList);
  }, [props.allEventList, props.activeEventList]);

  useEffect(function () {}, [error]);

  return (
    <Formik
      initialValues={initialValues1}
      validationSchema={eventAreaSchema.validateEventAreaAdd()}
      validateOnChange={false}
      validateOnBlur={false}
      onReset={(values) => {
        setShownew(false);
        setSuccess("");
        props.setFormData({
          event_area: "",
          event_area_id: "",
          event_id: "",
          is_active: "",
        });
      }}
      onSubmit={(values) => {
        setIsSubmit(true);
        const formData = new FormData();
        formData.append("event_area", values.event_area.trim());
        formData.append("event_id", values.event_id);
        props.modalType === "isEdit" &&
          formData.append("event_area_id", values.event_area_id);

        props.modalType === "isEdit"
          ? apiEventAreaEdit(formData)
              .then((data) => {
                setSuccess(data.data.message);
                setError("");
                props.setFormData("");
                props.getEventAreaList();
              })
              .catch((err) => {
                setError(err.response.data.data.message);
                setSuccess("");
              })
              .finally((result) => {
                setIsSubmit(false);
              })
          : apiEventAreaAdd(formData)
              .then((data) => {
                setSuccess(data.data.message);
                setError("");
                setShownew(true);
                props.setFormData("");
                props.getEventAreaList();
              })
              .catch((err) => {
                setError(err.response.data.data.message);
              })
              .finally((result) => {
                setIsSubmit(false);
              });
      }}
    >
      {({
        isSubmitting,
        handleChange,
        values,
        touched,
        setFieldValue,
        errors,
      }) => (
        <>
          <FormikForm>
            <div className="row  gy-2 mb-3">
              <div className="col-md-12">
                <label htmlFor="event_area">
                  Event Area Name{" "}
                  {!readonly && <span className="text-danger">*</span>}
                </label>
                <FormControl
                  type="text"
                  autoComplete="off"
                  maxLength={30}
                  onChange={(event) => {
                    const re = /^[A-Za-z0-9 ]*$/;
                    if (
                      event.target.value === "" ||
                      re.test(event.target.value)
                    ) {
                      setFieldValue("event_area", event.target.value);
                    }
                  }}
                  value={values.event_area}
                  name="event_area"
                  id="event_area"
                  readOnly={readonly}
                />
                {errors?.event_area ? (
                  <div className="text-danger small">{errors?.event_area} </div>
                ) : null}
              </div>
              <div className="col-md-12">
                <label htmlFor="event_id">
                  {props.modalType === "isAdd" && "Select"} Event{" "}
                  {readonly || props.modalType === "isEdit" || (
                    <span className="text-danger">*</span>
                  )}
                </label>
                <Select
                  isDisabled={
                    readonly || props.modalType === "isEdit" ? true : false
                  }
                  options={
                    props.modalType === "isAdd" ? activeEventList : allEventList
                  }
                  value={
                    (props.modalType === "isAdd"
                      ? activeEventList.find(
                          (el) => el?.value === values.event_id
                        )
                      : allEventList.find(
                          (el) => el?.value === values.event_id
                        )) || ""
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "0px",
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      marginTop: "0px",
                      marginBottom: "0px",
                      paddingLeft: "0px",
                    }),
                  }}
                  classNamePrefix="react-select"
                  tabSelectsValue
                  onChange={(selectedOption) => {
                    setFieldValue("event_id", selectedOption.value);
                  }}
                  name="event_id"
                  id="event_id"
                />
                {errors?.event_id && touched.event_id ? (
                  <div className="text-danger small">{errors?.event_id} </div>
                ) : null}
              </div>
            </div>
            <div className="col-12 mt-4">
              {(error || success) && (
                <div
                  className={`alert alert-${
                    error ? "danger" : "success"
                  } py-2 small`}
                  role="alert"
                >
                  {error || success || ""}
                </div>
              )}
              <div style={{ float: "right" }}>
                <button
                  className="branchModalCancel"
                  onClick={() => {
                    props.setFormData({
                      event_area: "",
                      event_area_id: "",
                      event_id: "",
                      is_active: "",
                    });
                    props.setIsOpen(false);
                  }}
                  type="button"
                >
                  Cancel
                </button>
                {props.modalType !== "isView" &&
                  (shownew ? (
                    <button id="createNew" type="reset">
                      Add New
                    </button>
                  ) : (
                    <button type="submit" disabled={isSubmit}>
                      {isSubmit && (
                        <span className="spinner-border spinner-border-sm me-2"></span>
                      )}
                      Save
                    </button>
                  ))}
              </div>
            </div>
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

export default EventAreaForm;
