import { inputChangeFocus, inputOnlyNumber } from "../../../utils";
import { useEffect, useState } from "react";
import $ from "jquery";
import config from "../../../config";
import { Link, useLocation } from "react-router-dom";

function ValidateOtp({ loginFormik, otpFormik, error, changeState }) {
  const [time, setTime] = useState(30);
  const [isResend, setIsResend] = useState(false);
  const [resendError, setResendError] = useState("");
  // const location = useLocation();
  // const team = location.state?.team || {};

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime((prev) => prev - 1);
      }, 1000);
    }
  }, [time]);

  const resendOtp = async () => {
    await $.post(
      `${config.apiUrl}/login`,
      JSON.stringify({
        user_mob: loginFormik.values.mobile,
        reqType: 1,
        envinfo: "Web",
        versioninfo: "1.0",
        otherinfo: "{}",
      })
    )
      .done(function (res) {
        setTime(() => 30);
        setIsResend(true);
        setTimeout(() => {
          setIsResend(false);
        }, 8000);
        setResendError("");
      })
      .catch(function (err) {
        setResendError(err.responseJSON.data.message);
      })
      .finally((result) => {});
  };

  return (
    <>
      <form onSubmit={otpFormik.handleSubmit} autoComplete="off">
        <div className="card py-4 px-3">
          <div className="card-body">
            {/* <Link to="/forgot-pin" className="text-primary text-decoration-none small">&#x2190; Back to Login</Link> */}
            <p className="mb-2">
              <Link
                // to="/forgot-pin"
                onClick={changeState}
                className="text-primary text-decoration-none small">
                &#x2190; Back to Reset Mobile
              </Link>
            </p>
            <h1 className="mb-2">Verification Code</h1>
            <p className="text-grey">An authentication code has been sent</p>
            <strong>Mobile No: {loginFormik.values.mobile}</strong>

            <div className="my-5 d-flex justify-content-center">
              <input
                type="text"
                className="form-control otp"
                autoFocus
                maxLength="1"
                name="otp[0]"
                id="otp0"
                onKeyDown={inputOnlyNumber}
                onKeyUp={(e) => inputChangeFocus(e, "otp1", "otp0")}
                onChange={otpFormik.handleChange}
                onBlur={otpFormik.handleBlur}
              />
              <input
                type="text"
                className="form-control otp"
                maxLength="1"
                name="otp[1]"
                id="otp1"
                onChange={otpFormik.handleChange}
                onKeyDown={inputOnlyNumber}
                onKeyUp={(e) => inputChangeFocus(e, "otp2", "otp0")}
                onBlur={otpFormik.handleBlur}
              />
              <input
                type="text"
                className="form-control otp"
                maxLength="1"
                name="otp[2]"
                id="otp2"
                onChange={otpFormik.handleChange}
                onKeyDown={inputOnlyNumber}
                onKeyUp={(e) => inputChangeFocus(e, "otp3", "otp1")}
                onBlur={otpFormik.handleBlur}
              />
              <input
                type="text"
                className="form-control otp"
                maxLength="1"
                name="otp[3]"
                id="otp3"
                onChange={otpFormik.handleChange}
                onKeyDown={inputOnlyNumber}
                onKeyUp={(e) => inputChangeFocus(e, "otp3", "otp2")}
                onBlur={otpFormik.handleBlur}
              />
            </div>

            {otpFormik.touched.otp && otpFormik.errors.otp ? (
              <div className="alert alert-danger text-center small py-1">
                {otpFormik.errors.otp}
              </div>
            ) : null}

            {resendError ? (
              <div className="alert alert-danger text-center small py-1">
                {resendError}
              </div>
            ) : null}

            {error ? (
              <div className="alert alert-danger text-center small py-1">
                {error}
              </div>
            ) : null}

            {isResend ? (
              <div className="alert alert-success text-center small py-1">
                OTP has been sent
              </div>
            ) : null}

            <div className="d-grid mb-3">
              <button
                style={{ background: "#FE2F7B", borderRadius: "8px" }}
                className="btn btn-primary"
                type="submit"
                disabled={otpFormik.isSubmitting}>
                {otpFormik.isSubmitting && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}
                Validate OTP
              </button>
            </div>
            <p className="text-center text-grey">
              {time > 0 && (
                <>
                  {" "}
                  You can Resend an OTP in {time} seconds. <br />{" "}
                </>
              )}
              {time < 1 && (
                <>
                  Didn’t receive a code?{" "}
                  <strong
                    className="text-primary"
                    role="button"
                    onClick={resendOtp}>
                    Resend Code
                  </strong>
                </>
              )}
            </p>
          </div>
        </div>
      </form>
    </>
  );
}

export default ValidateOtp;
