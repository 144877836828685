const config = {
    // apiUrl: "http://192.168.0.104:3000/api/v1",
    apiUrl: "https://api.goinvite.pro/api/v1",
    baseUrl: "",
    links: {
        playstore: "#",
    },
    countryCodes: ["+91", "+92"],
    userTypes: [
        {value: 1, label: "Admin"},
        {value: 2, label: "User"},
    ],
    buyerTypes: [
        {value: 1, label: "B2B"},
        {value: 2, label: "B2C"},
        {value: 3, label: "B2G"},
    ],
    buyerCategory: [
        {value: 3, label: "Both"},
        {value: 1, label: "Buyer"},
        {value: 2, label: "Seller"},
    ],
    expenseCategories: [
        {value: 1, label: "Expense"},
        {value: 2, label: "Income"},
    ],
    dropdown1: [
        {value: 1, label: "drop1"},
        {value: 2, label: "down1"},
    ],
    dropdown2: [
        {value: 1, label: "drop2"},
        {value: 2, label: "down2"},
    ],
    dropdown3: [
        {value: 1, label: "drop3"},
        {value: 2, label: "down3"},
    ],
    stockInType: [
        {value: 101, label: "Import"},
        {value: 102, label: "Local"},
    ],
    stockType: [
        {value: 1, label: "Consumption"},
        {value: 2, label: "Production"},
    ],
    creditNoteReasons: [
        {value: 101, label: "Amount Issue"},
        {value: 102, label: "Other Reason"},
    ],
    paymentMode: [
        {value: 1, label: "Cash"},
        {value: 2, label: "Bank"},
        {value: 3, label: "Credit"},
        {value: 4, label: "Mobile Pay"},
    ],
    roleListIng: [
        {id: 1, title: "Super Admin", role_id: 1},
        {id: 2, title: "Admin", role_id: 2},
        {id: 3, title: "Manager", role_id: 3},
        {id: 4, title: "Security", role_id: 4},
        {id: 5, title: "Co-participant", role_id: 5},
    ],
    reqFields: [
        {
            value: 1,
            label: "Name",
            defaultValue: true,
            isDisabled: true,
            isFixed: true,
        },
        {value: 2, label: "Mobile"},
        {value: 3, label: "Photo"},
        {value: 4, label: "Aadhar"},
        {value: 5, label: "Pan"},
    ],
};

export default config;