import {Link} from "react-router-dom";
import notification from "../../images/icons/notification.png"
import userPic from "../../images/icons/user_pic.png"
import search from "../../images/icons/search.png"
import {useEffect, useState} from "react";

function Header() {
    const [user, setUser] = useState({});

    useEffect(function () {
        if (localStorage.getItem("go-ticket")) {
            setUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
        }
    }, []);

    return (<>
        {/* <div className="d-flex justify-content-between mb-4" id="header">
            <div>
                <p>Hi {user?.user_name || "Guest"},</p>
                <h1>Welcome to Account app!</h1>
            </div>
            <div className="d-flex align-items-center gap-2">
                <div className="input-group" style={{width: "19rem"}}>
                    <input type="search" className="form-control border-0 rounded-pill rounded-end px-3"
                           placeholder="Search..."/>
                    <button className="btn border-0 shadow-none bg-white" type="button">
                        <img src={search} className="img-fluid" alt=""/>
                    </button>
                </div>
                <img src={notification} alt=""/>
                <div className="d-flex align-items-center gap-1 dropdown-toggle" data-bs-toggle="dropdown"
                     data-bs-auto-close="true" role="button">
                    <img src={userPic} alt=""/>
                    <h6 className="mb-0">{user?.user_name || "Guest"}</h6>
                </div>
                <ul className="dropdown-menu dropdown-menu-end mt-2 py-1 border-0">
                    <li><Link to="/logout" className="dropdown-item bg-transparent">Logout</Link></li>
                </ul>
            </div>
        </div> */}
    </>);
}

export default Header;
