import React, { useState } from "react";
import axios from "axios";
import { RiFileExcel2Fill } from "react-icons/ri";
import Tippy from "@tippyjs/react";

const DownloadExcel = (props) => {
  const fileLink = props.link;
  const data = props.data;
  const fileName = props.filename;
  const [loading, setLoading] = useState(false);

  let loginToken;
  loginToken = JSON.parse(localStorage.getItem("go-ticket"))?.token ?? "";
  const headers = {
    headers: {
      Authorization: `Bearer ${loginToken}`,
    },
    responseType: "arraybuffer",
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      const payload = { ...data, download: 1 };
      let response = await axios.post(fileLink, payload, headers);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName + ".xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tippy content="Download Excel">
      <button
        type="button"
        onClick={handleDownload}
        style={{
          background: "none",
          cursor: "pointer",
          border: "none",
          fontSize: "20px",
        }}
      >
        {loading ? (
          <span className="spinner-border spinner-border-sm me-2"></span>
        ) : (
          <RiFileExcel2Fill />
        )}
      </button>
    </Tippy>
  );
};

export default DownloadExcel;
