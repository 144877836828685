import { useEffect, useState } from "react";
import { Form as FormikForm, Formik } from "formik";
import eventsSchema from "../../validations/events";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Form, FormControl } from "react-bootstrap";
import { TagsInput } from "../Common/Utiles";
import Select from "react-select";
import moment from "moment/moment";
import { apiEventAdd, apiEventEdit } from "../../api/master";
import UploadIcon from "../../images/upload.svg";
import { CloseIcon } from "../Common/Icons";
import AddQR from "./AddQR";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { getFileObjecFromUrl } from "../../utils";
import { Link } from "react-router-dom";
import { BoxArrowUpRight } from "react-bootstrap-icons";

function EventForm(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA05QbMXzAL_4ouvn7kWzM0o5Fj74kLp6A",
  });
  const [geocoder, setGeocoder] = useState(null);
  const [center, setCenter] = useState(
    typeof props?.latLng == "string"
      ? JSON.parse(props?.latLng)
      : {
        lat: 19.076,
        lng: 72.8777,
      }
  );
  const [currentMarker, setCurrentMarker] = useState(center);
  const handleLocation = (address = "Mumbai") => {
    if (!geocoder) {
      setGeocoder(() => new window.google.maps.Geocoder());
    }
    (geocoder || new window.google.maps.Geocoder())
      .geocode({ address: address })
      .then(({ results }) => {
        if (results && results[0]) {
          setCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          setCurrentMarker({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
        }
      })
      .catch(() => { });
  };
  const handleMarkerChange = (e, setFieldValue) => {
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    if (!geocoder) {
      setGeocoder(() => new window.google.maps.Geocoder());
    }
    (geocoder || new window.google.maps.Geocoder())
      .geocode({ location: { lat, lng } })
      .then(({ results }) => {
        if (results && results[0]) {
          console.log(results);
          setFieldValue("address", results[0].formatted_address);
        }
      })
      .catch(() => { });
  };

  // const navigate = useNavigate();
  const [waData, setWaData] = useState({
    xAxis: 0,
    yAxis: 0,
    logoW: 127,
    logoH: 127,
  });
  const [emailData, setEmailData] = useState({
    xAxis: 0,
    yAxis: 0,
    logoW: 127,
    logoH: 127,
  });
  const [emailImage, setEmailImage] = useState();
  const [waImage, setWaImage] = useState();
  const [emailImageLink, setEmailImageLink] = useState();
  const [waImageLink, setWaImageLink] = useState();
  const [resetTag, setResetTag] = useState(false);
  const [addQrWA, setAddQrWA] = useState(false);
  const [addQrEmail, setAddQrEmail] = useState(false);
  const [shownew, setShownew] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [stateValue, setStateValue] = useState([]);
  const [city, setCity] = useState([]);
  const [readonly, setReadonly] = useState(
    props.modalType === "isView" || false
  );
  const [preview, setPreview] = useState({
    whatsapp_pics: props.whatsapp_pics !== "" ? props.whatsapp_pics : "",
    email_pics: props.email_pics !== "" ? props.email_pics : "",
    event_banner: props.event_banner !== "" ? props.event_banner : "",
  });
  const [uploadedFile, setUploadedFile] = useState();
  const [imageError, setImageError] = useState([]);
  const [initialValues1, setInitialValues] = useState({
    event_id: props?.event_id || "",
    name: props?.name || "",
    organiser: props?.organiser || "",
    youTube_link: props?.youTube_link || "",
    st_dt: (props?.st_dt && new Date(props?.st_dt)) || "",
    ed_dt: (props?.ed_dt && new Date(props?.ed_dt)) || "",
    reg_st_dt: (props?.reg_st_dt && new Date(props?.reg_st_dt)) || "",
    reg_ed_dt: (props?.reg_ed_dt && new Date(props?.reg_ed_dt)) || "",
    is_paid: (props?.is_paid === 1 && true) || false,
    is_publish_portal: (props?.is_publish_portal === 1 && true) || false,
    amount: props?.amount || "",
    is_self_reg: (props?.is_self_reg === 1 && true) || false,
    tags: (props?.tags && props?.tags?.toString()?.split(",")) || [],
    event_tag_line: props?.event_tag_line || "",
    desc: props?.desc || "",
    address: props?.address || "",
    email_subject: props?.email_subject || "",
    pincode: props?.pincode || "",
    cat_id: props?.cat_id || "",
    state_id: props?.state_id || "",
    city_id: props?.city_id || "",
    is_active: props?.is_active || "",
    whatsapp_pics: props?.whatsapp_pics || "",
    event_banner: props?.event_banner || "",
    email_pics: props?.email_pics || "",
  });

  const today = new Date().setDate(new Date().getDate());
  const tomorrow = new Date().setDate(new Date().getDate() + 1);

  const handleClose = () => {
    setAddQrWA(false);
    // setFormData("");
  };

  useEffect(() => {
    setStateValue(props.stateValue);
    setCity(props.cityValue);

    if (props.modalType == "isEdit" && props?.whatsapp_pics) {
      getFileObjecFromUrl(props?.whatsapp_pics || "").then((image) =>
        setWaImage(image)
      );
    }

    if (props.modalType == "isEdit" && props?.email_pics) {
      getFileObjecFromUrl(props?.email_pics || "").then((image) =>
        setEmailImage(image)
      );
    }
  }, []);

  useEffect(function () { }, [error]);

  const [isSubmit, setIsSubmit] = useState(false)
  return (
    <Formik
      initialValues={initialValues1}
      validationSchema={eventsSchema.validateEventsAdd()}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        let whatsapp_pic = uploadedFile?.whatsapp_pics
          ? uploadedFile?.whatsapp_pics
          : values.whatsapp_pics !== ""
            ? values.whatsapp_pics
            : "";
        let event_banner = uploadedFile?.event_banner
          ? uploadedFile?.event_banner
          : values.event_banner !== ""
            ? values.event_banner
            : "";
        let email_pic = uploadedFile?.email_pics
          ? uploadedFile?.email_pics
          : values.email_pics !== ""
            ? values.email_pics
            : "";
        const formData = new FormData();
        formData.append("name", values.name.trim());
        formData.append("organiser", values.organiser.trim());
        formData.append("youTube_link", values.youTube_link.trim());
        formData.append(
          "st_dt",
          moment(values.st_dt).format("YYYY-MM-DD H:mm")
        );
        formData.append(
          "ed_dt",
          moment(values.ed_dt).format("YYYY-MM-DD H:mm")
        );
        values?.is_self_reg &&
          formData.append(
            "reg_st_dt",
            moment(values.reg_st_dt).format("YYYY-MM-DD H:mm")
          );
        values?.is_self_reg &&
          formData.append(
            "reg_ed_dt",
            moment(values.reg_ed_dt).format("YYYY-MM-DD H:mm")
          );
        formData.append("is_paid", values.is_paid === true ? 1 : 0);
        formData.append(
          "is_publish_portal",
          values.is_publish_portal === true ? 1 : 0
        );
        formData.append("is_self_reg", values.is_self_reg === true ? 1 : 0);
        formData.append(
          "amount",
          values.is_paid === true ? String(values.amount).trim() : ""
        );

        formData.append("event_tag_line", values.event_tag_line.trim());
        formData.append("desc", values.desc.trim());
        formData.append("email_subject", values.email_subject.trim());
        formData.append("address", values.address.trim());
        formData.append("city_id", values.city_id);
        formData.append("state_id", values.state_id);
        formData.append("pincode", values.pincode);
        formData.append("tags", values?.tags?.toString());
        formData.append("whatsapp_pics", whatsapp_pic);
        formData.append("event_banner", event_banner);
        formData.append("email_pics", email_pic);
        formData.append("whatsapp_data", JSON.stringify(waData));
        formData.append("email_data", JSON.stringify(emailData));
        formData.append("latLng", JSON.stringify(currentMarker));
        props.modalType === "isEdit" &&
          formData.append("event_id", values.event_id);
        setIsSubmit(true)
        props.modalType === "isEdit"
          ? apiEventEdit(formData)
            .then(() => {
              props.setFormData("");
              setIsSubmit(false)
              setError("");
              setSuccess("success");
              props.getEventList();
            })
            .catch((data) => {
              if (data.response.data.status === 400) {
                setError(data.response.data.data.message);
              }
              setIsSubmit(false)
            })
            .finally((result) => {
              setTimeout(() => {
                setSuccess("");
                setError("");
                setIsSubmit(false)
              }, 5000);
            })
          : apiEventAdd(formData)
            .then(() => {
              setError("");
              setSuccess("success");
              props.setFormData("");
              props.getEventList();
              setShownew(true);
              setIsSubmit(false)
            })
            .catch((data) => {
              if (data.response.data.status === 400) {
                setError(data.response.data.data.message);
              }
              setIsSubmit(false)
            })
            .finally((result) => {
              setTimeout(() => {
                setSuccess("");
                setError("");
              }, 5000);
              setIsSubmit(false)
            });
      }}
      onReset={(values) => {
        setShownew(false);
        setSuccess("");
        setCenter({ lat: 19.076, lng: 72.8777 });
        setCurrentMarker({ lat: 19.076, lng: 72.8777 });
        setResetTag(true);
        setWaImage(null);
        setEmailImage(null);
        setIsSubmit(false)
        props.setFormData({
          event_id: "",
          name: "",
          organiser: "",
          youTube_link: "",
          st_dt: "",
          ed_dt: "",
          reg_st_dt: "",
          reg_ed_dt: "",
          is_paid: "",
          is_publish_portal: "",
          amount: "",
          is_self_reg: "",
          tags: "",
          desc: "",
          event_tag_line: "",
          email_subject: "",
          address: "",
          pincode: "",
          cat_id: "",
          state_id: "",
          city_id: "",
          is_active: "",
          whatsapp_pics: "",
          event_banner: "",
          email_pics: "",
        });
      }}
    >
      {({ isSubmitting, handleChange, values, setFieldValue, errors }) => (
        <>
          <FormikForm>
            {props.modalType == "isView" && values.is_publish_portal && (
              <div className="text-end mb-2">
                <Link
                  target="_blank"
                  to={`/event-details/${values?.event_id}`}
                  className="text-decoration-none d-flex justify-content-end gap-1"
                >
                  <span>Preview my event</span>{" "}
                  <BoxArrowUpRight style={{ marginTop: "1.5px" }} />
                </Link>
              </div>
            )}
            <div className="row  gy-2 mb-3">
              <div className="col-md-6">
                <label htmlFor="name">
                  Event Name{" "}
                  {!readonly && <span className="text-danger">*</span>}
                </label>
                <FormControl
                  type="text"
                  autoComplete="off"
                  maxLength={100}
                  onChange={(event) => {
                    const re = /^[A-Za-z0-9@#,\-&:()\[\]\s\\]*$/;

                    if (
                      event.target.value === "" ||
                      re.test(event.target.value)
                    ) {
                      setFieldValue("name", event.target.value);
                    }
                  }}
                  value={values.name}
                  name="name"
                  id="name"
                  readOnly={readonly}
                />
                {errors?.name ? (
                  <div className="text-danger small">{errors?.name} </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <label htmlFor="organiser">
                  Event Organiser{" "}
                  {!readonly && <span className="text-danger">*</span>}
                </label>
                <FormControl
                  type="text"
                  autoComplete="off"
                  maxLength={100}
                  onChange={(event) => {
                    const re = /^[A-Za-z0-9@#,\-&:()\[\]\s\\]*$/;

                    if (
                      event.target.value === "" ||
                      re.test(event.target.value)
                    ) {
                      setFieldValue("organiser", event.target.value);
                    }
                  }}
                  value={values.organiser}
                  name="organiser"
                  id="organiser"
                  readOnly={readonly}
                />
                {errors?.organiser ? (
                  <div className="text-danger small">{errors?.organiser} </div>
                ) : null}
              </div>
              <div className="col-md-12">
                <label htmlFor="event_tag_line">
                  Event Tag Line{" "}
                  {/* {!readonly && <span className="text-danger">*</span>} */}
                </label>
                <textarea
                  maxLength={200}
                  className="form-control"
                  onChange={handleChange}
                  value={values.event_tag_line}
                  readOnly={readonly}
                  name="event_tag_line"
                  id="event_tag_line"
                ></textarea>
                {errors?.event_tag_line ? (
                  <div className="text-danger small">
                    {errors?.event_tag_line}{" "}
                  </div>
                ) : null}
              </div>
              <div className="col-md-12">
                <label htmlFor="desc">Event Description</label>
                <textarea
                  maxLength={500}
                  className="form-control"
                  onChange={handleChange}
                  value={values.desc}
                  readOnly={readonly}
                  name="desc"
                  id="desc"
                ></textarea>
              </div>
              <div className="col-md-6">
                <label htmlFor="st_dt">
                  Event Start Date & Time{" "}
                  {!readonly && <span className="text-danger">*</span>}
                </label>
                <DatePicker
                  id="st_dt"
                  autoComplete="off"
                  placeholderText="DD-MM-YYYY HH:MM"
                  // dateFormat={"DD-MM-YYYY HH:MM"}
                  dateFormat="dd-MM-yyyy h:mma"
                  showTimeSelect
                  className="form-control datePicker"
                  selected={values.st_dt}
                  minDate={today}
                  maxDate={values.ed_dt && values.ed_dt}
                  readOnly={readonly}
                  name="st_dt"
                  onChange={(date) => setFieldValue("st_dt", date)}
                />
                {errors?.st_dt ? (
                  <div className="text-danger small">{errors?.st_dt} </div>
                ) : null}
              </div>
              <div className="col-md-6">
                <label htmlFor="ed_dt">
                  Event End Date & Time{" "}
                  {!readonly && <span className="text-danger">*</span>}
                </label>
                <DatePicker
                  id="ed_dt"
                  disabled={values.st_dt === ""}
                  autoComplete="off"
                  className="form-control datePicker"
                  readOnly={readonly}
                  placeholderText="DD-MM-YYYY HH:MM"
                  // dateFormat={"DD-MM-YYYY HH:MM"}
                  dateFormat="dd-MM-yyyy h:mma"
                  showTimeSelect
                  minDate={values.st_dt ? values.st_dt : today}
                  selected={values.ed_dt}
                  name="ed_dt"
                  onChange={(date) => setFieldValue("ed_dt", date)}
                />
                {errors?.ed_dt ? (
                  <div className="text-danger small">{errors?.ed_dt} </div>
                ) : null}
              </div>
              <div className="col-md-12">
                <Form.Check
                  id="is_self_reg"
                  disabled={readonly}
                  name="is_self_reg"
                  checked={Boolean(values?.is_self_reg)}
                  onChange={handleChange}
                  label="Self Registration"
                  type={"checkbox"}
                />
              </div>
              {values?.is_self_reg && (
                <div className="col-md-6">
                  <label htmlFor="reg_st_dt">
                    Registration Start Date & Time{" "}
                    {!readonly && <span className="text-danger">*</span>}
                  </label>
                  <DatePicker
                    readOnly={readonly}
                    id="reg_st_dt"
                    disabled={values.st_dt === "" || values.ed_dt === ""}
                    // minDate={values.st_dt ? values.st_dt : today}
                    minDate={today}
                    autoComplete="off"
                    maxDate={values.ed_dt && values.ed_dt}
                    className="form-control datePicker"
                    selected={values.reg_st_dt}
                    placeholderText="DD-MM-YYYY HH:MM"
                    // dateFormat={"DD-MM-YYYY HH:MM"}
                    dateFormat="dd-MM-yyyy h:mma"
                    showTimeSelect
                    name="reg_st_dt"
                    onChange={(date) => setFieldValue("reg_st_dt", date)}
                  />
                  {errors?.reg_st_dt ? (
                    <div className="text-danger small">
                      {errors?.reg_st_dt}{" "}
                    </div>
                  ) : null}
                </div>
              )}
              {values?.is_self_reg && (
                <div className="col-md-6">
                  <label htmlFor="reg_ed_dt">
                    Registration End Date & Time{" "}
                    {!readonly && <span className="text-danger">*</span>}
                  </label>
                  <DatePicker
                    readOnly={readonly}
                    id="reg_ed_dt"
                    autoComplete="off"
                    className="form-control datePicker"
                    disabled={
                      values.st_dt === "" ||
                      values.ed_dt === "" ||
                      values.reg_st_dt === ""
                    }
                    // minDate={values.st_dt ? values.st_dt : today}
                    minDate={today}
                    maxDate={values.ed_dt && values.ed_dt}
                    selected={values.reg_ed_dt}
                    placeholderText="DD-MM-YYYY HH:MM"
                    // dateFormat={"DD-MM-YYYY HH:MM"}
                    dateFormat="dd-MM-yyyy h:mma"
                    showTimeSelect
                    name="reg_ed_dt"
                    value={
                      values.reg_st_dt > values.reg_ed_dt
                        ? ""
                        : values.reg_ed_dt
                    }
                    onChange={(date) => setFieldValue("reg_ed_dt", date)}
                  />
                  {errors?.reg_ed_dt ? (
                    <div className="text-danger small">
                      {errors?.reg_ed_dt}{" "}
                    </div>
                  ) : null}
                </div>
              )}
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <Form.Check
                      disabled={readonly}
                      id="is_paid"
                      name="is_paid"
                      checked={Boolean(values?.is_paid)}
                      onChange={handleChange}
                      label="Paid Event"
                      type={"checkbox"}
                    />
                  </div>
                  {values.is_paid === true && (
                    <div className="col-md-6">
                      <label htmlFor="amount">
                        Amount{" "}
                        {!readonly && <span className="text-danger">*</span>}
                      </label>
                      <FormControl
                        type="text"
                        readOnly={readonly}
                        onChange={(number) => {
                          const re = /^[\d]{1,15}([\.\,]\d{0,4})?$/;

                          if (
                            number.target.value === "" ||
                            re.test(number.target.value)
                          ) {
                            setFieldValue("amount", number.target.value);
                          }
                        }}
                        value={values.amount}
                        name="amount"
                        id="amount"
                        autoComplete="off"
                      />
                      {errors?.amount ? (
                        <div className="text-danger small">
                          {errors?.amount}{" "}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <Form.Check
                  id="is_publish_portal"
                  name="is_publish_portal"
                  disabled={readonly}
                  checked={Boolean(values?.is_publish_portal)}
                  onChange={handleChange}
                  label="Publish on Portal"
                  type={"checkbox"}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="youTube_link">
                  Event YouTube link{" "}
                  {/* {!readonly && <span className="text-danger">*</span>} */}
                </label>
                <FormControl
                  type="text"
                  autoComplete="off"
                  maxLength={500}
                  onChange={(event) => {
                    setFieldValue("youTube_link", event.target.value);
                    // const re = /^[A-Za-z0-9@#,\-&:./_$?/()\[\]\s\\]*$/;

                    // if (
                    //   event.target.value === "" ||
                    //   re.test(event.target.value)
                    // ) {
                    //   setFieldValue("youTube_link", event.target.value);
                    // }
                  }}
                  value={values.youTube_link}
                  name="youTube_link"
                  id="youTube_link"
                  readOnly={readonly}
                />

                {errors?.youTube_link ? (
                  <div className="text-danger small">
                    {errors?.youTube_link}{" "}
                  </div>
                ) : null}
              </div>
              {(!readonly || values.tags !== "") && (
                <div className="col-md-12">
                  <label htmlFor="tags">Tags</label>
                  <TagsInput
                    readonly={readonly}
                    id="tags"
                    name="tags"
                    resetTag={resetTag}
                    setResetTag={setResetTag}
                    data={values.tags}
                    placeholder="max 5 tags"
                    setFieldValue={setFieldValue}
                  />
                </div>
              )}
              <div className="col-md-4">
                {readonly && preview?.event_banner === null ? (
                  ""
                ) : (
                  <label htmlFor="event_banner">
                    {readonly ? "" : "Select"} Event Banner BG File{" "}
                    {!readonly && <span className="text-danger">*</span>}
                  </label>
                )}
                <div className="uploadBox mt-3" style={{ marginLeft: "36px" }}>
                  {values.event_banner === "" ? (
                    !readonly && (
                      <label className="" htmlFor="event_banner">
                        <div className="uploadThumbnial">
                          <img src={UploadIcon} />
                        </div>
                      </label>
                    )
                  ) : (
                    <>
                      {" "}
                      {!readonly && (
                        <span
                          className="removeImage"
                          role="button"
                          onClick={() => {
                            setFieldValue("event_banner", "");
                            setPreview({ ...preview, event_banner: "" });
                          }}
                        >
                          <CloseIcon />
                        </span>
                      )}
                      <label className="" htmlFor="event_banner">
                        <div className="previewBox">
                          <img src={preview?.event_banner} />
                        </div>
                      </label>
                    </>
                  )}
                  <FormControl
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      if (event?.target?.files[0]?.type.includes("image/")) {
                        const selectedFile = event.target.files[0];
                        setFieldValue("event_banner", selectedFile);
                        setUploadedFile({
                          ...uploadedFile,
                          event_banner: selectedFile,
                        });
                        if (selectedFile) {
                          const reader = new FileReader();
                          reader.onload = () => {
                            setPreview({
                              ...preview,
                              event_banner: reader.result,
                            });
                          };
                          reader.readAsDataURL(selectedFile);
                        }
                        setImageError({
                          ...imageError,
                          event_banner: "",
                        });
                      } else {
                        setImageError({
                          ...imageError,
                          event_banner: "Please upload Image Only",
                        });
                      }
                    }}
                    autoComplete="off"
                    disabled={readonly}
                    className="form-control"
                    id="event_banner"
                    name="event_banner"
                  />
                </div>
                {props.modalType !== "isView" && (
                  <>
                    <p style={{ width: "285px", fontSize: "12px" }}>
                      (970px <span style={{ fontWeight: "900", fontSize: "12px" }}>x</span> 250px Recommended)
                    </p>
                  </>
                )}
                {/* {imageError && imageError.event_banner && (
                  <div className="text-danger small">
                    {imageError.event_banner}{" "}
                  </div>
                )} */}
                {errors?.event_banner ? (
                  <div className="text-danger small">
                    {errors?.event_banner}{" "}
                  </div>
                ) : null}
              </div>

              <div className="col-md-4">
                {readonly && preview?.whatsapp_pics === null ? (
                  ""
                ) : (
                  <label htmlFor="whatsapp_pics">
                    {readonly ? "" : "Select"} Whatsapp BG File
                    {/* {!readonly && <span className="text-danger">*</span>} */}
                  </label>
                )}
                <div className="uploadBox mt-3" style={{ marginLeft: "36px" }}>
                  {values.whatsapp_pics === "" ? (
                    !readonly && (
                      <label className="" htmlFor="whatsapp_pics">
                        <div className="uploadThumbnial">
                          <img src={UploadIcon} />
                        </div>
                      </label>
                    )
                  ) : (
                    <>
                      {" "}
                      {!readonly && (
                        <span
                          className="removeImage"
                          role="button"
                          onClick={() => {
                            setFieldValue("whatsapp_pics", "");
                            setPreview({ ...preview, whatsapp_pics: "" });
                            setWaImage(null);
                          }}
                        >
                          <CloseIcon />
                        </span>
                      )}
                      <label className="" htmlFor="whatsapp_pics">
                        <div className="previewBox">
                          <img src={preview?.whatsapp_pics} />
                          {props.modalType === "isEdit" &&
                            setWaImageLink(preview?.whatsapp_pics)}
                        </div>
                      </label>
                    </>
                  )}
                  <FormControl
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      if (event?.target?.files[0]?.type.includes("image/")) {
                        const selectedFile = event.target.files[0];
                        setFieldValue("whatsapp_pics", selectedFile);
                        setWaImage(selectedFile);
                        setUploadedFile({
                          ...uploadedFile,
                          whatsapp_pics: selectedFile,
                        });
                        if (selectedFile) {
                          const reader = new FileReader();
                          reader.onload = () => {
                            setPreview({
                              ...preview,
                              whatsapp_pics: reader.result,
                            });
                          };
                          reader.readAsDataURL(selectedFile);
                        }
                        setImageError({
                          ...imageError,
                          whatsapp_pics: "",
                        });
                        // navigate("setQR", { state: { file: selectedFile } });
                      } else {
                        setImageError({
                          ...imageError,
                          whatsapp_pics: "Please upload Image Only",
                        });
                      }
                    }}
                    autoComplete="off"
                    disabled={readonly}
                    className="form-control"
                    id="whatsapp_pics"
                    name="whatsapp_pics"
                  />
                </div>
                {props.modalType !== "isView" && (
                  <>
                    <p style={{ width: "285px", fontSize: "12px" }}>
                      (1080px <span style={{ fontWeight: "900", fontSize: "12px" }}>x</span> 1920px Recommended)
                    </p>
                    <button
                      style={{ width: "90px", marginLeft: "45px" }}
                      type="button"
                      className="btn btn-primary py-1 px-2 mt-2"
                      onClick={() => {
                        if (waImage) {
                          setAddQrWA(true);
                          setError(null);
                        } else {
                          setError("Please select Image first");
                          setTimeout(() => setError(""), 5000);
                        }
                      }}
                    >
                      Set QR
                    </button>
                  </>
                )}
                {/* {imageError && imageError.whatsapp_pics && (
                  <div className="text-danger small">
                    {imageError.whatsapp_pics}{" "}
                  </div>
                )} */}
                {errors?.whatsapp_pics ? (
                  <div className="text-danger small">
                    {errors?.whatsapp_pics}{" "}
                  </div>
                ) : null}
              </div>

              <div className="col-md-4">
                {readonly && preview?.email_pics === null ? (
                  ""
                ) : (
                  <label htmlFor="email_pics">
                    {readonly ? "" : "Select"} Email BG File
                    {/* {!readonly && <span className="text-danger">*</span>} */}
                  </label>
                )}

                <div className="uploadBox mt-3" style={{ marginLeft: "36px" }}>
                  {values.email_pics === "" ? (
                    !readonly && (
                      <label className="" htmlFor="email_pics">
                        <div className="uploadThumbnial">
                          <img src={UploadIcon} />
                        </div>
                      </label>
                    )
                  ) : (
                    <>
                      {!readonly && (
                        <span
                          className="removeImage"
                          role="button"
                          onClick={() => {
                            setFieldValue("email_pics", "");
                            setPreview({ ...preview, email_pics: "" });
                            setEmailImage(null);
                          }}
                        >
                          <CloseIcon />
                        </span>
                      )}
                      {preview?.email_pics !== null && (
                        <label className="" htmlFor="email_pics">
                          <div className="previewBox">
                            <img src={preview?.email_pics} />
                            {props.modalType === "isEdit" &&
                              setEmailImageLink(preview?.email_pics)}
                          </div>
                        </label>
                      )}
                    </>
                  )}

                  <FormControl
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      if (event?.target?.files[0]?.type.includes("image/")) {
                        const selectedFile = event.target.files[0];
                        setFieldValue("email_pics", selectedFile);
                        setEmailImage(selectedFile);
                        setUploadedFile({
                          ...uploadedFile,
                          email_pics: selectedFile,
                        });
                        if (selectedFile) {
                          const reader = new FileReader();
                          reader.onload = () => {
                            setPreview({
                              ...preview,
                              email_pics: reader.result,
                            });
                          };
                          reader.readAsDataURL(selectedFile);
                        }
                        setImageError({
                          ...imageError,
                          email_pics: "",
                        });
                      } else {
                        setImageError({
                          ...imageError,
                          email_pics: "Please upload Image Only",
                        });
                      }
                    }}
                    disabled={readonly}
                    autoComplete="off"
                    className="form-control"
                    id="email_pics"
                    name="email_pics"
                  />
                </div>
                {props.modalType !== "isView" && (
                  <>
                    <p style={{ width: "285px", fontSize: "12px" }}>
                      (1920px <span style={{ fontWeight: "900", fontSize: "12px" }}>x</span> 1080px Recommended)
                    </p>
                    <button
                      style={{ width: "90px", marginLeft: "45px" }}
                      type="button"
                      className="btn btn-primary py-1 px-2 mt-2"
                      onClick={() => {
                        if (emailImage) {
                          setAddQrEmail(true);
                          setError(null);
                        } else {
                          setError("Please select Image first");
                          setTimeout(() => setError(""), 5000);
                        }
                      }}
                    >
                      Set QR
                    </button>

                  </>
                )}
                {/* {imageError && imageError.email_pics && (
                  <div className="text-danger small">
                    {imageError.email_pics}{" "}
                  </div>
                )} */}
                {errors?.email_pics ? (
                  <div className="text-danger small">{errors?.email_pics} </div>
                ) : null}
                <p className="text-danger" style={{color:"red",fontSize:"12px", lineHeight:"16px"}}>If email background is not uploaded then you'll not able to send email Invitation to guest</p>
              </div>

              {addQrWA && (
                <div>
                  <AddQR
                    file={waImage}
                    addQrWA={addQrWA}
                    setAddQrWA={setAddQrWA}
                    waData={waData}
                    setWaData={setWaData}
                    waImageLink={waImageLink}
                  />
                </div>
              )}
              {addQrEmail && (
                <div>
                  <AddQR
                    file={emailImage}
                    addQrEmail={addQrEmail}
                    setAddQrEmail={setAddQrEmail}
                    emailData={emailData}
                    setEmailData={setEmailData}
                    emailImageLink={emailImageLink}
                  />
                </div>
              )}

              <div className="col-md-12">
                <label htmlFor="email_subject">Email Subject</label>
                <textarea
                  onChange={handleChange}
                  maxLength={200}
                  readOnly={readonly}
                  className="form-control"
                  autoComplete="off"
                  value={values.email_subject}
                  id="email_subject"
                  name="email_subject"
                />
                {errors?.email_subject ? (
                  <div className="text-danger small">
                    {errors?.email_subject}{" "}
                  </div>
                ) : null}
              </div>

              <div className="col-md-12">
                <label htmlFor="address">
                  Address
                </label>
                <textarea
                  onChange={handleChange}
                  maxLength={200}
                  readOnly={readonly}
                  className="form-control"
                  autoComplete="off"
                  value={values.address}
                  id="address"
                  name="address"
                  onBlur={(e) => handleLocation(e.target.value)}
                />
                {errors?.address ? (
                  <div className="text-danger small">{errors?.address} </div>
                ) : null}
              </div>
              <div className="col-md-4">
                <label htmlFor="state_id">
                  State
                </label>
                <Select
                  isDisabled={readonly}
                  options={stateValue}
                  value={
                    stateValue.find((el) => el?.value === values.state_id) ||
                    " "
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "0px",
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "0px",
                    }),
                  }}
                  classNamePrefix="react-select"
                  tabSelectsValue
                  onChange={(selectedOption) => {
                    setFieldValue("state_id", selectedOption.value);
                    // handleLocation(selectedOption?.label || "Maharashtra");
                  }}
                  name="state_id"
                  id="state_id"
                />
                {errors?.state_id ? (
                  <div className="text-danger small">{errors?.state_id} </div>
                ) : null}
              </div>
              <div className="col-md-4">
                <label htmlFor="city_id">
                  City
                </label>
                <Select
                  isDisabled={readonly || values.state_id === ""}
                  options={city.filter((e) => e.state_id === values.state_id)}
                  value={city.find((el) => el?.value === values.city_id) || " "}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "0px",
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "0px",
                    }),
                  }}
                  classNamePrefix="react-select"
                  tabSelectsValue
                  onChange={(selectedOption) => {
                    setFieldValue("city_id", selectedOption.value);
                    // handleLocation(selectedOption?.label || "Mumbai");
                  }}
                  name="city_id"
                  id="city_id"
                />
                {errors?.city_id ? (
                  <div className="text-danger small">{errors?.city_id} </div>
                ) : null}
              </div>

              <div className="col-md-4">
                <label htmlFor="pincode">
                  Pincode
                </label>
                <FormControl
                  readOnly={readonly}
                  autoComplete="off"
                  maxLength={6}
                  value={values.pincode}
                  onChange={(number) => {
                    const re = /^[\d\.\,]{0,6}?$/;
                    if (
                      number.target.value === "" ||
                      re.test(number.target.value)
                    ) {
                      setFieldValue("pincode", number.target.value);
                      // handleLocation(number.target.value || 400008);
                    }
                  }}
                  name="pincode"
                  id="pincode"
                />
                {errors?.pincode ? (
                  <div className="text-danger small">{errors?.pincode} </div>
                ) : null}
              </div>

              <div className="col-md-12">
                {isLoaded && (
                  <GoogleMap
                    zoom={13}
                    center={center}
                    mapContainerStyle={{ width: "100%", height: 250 }}
                  >
                    <Marker
                      position={currentMarker}
                      onDragEnd={(e) => handleMarkerChange(e, setFieldValue)}
                      draggable={props.modalType !== "isView"}
                      onDrag={(e) =>
                        setCurrentMarker({
                          lat: e.latLng.lat(),
                          lng: e.latLng.lng(),
                        })
                      }
                    ></Marker>
                  </GoogleMap>
                )}
              </div>
            </div>
            {props?.is_active
              ? null
              : props.modalType === "isView" && (
                <div className="col-md-6">
                  <label>Reason</label>

                  <textarea
                    onChange={handleChange}
                    disabled
                    className="form-control"
                    autoComplete="off"
                    value={props.reason}
                    id="reason"
                    name="reason"
                  />
                </div>
              )}

            {(error || success) && (
              <div
                className={`alert alert-${error ? "danger" : "success"
                  } py-2 small`}
                role="alert"
              >
                {error || success || ""}
              </div>
            )}

            <div style={{ float: "right" }}>
              <button
                className="branchModalCancel"
                onClick={() => {
                  props.setFormData({
                    event_id: "",
                    name: "",
                    organiser: "",
                    st_dt: "",
                    ed_dt: "",
                    reg_st_dt: "",
                    reg_ed_dt: "",
                    is_paid: "",
                    is_publish_portal: "",
                    amount: "",
                    youTube_link: "",
                    is_self_reg: "",
                    tags: "",
                    desc: "",
                    event_tag_line: "",
                    email_subject: "",
                    address: "",
                    pincode: "",
                    cat_id: "",
                    state_id: "",
                    city_id: "",
                    is_active: "",
                    whatsapp_pics: "",
                    event_banner: "",
                    email_pics: "",
                    whatsapp_data: "",
                    email_data: "",
                  });
                  props.setIsOpen(false);
                }}
                type="button"
              >
                Cancel
              </button>

              {shownew ? (
                <button id="createNew" type="reset">
                  Add New
                </button>
              ) : (
                props.modalType !== "isView" && (
                  <button type="submit" disabled={isSubmit}>
                    {isSubmit && (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    )}
                    Submit</button>
                )
              )}
            </div>
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

export default EventForm;
