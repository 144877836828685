import Sidebar from "../../../components/partials/Sidebar";
import Header from "../../../components/partials/Header";
import Breadcrumb from "../../../components/Breadcrumb";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import "./style.css";
import filter from "../../../images/icons/filter.png";
import editIcon from "../../../images/icons/edit.png";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Modal from "react-bootstrap/Modal";
import viewIcon from "../../../images/view.png";
import CompanyViews from "./view";
import CompanyAdd from "./Add";
import CompanyEdit from "./Edit";
import { apiCancelCompany, apiListCompany } from "../../../api/master";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function CompanyList() {
  const initialState = {
    comp_name: "",
    is_active: "",
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showId, setShowId] = useState(0);
  const [editId, setEditId] = useState("");
  const [formInputs, setFormInputs] = useState(initialState);
  const [filtersVisible, setFiltersVisible] = useState(false);

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  async function changeStatus(company_id, status) {
    let target = window.event.target;
    let oldText = target.innerText;
    target.innerText = "Updating...";
    await apiCancelCompany({ comp_id: company_id, is_active: status })
      .then((data) => {
        setError("");
      })
      .catch((err) => {
        setError(err.response.data.data.message);
        setTimeout(function () {
          setError("");
        }, 3000);
      })
      .finally(() => {
        target.innerText = oldText;
        getList(filtersVisible);
      });
  }

  async function getList(isFilter = true, pageNo = null) {
    let json = { paginate: 1, page_no: (pageNo || currentPage) - 1 };
    if (isFilter) {
      json = { ...json, ...formInputs };
    }
    await apiListCompany(json)
      .then((data) => {
        setCurrentPage(pageNo || currentPage);
        setIsSubmit(false);
        setList(data.data.data.list);
        setIsLoading(false);
        setTotalPage(data.data.data.count);
      })
      .catch(function (err) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.message,
          confirmButtonText: "OK",
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          focusConfirm: true,
        });
      });
  }

  useEffect(
    function () {
      getList(filtersVisible);
    },
    [currentPage]
  );

  function handleInputs(e) {
    setFormInputs({ ...formInputs, [e.target.id]: e.target.value });
  }

  function handleFilterVisibility() {
    if (filtersVisible) {
      setFiltersVisible(false);
      setFormInputs(initialState);
    } else {
      setFiltersVisible(true);
    }
  }

  return (
    <>
      <div className="d-flex min-vh-100" style={{ background: "#DAE5FF" }}>
        <Sidebar />
        <div className="content m-1 ms-1 p-4">
          <Header />
          <main className="bg-white rounded-4" id="buyer">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col">
                  <Breadcrumb path={["Company"]} />
                </div>
                <div className="col text-end">
                  <Tippy content="Filter">
                    <img
                      onClick={() => handleFilterVisibility()}
                      src={filter}
                      className="img-fluid me-3"
                      alt=""
                      role="button"
                      style={{ width: "2.5rem" }}
                    />
                  </Tippy>

                  <Link
                    onClick={(v) => {
                      handleShow();
                      setShowId(1);
                    }}
                    className="btn btn-primary px-4 py-2">
                    Add Company
                  </Link>
                </div>
              </div>

              {filtersVisible && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setIsSubmit(true);
                    currentPage > 1 ? setCurrentPage(1) : getList(true, 1);
                  }}
                  autoComplete="off">
                  <div className="row filter">
                    <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="comp_name">Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="comp_name"
                          value={formInputs.comp_name}
                          onChange={handleInputs}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="status">Status</label>
                        <select
                          className="form-select"
                          id="is_active"
                          name="is_active"
                          value={formInputs.is_active}
                          onChange={(e) =>
                            setFormInputs({
                              ...formInputs,
                              is_active: e.target.value,
                            })
                          }>
                          {[
                            { id: "", name: "All" },
                            { id: 1, name: "Active" },
                            {
                              id: 0,
                              name: "In-Active",
                            },
                          ].map((v, i) => (
                            <option key={i} value={v.id}>
                              {v.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12 ">
                      <label className="d-none d-xl-block"> &nbsp;</label>
                      <div className="mb-3">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={isSubmit}>
                          {isSubmit && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                          )}
                          Search
                        </button>
                        <button
                          className="btn btn-primary rounded-3 px-3 ms-2"
                          type="button"
                          onClick={(e) => {
                            setFormInputs(initialState);
                            currentPage > 1
                              ? setCurrentPage(1)
                              : getList(false, 1);
                          }}
                          style={{ background: "#4263eb", fontSize: "14px" }}>
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              {error && (
                <div className="row mt-3">
                  <div className="col">
                    <div
                      className={`alert alert-danger py-1 small`}
                      role="alert">
                      {error || ""}
                    </div>
                  </div>
                </div>
              )}

              <div className="row mb-4">
                <div className="col">
                  <table className={`table ${error ? "my-1" : "mt-4"} rounded`}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Company Name</th>
                        {/* <th>Company Address</th> */}
                        {/* <th>State</th>
                         */}
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(isLoading || list.length == 0) && (
                        <tr>
                          <td colSpan="4" className="text-center">
                            {isLoading ? "Loading..." : "No Records"}
                          </td>
                        </tr>
                      )}
                      {list.map((v, i) => (
                        <tr key={`bl_` + i}>
                          <td>{currentPage * 10 - 10 + i + 1}</td>
                          <td>
                            {v?.comp_name?.length > 20 ? (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip_` + i}>
                                    {v?.comp_name}
                                  </Tooltip>
                                }>
                                <span>
                                  {v?.comp_name?.length > 20
                                    ? v?.comp_name?.substring(0, 20) + "..."
                                    : v?.comp_name}{" "}
                                </span>
                              </OverlayTrigger>
                            ) : (
                              v?.comp_name
                            )}
                          </td>
                          {/* <td>{v.comp_logo}</td> */}

                          <td>
                            <Tippy content="Change Status">
                              {v.is_active ? (
                                <span
                                  className="badge text-bg-success"
                                  role="button"
                                  onClick={() => changeStatus(v.comp_id, 0)}>
                                  Active
                                </span>
                              ) : (
                                <span
                                  className="badge text-bg-danger"
                                  role="button"
                                  onClick={() => changeStatus(v.comp_id, 1)}>
                                  In-Active
                                </span>
                              )}
                            </Tippy>
                          </td>
                          <td>
                            <Tippy content="View" trigger="mouseenter">
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setEditId(v);
                                  handleShow();
                                  setShowId(3);
                                }}>
                                <img
                                  src={viewIcon}
                                  className="img-fluid me-2"
                                  alt=""
                                />
                              </a>
                            </Tippy>

                            <Tippy content="Edit" trigger="mouseenter">
                              <Link
                                onClick={() => {
                                  setEditId(v);
                                  handleShow();
                                  setShowId(2);
                                }}
                                className={`link-info ${
                                  v.is_active ? "" : "d-none"
                                }`}>
                                <img
                                  src={editIcon}
                                  className="img-fluid me-2"
                                  alt=""
                                />
                              </Link>
                            </Tippy>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <Pagination
                    nPages={Math.ceil(totalPage / 10)}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
              <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                backdrop="static">
                {showId === 1 ? (
                  <CompanyAdd id={editId} list={getList} hide={handleClose} />
                ) : showId === 2 ? (
                  <CompanyEdit id={editId} list={getList} hide={handleClose} />
                ) : showId === 3 ? (
                  <CompanyViews id={editId} list={getList} hide={handleClose} />
                ) : null}
              </Modal>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default CompanyList;
