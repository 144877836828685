import "./App.css";
import config from "./config";
import { BrowserRouter, json, Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Dashboard from "./pages/Dashboard/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Logout from "../src/pages/Auth/Logout";
import Login from "../src/pages/Auth/Login";
import ForgetPin from "../src/pages/Auth/ForgetPin";
import CompanyList from "./pages/Master/Company";
import UserList from "./pages/Master/User";
import { createContext, useEffect, useState } from "react";
import SettingList from "./pages/Setting/List";
import EventsList from "./pages/Events";
import TeamList from "./pages/Team";
import Guestlist from "./pages/Guests";
import EventAreaList from "./pages/EventArea";
import GuestTypelist from "./pages/GuestType/List";
import IteamList from "./pages/Team/IteamList";
import TeamDetails from "./pages/TeamDetail";
import ReportList from "./pages/Reports/reportList";
import $ from "jquery";
import EventDetails from "./pages/EventDetails";
import Contact from "./pages/Homepage/Contact";
import About from "./pages/Homepage/About";

export const ReportContext = createContext(null);

function App() {
  const [compConfig, setCompConfig] = useState([]);
  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    function retryReportList(attempt = 3) {
      $.post(
        `${config.apiUrl}/reports/listReportHeader`,
        JSON.stringify({ paginate: 0 })
      )
        .done(function (res) {
          setReportList(() => res.data.list);
        })
        .catch(function (err) {
          setReportList(() => []);
          if (attempt--) {
            setTimeout(function () {
              retryReportList(attempt);
            }, 3000);
          }
        });
    }

    if (localStorage.getItem("go-ticket")) {
      retryReportList();
    }
  }, []);

  const [user, setUser] = useState();
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("go-ticket"))?.userData);
  }, []);

  return (
    <ReportContext.Provider value={reportList}>
      <BrowserRouter basename={config.baseUrl}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<Contact />} />
          
          <Route exact path="/about" element={<About />} />
          <Route
            exact
            path="/login"
            element={
              localStorage.getItem("go-ticket") ? (
                user?.role_id != 1 ? (
                  <Navigate to="/user" replace />
                ) : (
                  <Navigate to="/dashboard" replace />
                )
              ) : (
                <Login />
              )
            }
          />
          <Route
            exact
            path="/forgot-pin"
            element={
              localStorage.getItem("go-ticket") ? (
                user?.role_id != 1 ? (
                  <Navigate to="/user" replace />
                ) : (
                  <Navigate to="/dashboard" replace />
                )
              ) : (
                <ForgetPin />
              )
            }
          />
          <Route exact path="/event-details/:id" element={<EventDetails />} />

          <Route
            exact
            path="/team-detail/:linktoken"
            element={<TeamDetails />}
          />

          <Route element={<ProtectedRoute />}>
            {user?.role_id != 1 && (
              <Route exact path="/dashboard" element={<Dashboard />} />
            )}

            <Route exact path="/guests" element={<Guestlist />} />
            <Route exact path="/guestType" element={<GuestTypelist />} />
            <Route exact path="/events" element={<EventsList />} />
            <Route exact path="/team" element={<TeamList />} />
            <Route exact path="/logout" element={<Logout />} />

            <Route exact path="/company" element={<CompanyList />} />

            <Route exact path="/team/review/:id" element={<IteamList />} />

            <Route exact path="/user" element={<UserList />} />
            <Route exact path="/event-area" element={<EventAreaList />} />
            <Route exact path="/guestType" element={<GuestTypelist />} />
            <Route
              exact
              path="/setting"
              element={<SettingList setCompConfig={setCompConfig} />}
            />

            <Route exact path="/reports/:reportId" element={<ReportList />} />
          </Route>

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </ReportContext.Provider>
  );
}

export default App;
