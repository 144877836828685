import { useEffect, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import { FormControl } from "react-bootstrap";
// import "./style.css";
import Select from "react-select";
import { apiEditGuestType, apiCreateGuestType } from "../../api/master";
import { toast } from "react-hot-toast";
import guestTypeSchema from "../../validations/guestType";
// import AsyncSelect from "react-select/async";

function GuestTypeForm(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [shownew, setShownew] = useState(false);
  const [allEventList, setAllEventList] = useState([]);
  const [activeEventList, setActiveEventList] = useState([]);
  const [error, setError] = useState("");
  const [readonly, setReadonly] = useState(
    props.modalType === "isView" || false
  );
  console.log("props", props);

  const [initialValues1, setInitialValues] = useState({
    event_id: props?.event_id || "",
    guest_type: props?.guest_type || "",
    guest_type_id: props?.guest_type_id || "",

    is_active: props?.is_active || "",
    qty: props?.qty || "",
    status: props?.status || "",
  });

  const status = [
    { label: "On Sale", value: 101 },
    { label: "Hidden", value: 102 },
    { label: "Access code required", value: 103 },
    { label: "Display as Sold Out", value: 104 },
    { label: "Display as Unavailable", value: 105 },
    { label: "Only visible to admin", value: 106 },
  ];
  useEffect(() => {
    setAllEventList(props.allEventList);
    setActiveEventList(props.activeEventList);
  }, [props.allEventList, props.activeEventList]);

  useEffect(function () { }, [error]);

  return (
    <Formik
      initialValues={initialValues1}
      validationSchema={guestTypeSchema.validateGuestTypeAdd()}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        setIsSubmit(true);
        const reqData = new FormData();
        reqData.append("guest_type", values.guest_type);
        reqData.append("event_id", values.event_id);
        reqData.append("qty", values.qty);
        reqData.append("status", values.status);

        props.modalType === "isEdit" &&
          reqData.append("guest_type_id", values.guest_type_id);

        props.modalType === "isEdit"
          ? apiEditGuestType(reqData)
            .then(() => {
              setError("");
              setSuccess("success");
              props.setFormData("");
              props.getGuestTypeList();
            })
            .catch((data) => {
              if (data.response.data.status === 400) {
                setError(data.response.data.data.message);
                props.setFormData("");
                props.getGuestTypeList();
              }
            })
            .finally((result) => {
              setTimeout(() => {
                setSuccess("");
                setError("");
              }, 5000);
              setIsSubmit(false);
            })
          : apiCreateGuestType(reqData)
            .then(() => {
              setError("");
              setSuccess("success");
              props.setFormData("");
              props.getGuestTypeList();
              setShownew(true);
            })
            .catch((data) => {
              if (data.response.data.status === 400) {
                setError(data.response.data.data.message);
                setSuccess("");
              }
            })
            .finally((result) => {
              setTimeout(() => {
                setSuccess("");
                setError("");
              }, 5000);
              setIsSubmit(false);
            });
      }}
      onReset={(values) => {
        setShownew(false);
        setSuccess("");
        props.setFormData({
          event_id: "",
          guest_type: "",
          guest_type_id: "",
          qty: "",
          status: "",
          is_active: "",
        });
      }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <>
          <FormikForm>
            <div className="row  gy-2 mb-3">
              <div className="col-md-12">
                <label htmlFor="name">
                  Guest Type Name{" "}
                  {readonly || <span className="text-danger">*</span>}
                </label>
                <FormControl
                  type="text"
                  autoComplete="off"
                  maxLength={30}
                  onChange={(event) => {
                    const re = /^[A-Za-z0-9 ]*$/;

                    if (
                      event.target.value === "" ||
                      re.test(event.target.value)
                    ) {
                      setFieldValue("guest_type", event.target.value);
                    }
                  }}
                  value={values.guest_type}
                  name="guest_type"
                  id="guest_type"
                  readOnly={readonly}
                />
                {errors?.guest_type && touched?.guest_type ? (
                  <div className="text-danger small">{errors?.guest_type} </div>
                ) : null}
              </div>

              <div className="col-md-12">
                <label htmlFor="event_id">
                  {props.modalType === "isAdd" && "Select"} Event{" "}
                  {readonly || props.modalType === "isEdit" || (
                    <span className="text-danger">*</span>
                  )}
                </label>
                <Select
                  isDisabled={
                    readonly || props.modalType === "isEdit" ? true : false
                  }
                  options={
                    props.modalType === "isAdd" ? activeEventList : allEventList
                  }
                  value={
                    (props.modalType === "isAdd"
                      ? activeEventList.find(
                        (el) => el?.value === values.event_id
                      )
                      : allEventList.find(
                        (el) => el?.value === values.event_id
                      )) || ""
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "0px",
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "0px",
                    }),
                  }}
                  classNamePrefix="react-select"
                  tabSelectsValue
                  onChange={(selectedOption) => {
                    setFieldValue("event_id", selectedOption.value);
                  }}
                  name="event_id"
                  id="event_id"
                />
                {errors?.event_id && touched.event_id ? (
                  <div className="text-danger small">{errors?.event_id} </div>
                ) : null}
              </div>

              <div className="col-md-12">
                <label htmlFor="qty">
                  Quantity {readonly || <span className="text-danger">*</span>}
                </label>
                <FormControl
                  type="text"
                  autoComplete="off"
                  maxLength={30}
                  onChange={(event) => {
                    setFieldValue("qty", event.target.value);
                  }}
                  value={values?.qty}
                  name="qty"
                  id="qty"
                  readOnly={readonly}
                  onKeyDown={(event) => {
                    if (!/\d/.test(event.key) && event.key !== "Backspace" && event.key !== "Tab") {
                      event.preventDefault();
                    }
                  }}
                />
                {errors?.qty && touched?.qty ? (
                  <div className="text-danger small">{errors?.qty} </div>
                ) : null}
              </div>

              <div className="col-md-12">
                <label htmlFor="event_id">
                  Status {readonly || <span className="text-danger">*</span>}
                </label>
                <Select
                  isDisabled={readonly}
                  options={status}
                  value={status.find((el) => el.value === values.status) || ""}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "0px",
                    }),
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "0px",
                    }),
                  }}
                  classNamePrefix="react-select"
                  tabSelectsValue
                  onChange={(selectedOption) => {
                    setFieldValue("status", selectedOption.value);
                  }}
                  name="status"
                  id="status"
                />
                {errors?.status && touched.status ? (
                  <div className="text-danger small">{errors?.status} </div>
                ) : null}
              </div>
            </div>
            {/* {error && (
              <div className={`alert alert-danger py-2 small`} role="alert">
                {error || ""}
              </div>
            )}

            {props.modalType !== "isView" && (
              <button type="submit">Submit</button>
            )} */}
            {(error || success) && (
              <div
                className={`alert alert-${error ? "danger" : "success"
                  } py-2 small`}
                role="alert"
              >
                {error || success || ""}
              </div>
            )}
            <div style={{ float: "right" }}>
              <button
                className="branchModalCancel"
                onClick={() => {
                  props.setFormData({
                    event_id: "",
                    guest_type: "",
                    guest_type_id: "",

                    is_active: "",
                  });
                  props.setIsOpen(false);
                }}
                type="button"
              >
                Cancel
              </button>

              {props.modalType !== "isView" &&
                (shownew ? (
                  <button id="createNew" type="reset">
                    Add New
                  </button>
                ) : (
                  <button type="submit" disabled={isSubmit}>
                    {isSubmit && (
                      <span className="spinner-border spinner-border-sm me-2"></span>
                    )}
                    Save
                  </button>
                ))}
            </div>
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

export default GuestTypeForm;
