import * as Yup from "yup";

const guestTypeSchema = {};

guestTypeSchema.validateGuestTypeAdd = () => {
  return Yup.object({
    guest_type: Yup.string()
      .trim()
      .required("Guest Type name is required.")
      .min(2, "Guest Type name minimum length is 2")
      .max(30, "Guest Type name should be at most 30 characters.")
      .matches(/^[A-Za-z0-9 ]*$/, `Guest name should be Alphanumeric `),

    event_id: Yup.string().required("Event name is required"),
    qty: Yup.string().trim().required("Quantity is required."),
    status: Yup.string().required("Status is required"),
  });
};

// guestTypeSchema.validateguestTypeEdit = () => {
//   return Yup.object({
//     guest_type: Yup.string()
//     .trim()
//     .required('Guest Type name is required.')
//     .min(2, 'Guest Type name minimum length is 2')
//     .max(100, 'Guest Type name should be at most 10 characters.')
//     .matches(/^[A-Za-z0-9\[\]#()&:@ -]*$/, `Guest Type name should be Alphanumeric or [,],#,(,),&,:,@,- only`),

//   event_id:  Yup.string()
//     .required("Event name is required")
//   })
// }
guestTypeSchema.validateGuestTypeCencel = () => {
  return Yup.object({
    reason: Yup.string()
      .trim()
      .required("Reason is required.")
      .min(5, "Reason should be at atleast 5 characters")
      .max(250, "Company name should be at most 250 characters."),
  });
};

export default guestTypeSchema;
