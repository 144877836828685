import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Navbar, Container, NavDropdown, Button, Nav } from "react-bootstrap";

import goTicketLogo from "../../images/goTicketLogo.png";
import logo from "../../images/icons/LOGO goInvitepro.svg";
// import namelogo from "../../images/icons/namelogo.png";
import namelogo from "../../images/icons/logoName.png";
import userPic from "../../images/icons/user_pic.png";
import "./style.css";

import Modal from "react-bootstrap/Modal";
import CommonModal from "../Common/Modal";
import EventForm from "../Events/EventsForm";

import { apiEventList, apiEventDelete } from "../../api/master";
import { apiCity, apiState } from "../../api/stateAndCity";
import config from "../../config";

// function Navbar() {
//     const links = [
//         {label: "Home", href: "/"},
//         {label: "Pricing", href: "/pricing"},
//         {label: "About Us", href: "/about-us"},
//         {label: "Blog", href: "/blog"},
//         {label: "Careers", href: "/careers"},
//         {label: "FAQ", href: "/faq"},
//         {label: "Login", href: "/login", class: "btn btn-primary btn-primary-custom ms-sm-3 ms-0 w-100"},
//     ];

//     return (
//         <>
//             <nav className="navbar navbar-expand-md bg-white" id="navbar">
//                 <div className="container">
//                     <h1 class="navbar-brand">Go Ticket</h1>
//                     <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
//                             data-bs-target="#navbarContent">
//                         <span className="navbar-toggler-icon"></span>
//                     </button>
//                     <div className="collapse navbar-collapse" id="navbarContent">
//                         <ul className="navbar-nav ms-auto">
//                             {links.map((link, i) => {
//                                 return <li key={"nav_" + i} className="nav-item px-1">
//                                     <NavLink to={link.href} className={({isActive}) => {
//                                         return (link?.class || 'nav-link text-center') + (isActive ? " active text-primary" : "");
//                                     }}>{link.label}</NavLink>
//                                 </li>
//                             })}
//                         </ul>
//                     </div>
//                 </div>
//             </nav>
//         </>
//     );
// }
function NavBar({ user }) {
  //   const links = [
  //     { label: "Home", href: "/" },
  //     { label: "Pricing", href: "/pricing" },
  //     { label: "About Us", href: "/about-us" },
  //     { label: "Blog", href: "/blog" },
  //     { label: "Careers", href: "/careers" },
  //     { label: "FAQ", href: "/faq" },
  //     {
  //       label: "Login",
  //       href: "/login",
  //       class: "btn btn-primary btn-primary-custom ms-sm-3 ms-0 w-100",
  //     },
  //   ];

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [userRole, setUserRole] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [eventList, getEventList] = useState(false);
  const [formData, setFormData] = useState("");
  const [stateValue, setStateValue] = useState([]);
  const [city, setCity] = useState([]);
  const [list, setList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (localStorage.getItem("go-ticket")) {
      setUserRole(
        JSON.parse(localStorage.getItem("go-ticket")).userData.role_id
      );
    }
  }, [currentPage]);

  const handleCreateEvent = () => {
    if (user) {
      if (userRole == 1) {
        navigate("/user");
      } else {
        navigate("/dashboard");
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Navbar
        variant="light"
        bg="bg-white"
        expand="lg"
        className="px-5 fw-semibold"
      >
        <Container fluid className="d-flex justify-content-between">
          <Navbar.Brand href="/" className="d-flex gap-2 align-items-center">
            <img
              src={logo}
              alt="goTicket Logo"
              style={{ width: "28px", height: "28px" }}
            />{" "}
            <img
              src={namelogo}
              alt="goTicket Logo"
              style={{ width: "130px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse
            id="navbarScroll"
            className="d-flex justify-content-end"
          >
            <Nav
              className=" my-2 my-lg-0 align-items-center gap-4 d-flex justify-content-end"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <NavDropdown
                title={<span className="text-theme-gray">About Us</span>}
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item as={NavLink} to="/about">
                  About
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/contact">
                  Contact Us
                </NavDropdown.Item>
              </NavDropdown>
              {user ? (
                <button
                  className="border border-0 rounded-3 bg-theme-pink text-white py-2 px-3"
                  onClick={handleCreateEvent}
                >
                  Go to Dashboard
                </button>
              ) : null}

              {user ? (
                <div className="d-flex align-items-center flex-column flex-lg-row gap-lg-5 gap-xl-2">
                  <div className="d-flex align-items-center">
                    <img src={userPic} className="img-fluid me-2" alt="" />
                    <h6 className="mb-0">{user?.user_name}</h6>
                  </div>
                  <button
                    onClick={() => setShow(!show)}
                    // className="border border-0 bg-transparent p-0 fw-semibold text-theme-pink"
                    className="border border-0 rounded-3 bg-theme-pink text-white py-2 px-3"
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <button
                  className="border border-0 rounded-3 bg-theme-pink text-white py-2 px-3"
                  style={{ width: "6em" }}
                  onClick={() => navigate("/login")}
                >
                  Sign In
                </button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <CommonModal
        isOpen={isOpen}
        title="Event"
        modalType={"isAdd"}
        setIsOpen={setIsOpen}
        setFormData={setFormData}
      >
        <EventForm
          getEventList={getEventList}
          {...formData}
          modalType={"isAdd"}
          setIsOpen={setIsOpen}
          setFormData={setFormData}
          stateValue={stateValue}
          cityValue={city}
        />
      </CommonModal>
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h4
            style={{ marginLeft: "5rem", height: "4px", textAlign: "center" }}
            id="modalhead"
          >
            Are you sure you want to log out ?
          </h4>
        </Modal.Header>
        <div style={{ textAlign: "center", padding: "30px" }}>
          <button
            style={{
              marginRight: "22px",
              borderRadius: "8px",
              fontsize: "14px",
            }}
            onClick={() => setShow(!show)}
            type="button"
            className="btn btn-secondary"
            data-mdb-dismiss="modal"
          >
            CANCEL
          </button>
          <button
            style={{ backgroundColor: "#FE2F7B", borderRadius: "8px" }}
            onClick={() => {
              if (localStorage.getItem("go-ticket")) {
                localStorage.removeItem("go-ticket");
              }
              window.location.href = config.baseUrl;
            }}
            type="button"
            className="btn text-white"
          >
            LOGOUT
          </button>
        </div>
      </Modal>
    </>
  );
}

export default NavBar;
