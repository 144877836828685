import { useEffect, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { FormControl } from "react-bootstrap";
import { ApiGuestUpdateStatus, ApiGuestUploadExcel } from "../../api/master";
import UploadIcon from "../../images/upload.svg";
import { CloseCircleIcon } from "../Common/Icons";
import { toast } from "react-hot-toast";
import * as Yup from "yup";

function UploadExcelForm(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [shownew, setShownew] = useState(false);
  const [error, setError] = useState("");
  const [uploadedFile, setUploadedFile] = useState();
  const [fileError, setFileError] = useState([]);
  const [loading, setLoading] = useState(false);

  const [initialValues1, setInitialValues] = useState({
    upload_excel: "",
    file_type: "",
  });

  const validationSchema = Yup.object().shape({
    upload_excel: Yup.mixed().required("Please select a file."),
  });

  useEffect(function () { }, [error]);
  return (
    <Formik
      initialValues={initialValues1}
      validateOnChange={false}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => {
        setLoading(true);
        let excel_file = uploadedFile?.upload_excel
          ? uploadedFile?.upload_excel
          : "";
        const formData = new FormData();

        formData.append("excel", excel_file);
        if (initialValues1.file_type == 1) {
          ApiGuestUploadExcel(formData)
            .then((res) => {
              if (res.status === 200) {
                const link = document.createElement("a");
                link.href = res.data.data.excel_url;
                link.setAttribute("download", "guestAdd.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setSuccess(res.data.message);
                setError("");
                setShownew(true);
                props.setFormData("");
                props.setIsGuestList();
              }
            })
            .catch((data) => {
              setError(data.response.data.data.message);
            })
            .finally((result) => {
              setTimeout(() => {
                setSuccess("");
                setLoading(false);
                setError("");
              }, 3000);
              setIsSubmit(false);
            });
        } else {
          ApiGuestUpdateStatus(formData)
            .then((res) => {
              if (res.status === 200) {
                const link = document.createElement("a");
                link.href = res.data.data.excel_url;
                link.setAttribute("download", "updateStatus.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setSuccess(res.data.message);
                setError("");
                setShownew(true);
                props.setFormData("");
                props.setIsGuestList();
              }
            })
            .catch((data) => {
              setError(data.response.data.data.message);
            })
            .finally((result) => {
              setTimeout(() => {
                setSuccess("");
                setLoading(false);
                setError("");
              }, 3000);
              setIsSubmit(false);
            });
        }
      }}
    >
      {({
        isSubmitting,
        handleChange,
        values,
        setFieldValue,
        errors,
        resetForm,
      }) => (
        <>
          <FormikForm className="guest-excel-form">
            <div className="row">
              <div className="col-md-12 text-center">
                <label htmlFor="file_type">
                  Select type
                  <span className="text-danger"> *</span>
                </label>
                <select
                  className="form-select custom-select"
                  id="file_type"
                  name="file_type"
                  value={initialValues1?.is_active}
                  onChange={(e) => {
                    setInitialValues({
                      ...initialValues1,
                      file_type: e.target.value,
                    });
                  }}
                >
                  {[
                    { id: "", name: "Select type" },
                    { id: 1, name: "Add Guest" },
                    { id: 2, name: "Update Status" },
                  ].map((v, i) => (
                    <option key={i} value={v.id}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-12 text-center">
                <label htmlFor="upload_excel">Upload Excel</label>

                <FormControl
                  type="file"
                  accept=".csv, .xls, .xlsx"
                  onChange={(event) => {
                    if (
                      event?.target?.files[0]?.type.includes(
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      )
                    ) {
                      const selectedFile = event.target.files[0];
                      setFieldValue("upload_excel", selectedFile);
                      setInitialValues({
                        ...initialValues1,
                        upload_excel: selectedFile,
                      });
                      setUploadedFile((prevState) => ({
                        ...prevState,
                        upload_excel: selectedFile,
                      }));
                      setFileError({
                        ...fileError,
                        upload_excel: "",
                      });
                    } else {
                      setFileError({
                        ...fileError,
                        upload_excel: "Please upload (Excel,csv) Only",
                      });
                    }
                  }}
                  // disabled={readonly}
                  autoComplete="off"
                  className="form-control"
                  id="upload_excel"
                  name="upload_excel"
                />
                {errors && errors.upload_excel && (
                  <div className="text-danger small text-start">
                    {errors.upload_excel}{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 mt-4 d-flex flex-column gap-2 justify-content-between align-items-center">
              {(error || success) && (
                <div
                  className={`alert alert-${error ? "danger" : "success"
                    } py-2 small`}
                  role="alert"
                >
                  {error || success || ""}
                </div>
              )}
              <div style={{ float: "right" }}>
                <button
                  className="branchModalCancel"
                  onClick={() => {
                    props.setFormData("");
                    props.setIsOpen(false);
                  }}
                  type="button"
                >
                  Cancel
                </button>
                {
                  props.modalType !== "isView" && (
                    // (shownew ? (
                    //   <button
                    //     id="createNew"
                    //     type="reset"
                    //     onClick={() => {
                    //       // setShownew(false);
                    //       setSuccess("");
                    //       setUploadedFile();
                    //       props.setFormData({
                    //         upload_excel: "",
                    //         file_type: "",
                    //       });

                    //       setInitialValues({
                    //         ...initialValues1,
                    //         file_type: "",
                    //         upload_excel: "",
                    //       });
                    //       resetForm();
                    //     }}
                    //   >
                    //     Add New
                    //   </button>
                    // ) : (
                    <button
                      type="submit"

                      className="mt-4 mb-1 btn-disable"
                      style={{
                        background:
                          uploadedFile === undefined ||
                            initialValues1.file_type === ""
                            ? "#e9ecef"
                            : null,
                      }}
                      disabled={
                        uploadedFile === undefined ||
                        initialValues1.file_type === ""
                        || loading
                      }
                    >
                      {loading ? <span className="spinner-border spinner-border-sm me-2"></span> : "Submit"}
                    </button>
                  )
                  // ))
                }
              </div>
              <div className="download-link">
                <a
                  style={{
                    pointerEvents:
                      initialValues1.file_type === "" ? "none" : "auto",
                  }}
                  href={
                    initialValues1.file_type == 1
                      ? // ? "goticket.xlsx"
                      "GoTicket Add Bulk Guest.xlsx"
                      : "GoTicket Payment Status.xlsx"
                  }
                  className="text-decoration-underline text-primary"
                >
                  <span>Download Sample File</span>
                </a>
              </div>
            </div>
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

export default UploadExcelForm;
