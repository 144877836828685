import * as Yup from "yup";

const eventAreaSchema = {};

eventAreaSchema.validateEventAreaAdd = () => {
  return Yup.object({
    event_area: Yup.string()
      .trim()
      .required("Event area name is required.")
      .min(2, "Event area name minimum length is 2")
      .max(30, "Event area name should be at most 30 characters.")
      .matches(
        /^[A-Za-z0-9 ]*$/,
        `Event area name should be Alphanumeric or [ ] only`
      ),
    event_id: Yup.string().trim().required("Event name is required."),
  });
};

export default eventAreaSchema;
