import React, { useEffect, useRef, useState } from "react";
import { apiCreateTeamDtl, apiGetTeamConf } from "../../api/master";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import Modal from "react-bootstrap/Modal";
import { CloseIcon } from "../../components/Common/Icons";
import formdatimg from "../../images/opps23.jpg";
import $ from "jquery";
import "./style.css";
import { MdImageNotSupported } from "react-icons/md";
import Addmodal from "./Addmodal";
import { FaUserEdit } from "react-icons/fa";
import Editmodal from "./EditModal";
import Footer from "../../components/partials/Footer";
import logo from "../../images/icons/2.jpg";
const tableSize = 10;
const headerLables = [
  {
    lable: "Name",
    value: "name",
    width: "200px",
  },
  {
    lable: "Mobile",
    value: "mobile",
    width: "200px",
  },
  {
    lable: "Photo",
    value: "pic",
    width: "100px",
  },
  {
    lable: "Aadhar",
    value: "id_1",
    width: "100px",
  },
  {
    lable: "Pan",
    value: "id_2",
    width: "100px",
  },
  {
    lable: "Action",
    value: "action",
  },
];
const ItemsPerPage = 10;
function TeamDetails() {
  const initialValues1 = {
    name: "",
    mobile: "",
    pic: "",
    id_1: "",
    id_2: "",
  };
  const { linktoken } = useParams();
  const [teamDetail, setTeamDetail] = useState();
  const [changing, setChanging] = useState(false);
  const [filesError, setFilesError] = useState(initialValues1);
  const [members, setMembers] = useState();

  const [uploadError, setUploadError] = useState();

  const [error, setError] = useState(initialValues1);
  const [errorIndex, setErrorIndex] = useState(null);

  const [picFiles, setPicFiles] = useState(null);
  const [adharFiles, setAdharFiles] = useState(null);
  const [panFiles, setPanFiles] = useState(null);

  const [picFilesLink, setPicFilesLink] = useState({
    pointer: null,
    link: "",
  });
  const [adharFilesLink, setAdharFilesLink] = useState({
    pointer: null,
    link: "",
  });
  const [panFilesLink, setPanFilesLink] = useState({
    pointer: null,
    link: "",
  });

  const [reqFields, setReqFields] = useState();
  const [random, setRandom] = useState(null);

  const [submit, setSubmit] = useState({
    state: false,
    index: null,
  });

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [data, setData] = useState("");

  console.log("data", data);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getlist = () => {
    const token = linktoken.split("_").slice(1).join("_");
    apiGetTeamConf("", token)
      .then((data) => {
        resetInputs();
        setTeamDetail(data.data.data[0]);
      })
      .catch((err) => setUploadError(err?.response?.data?.data?.message));
  };
  useEffect(() => {
    getlist();
  }, [linktoken, random]);

  const resetInputs = () => {
    setError(initialValues1);
    setPicFiles(null);
    setAdharFiles(null);
    setPanFiles(null);
    setPicFilesLink({
      pointer: null,
      link: "",
    });
    setAdharFilesLink({
      pointer: null,
      link: "",
    });
    setPanFilesLink({
      pointer: null,
      link: "",
    });
  };

  useEffect(() => {
    if (teamDetail) {
      const req_fields = teamDetail?.req_fields;
      setReqFields(JSON.parse(req_fields));

      const memberArray = generateMemberArray(
        parseInt(teamDetail?.slots),
        teamDetail?.members || []
      );

      setMembers(memberArray);
    }
  }, [teamDetail]);

  const validateFormData = (formData, validationObject) => {
    const errors = {};

    for (const key of formData.keys()) {
      if (
        validationObject.hasOwnProperty(key) &&
        validationObject[key] === "y"
      ) {
        const fieldValue = formData.get(key);
        if (!fieldValue || fieldValue === "" || fieldValue == null) {
          errors[key] = `This Field is required`;
        }
      }
    }

    return errors;
  };

  const handleSave = async (type, index, team_dtl_id) => {
    setSubmit({
      state: true,
      index,
    });
    const formData = new FormData();

    // if (type === 0) {

    team_dtl_id && formData.append("team_dtl_id", team_dtl_id);
    formData.append("name", members[index]?.name ? members[index]?.name : "");
    formData.append(
      "mobile",
      members[index]?.mobile ? members[index].mobile : ""
    );
    formData.append("pic", checkToBeUploadedPic(1, index));
    // formData.append("pic", picFiles ? picFiles : "");
    formData.append("id_1", checkToBeUploadedPic(2, index));
    // formData.append("id_1", adharFiles ? adharFiles : "");
    formData.append("id_2", checkToBeUploadedPic(3, index));
    // formData.append("id_2", panFiles ? panFiles : "");

    const errors = validateFormData(formData, reqFields);

    if (Object.keys(errors).length) {
      setError(errors);
      setErrorIndex(index);
      setSubmit({
        state: false,
        index: null,
      });
      return;
    }

    await apiCreateTeamDtl(formData, linktoken.split("_").slice(1).join("_"))
      .then((res) => {
        setRandom(Math.random());
      })
      .catch((err) => {
        // setRandom(Math.random());
        setError(initialValues1);
        alert(
          JSON.stringify(err?.response?.data?.data?.message) || "No Error Found"
        );
      })
      .finally(() => {
        setSubmit({
          state: false,
          index: null,
        });
      });
    setSubmit({
      state: false,
      index: null,
    });
    setChanging(false);
  };

  const checkToBeUploadedPic = (type, index) => {
    if (type == 1) {
      if (picFilesLink?.pointer != null && picFilesLink?.pointer == index) {
        return picFiles;
      } else if (members[index]?.pic != "" && members[index]?.pic != null) {
        return members[index]?.pic;
      } else {
        setPicFiles(null);
        setPicFilesLink({
          pointer: null,
          link: "",
        });
        return "";
      }
    } else if (type == 2) {
      if (adharFilesLink?.pointer != null && adharFilesLink?.pointer == index) {
        return adharFiles;
      } else if (members[index]?.id_1 != "" || members[index]?.id_1 != null) {
        return members[index]?.id_1;
      } else {
        setAdharFiles(null);
        setAdharFilesLink({
          pointer: null,
          link: "",
        });
        return "";
      }
    } else if (type == 3) {
      if (panFilesLink?.pointer != null && panFilesLink?.pointer == index) {
        return panFiles;
      } else if (members[index]?.id_2 != "" || members[index]?.id_2 != null) {
        return members[index]?.id_2;
      } else {
        setPanFiles(null);
        setPanFilesLink({
          pointer: null,
          link: "",
        });
        return "";
      }
    }
  };
  const generateMemberArray = (slots, members) => {
    const memberArray = members.map((member) => ({
      team_dtl_id: member?.team_dtl_id || "",
      name: member?.name || "",
      mobile: member?.mobile || "",
      pic: member?.pic || "",
      id_1: member?.id_1 || "",
      id_2: member?.id_2 || "",
      status: member?.is_active,
      rej_reason: member?.rej_reason || "",
    }));

    return memberArray;
  };

  const isKeyPresentAndEqualToY = (key, obj) => {
    return key in obj && obj[key] === "y";
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  function handleImageClick(url) {
    setModalImage(url);
    setIsModalOpen(() => true);
  }

  function handleModalClose() {
    setIsModalOpen(() => false);
  }

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain");
    const onlyNumbers = pastedText.replace(/\D/g, "");
    event.preventDefault();
    document.execCommand("insertText", false, onlyNumbers);
  };

  let lastKey = null;
  function inputMobile(e) {
    if (lastKey == "Control" && e.key == "v") {
      lastKey = e.key;
      return true;
    }
    if (lastKey == "Control" && e.key == "c") {
      lastKey = e.key;
      return true;
    }
    lastKey = e.key;
    let allowKeys = [
      "Tab",
      "Backspace",
      "Delete",
      "Enter",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "Control",
    ];
    if (!($.isNumeric(e.key) || allowKeys.includes(e.key))) e.preventDefault();
  }
  const [currentPage, setCurrentPage] = useState(1);

  const handleSeeMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const startIndex = (currentPage - 1) * ItemsPerPage;
  const endIndex = startIndex + ItemsPerPage;

  return teamDetail && reqFields ? (
    <div className="py-3 px-4  mainpage">
      <div className="d-flex justify-content-between">
        <div className="text-center">
          <img
            src={teamDetail?.mst_comp.comp_logo}
            alt="logo"
            style={{ height: "100px", width: "100px", borderRadius: "6px" }}
          />
          {teamDetail?.mst_comp.comp_name && (
            <h6>{teamDetail?.mst_comp.comp_name}</h6>
          )}
        </div>

        <div className="">
          <h5 className="">Welcome {teamDetail?.manager_name}</h5>
        </div>
      </div>
      <hr />
      <div className="text-center">
        <h2>{teamDetail?.event_name}</h2>
      </div>
      <div className="container mx-auto text-center">
        <h4>Add your {teamDetail?.team_name} Team</h4>

        {members?.length >= teamDetail?.slots ? (
          ""
        ) : (
          <div className="text-end">
            <Button
              // size=""team
              style={{height:"40px"}}
              className="px-3 py-0 ms-2 rounded-1 mb-2"
              onClick={handleShow}
            >
              Add Crew
            </Button>
          </div>
        )}
        <div className="website">
          <Table bordered striped responsive>
            <thead>
              <tr>
                {/* {headerLables?.map((item, index) => {
                  return (
                    <th style={{ width: "120px" }}>

                      {isKeyPresentAndEqualToY(item?.value, reqFields) && (
                        <span className="text-danger">{item?.lable}{" "} *</span>
                      )}
                    </th>
                  );
                })} */}
                {headerLables?.map((item, index) => {
                  return item?.value === "action" ? (
                    <th style={{ width: "120px" }} key={index}>
                      {" "}
                      {item?.lable}
                    </th>
                  ) : (
                    isKeyPresentAndEqualToY(item?.value, reqFields) && (
                      <th style={{ width: `${item?.width}` }}>
                        {item?.lable} <span className="text-danger">*</span>
                      </th>
                    )
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {members.length <= 0 ? (
                <tr>
                  <td colSpan={6}>No Record</td>
                </tr>
              ) : (
                ""
              )}

              {members &&
                members.slice(0, endIndex).map((m, index) => (
                  <>
                    <tr key={index}>
                      {/* name */}
                      {isKeyPresentAndEqualToY("name", reqFields) && (
                        <td>
                          <Form.Control
                            autoComplete="off"
                            style={{ padding: "0.2rem", width: "200px" }}
                            // disabled={m?.status === 0 || m?.status === 1}
                            disabled={true}
                            required={reqFields.name === "y"}
                            value={m?.name || "NA"}
                            name="name"
                            maxLength="50"
                            id="name"
                            size="sm"
                            type="text"
                            placeholder="Enter name"
                            onChange={(e) => {
                              setMembers((prevState) => {
                                let newState = [...prevState];
                                newState[index].name = e.target.value;
                                newState[index].status = "";
                                return newState;
                              });
                              setChanging(true);
                            }}
                          />

                          {errorIndex == index && error?.name && (
                            <text
                              className="small "
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {error?.name}
                            </text>
                          )}
                        </td>
                      )}
                      {/* mobile */}
                      {isKeyPresentAndEqualToY("mobile", reqFields) && (
                        <td>
                          <Form.Control
                            autoComplete="off"
                            // disabled={m?.status === 0 || m?.status === 1}
                            disabled={true}
                            required={reqFields.mobile === "y"}
                            value={m?.mobile || "NA"}
                            name="mobile"
                            id="mobile"
                            size="sm"
                            type="mobile"
                            maxLength="12"
                            inputMode="numeric"
                            style={{ padding: "0.2rem", width: "200px" }}
                            pattern="[0-9]"
                            placeholder="Enter mobile"
                            onPaste={handlePaste}
                            onKeyDown={inputMobile}
                            onChange={(e) => {
                              setMembers((prevState) => {
                                let newState = [...prevState];
                                newState[index].mobile = e.target.value;
                                newState[index].status = "";
                                return newState;
                              });
                              setChanging(true);
                            }}
                          />
                          {errorIndex == index && error?.mobile && (
                            <text
                              className="small "
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {error?.mobile}
                            </text>
                          )}
                        </td>
                      )}
                      {/* pic */}
                      {isKeyPresentAndEqualToY("pic", reqFields) && (
                        <td>
                          <div className="d-flex justify-content-center align-content-center">
                            <>
                              {m?.pic == "" ? (
                                <MdImageNotSupported />
                              ) : (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleImageClick(
                                      picFilesLink?.pointer == index
                                        ? picFilesLink?.link
                                        : m?.pic
                                    );
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-eye-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                  </svg>
                                </span>
                              )}
                            </>
                          </div>
                          {errorIndex == index && error?.pic && (
                            <text
                              className="small "
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {error?.pic}
                            </text>
                          )}
                        </td>
                      )}
                      {/* aadhar */}
                      {isKeyPresentAndEqualToY("id_1", reqFields) && (
                        <td>
                          <div className="d-flex justify-content-center align-content-center">
                            {m?.id_1 == "" ? (
                              <MdImageNotSupported />
                            ) : (
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleImageClick(
                                    adharFilesLink?.pointer == index
                                      ? adharFilesLink?.link
                                      : m?.id_1
                                  );
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-eye-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                </svg>
                              </span>
                            )}
                          </div>
                          {errorIndex == index && error?.id_1 && (
                            <text
                              className="small "
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {error?.id_1}
                            </text>
                          )}
                        </td>
                      )}
                      {/* pan */}
                      {isKeyPresentAndEqualToY("id_2", reqFields) && (
                        <td>
                          <div className="d-flex justify-content-center align-content-center">
                            <>
                              {m?.id_2 == "" ? (
                                <MdImageNotSupported />
                              ) : (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleImageClick(
                                      panFilesLink?.pointer == index
                                        ? panFilesLink?.link
                                        : m?.id_2
                                    );
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-eye-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                  </svg>
                                </span>
                              )}
                            </>
                          </div>
                          {errorIndex == index && error?.id_2 && (
                            <text
                              className="small "
                              style={{
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {error?.id_2}
                            </text>
                          )}
                        </td>
                      )}
                      {/* action */}
                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          {m.status === 2 ? (
                            <>
                              <span className="text-danger">Rejected</span>
                              <Tippy
                                className="bg-white p-5 text-black border border-2"
                                content={m?.rej_reason || "No reason provided"}
                                trigger="mouseenter"
                              >
                                <span
                                  style={{
                                    fontSize: "1.2rem",
                                    cursor: "pointer",
                                  }}
                                  className="fw-bold mx-2"
                                >
                                  i
                                </span>
                              </Tippy>

                              <FaUserEdit
                                style={{
                                  width: "40px",
                                  marginLeft: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleShowEdit();
                                  setData(m);
                                }}
                              />
                            </>
                          ) : m.status === 0 ? (
                            <>
                              <span className="text-primary">Pending</span>
                              {/* {
                            index === members.length - 1 && members.length <= teamDetail?.slots &&
                            (
                              <Button
                                size="sm"
                                className="px-3 py-0 ms-2"
                                onClick={() => {
                                  setMembers((prevState) => [
                                    ...prevState,
                                    {
                                      team_dtl_id: "",
                                      name: "",
                                      mobile: "",
                                      pic: "",
                                      id_1: "",
                                      id_2: "",
                                      status: null,
                                      rej_reason: "",
                                    },
                                  ]);
                                }}
                              >
                                +
                              </Button>
                            )

                          } */}
                            </>
                          ) : m.status === 1 ? (
                            <>
                              <span className="text-success">Approved</span>
                              {/* {
                            index === members.length - 1 && members.length <= teamDetail?.slots &&
                            (
                              <Button
                                size="sm"
                                className="px-3 py-0 ms-2"
                                onClick={() => {
                                  setMembers((prevState) => [
                                    ...prevState,
                                    {
                                      team_dtl_id: "",
                                      name: "",
                                      mobile: "",
                                      pic: "",
                                      id_1: "",
                                      id_2: "",
                                      status: null,
                                      rej_reason: "",
                                    },
                                  ]);
                                }}
                              >
                                +
                              </Button>
                            )

                          } */}
                            </>
                          ) : (
                            <>
                              <Button
                                size="sm"
                                className="px-3 py-0"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  // setMembers((prevState) => {
                                  //   let newState = [...prevState];
                                  //   newState[index].status = 0;
                                  //   return newState;
                                  // });
                                  handleSave(0, index, m.team_dtl_id);
                                  // await apiCreateTeamDtl({ member: members[index] })
                                }}
                              >
                                {submit?.state && submit?.index == index && (
                                  <span className="spinner-border spinner-border-sm me-2"></span>
                                )}
                                Save
                              </Button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="Wrapper_main Responsive row p-0">
          {members &&
            members.slice(0, endIndex).map((m, index) => (
              <div className="col-md-4 col-sm-12 col-lg-3 p-0">
                <div className="card_box">
                  {isKeyPresentAndEqualToY("name", reqFields) && (
                    <h2> Name : {m.name}</h2>
                  )}
                  {isKeyPresentAndEqualToY("mobile", reqFields) && (
                    <h6> Mobile : {m.mobile}</h6>
                  )}

                  {m.status === 2 ? (
                    <>
                      <span className="text-danger">Rejected</span>{" "}
                      <FaUserEdit
                        style={{
                          width: "40px",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleShowEdit();
                          setData(m);
                        }}
                      />
                      <br />
                      <span>
                        Reason : {m?.rej_reason || "No reason provided"}
                      </span>
                    </>
                  ) : m.status === 0 ? (
                    <>
                      <span className="text-primary">Pending</span>
                    </>
                  ) : m.status === 1 ? (
                    <>
                      <span className="text-success">Approved</span>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="d-flex justify-content-center mt-3">
                    <div className="View-btn d-flex flex-column gap-1">
                      {isKeyPresentAndEqualToY("pic", reqFields) && (
                        <button
                          style={{
                            borderRadius: "5px",
                            height: "40px",
                            width: "150px",
                          }}
                          className="btn btn-primary "
                          disabled={m?.pic == "" ? true : false}
                        >
                          {m?.pic == "" ? (
                            <MdImageNotSupported />
                          ) : (
                            <span
                              onClick={() => {
                                handleImageClick(
                                  picFilesLink?.pointer == index
                                    ? picFilesLink?.link
                                    : m?.pic
                                );
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-eye-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                              </svg>
                              <span className="ms-1"> Photo</span>
                            </span>
                          )}
                        </button>
                      )}
                      {isKeyPresentAndEqualToY("id_1", reqFields) && (
                        <button
                          style={{
                            borderRadius: "5px",
                            height: "40px",
                            width: "150px",
                          }}
                          className="btn btn-primary "
                          disabled={m?.id_1 == "" ? true : false}
                        >
                          {m?.id_1 == "" ? (
                            <MdImageNotSupported />
                          ) : (
                            <span
                              onClick={() => {
                                handleImageClick(
                                  adharFilesLink?.pointer == index
                                    ? adharFilesLink?.link
                                    : m?.id_1
                                );
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-eye-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                              </svg>
                              <span className="ms-1"> Aadhar</span>
                            </span>
                          )}
                        </button>
                      )}
                      {isKeyPresentAndEqualToY("id_2", reqFields) && (
                        <button
                          style={{
                            borderRadius: "5px",
                            height: "40px",
                            width: "150px",
                          }}
                          className="btn btn-primary "
                          disabled={m?.id_2 == "" ? true : false}
                        >
                          {m?.id_2 == "" ? (
                            <MdImageNotSupported />
                          ) : (
                            <span
                              onClick={() => {
                                handleImageClick(
                                  panFilesLink?.pointer == index
                                    ? panFilesLink?.link
                                    : m?.id_2
                                );
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-eye-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                              </svg>
                              <span className="ms-1"> Pan</span>
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {members.length > endIndex && (
          <>
            <div className="text-center">
              <Button onClick={handleSeeMore} className="px-3 py-0  rounded-1">
                See More...
              </Button>
            </div>
          </>
        )}

        {filesError.pic ? (
          <div className={`alert alert-danger py-2 small`} role="alert">
            {filesError.pic}
          </div>
        ) : null}
        {filesError.id_1 ? (
          <div className={`alert alert-danger py-2 small`} role="alert">
            {filesError.id_1}
          </div>
        ) : null}
        {filesError.id_2 ? (
          <div className={`alert alert-danger py-2 small`} role="alert">
            {filesError.id_2}
          </div>
        ) : null}
      </div>
      {/* <div className="position-relative bottom-0 mb-1">
        Powered By{" "}
        <span className="text-primary fw-semibold">goTicket.pro</span>
      </div> */}
      <Footer />

      <Modal
        closeButton
        show={isModalOpen}
        onHide={handleModalClose}
        keyboard={false}
        backdrop="static"
      >
        <div>
          {modalImage ? (
            <div style={{ height: "500px", width: "100%" }}>
              <img
                src={modalImage}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          ) : (
            <div
              style={{
                height: "500px",
                width: "100%",
                border: "1px solid red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>No Image</span>
            </div>
          )}
        </div>
        <button onClick={handleModalClose}>Close</button>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
      >
        <Addmodal
          validateFormData={validateFormData}
          reqFields={reqFields}
          handleClose={handleClose}
          linktoken={linktoken}
          getlist={getlist}
          isKeyPresentAndEqualToY={isKeyPresentAndEqualToY}
          teamDetail={teamDetail}
          members={members}
        />
      </Modal>
      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        keyboard={false}
        backdrop="static"
      >
        <Editmodal
          validateFormData={validateFormData}
          reqFields={reqFields}
          handleClose={handleCloseEdit}
          linktoken={linktoken}
          getlist={getlist}
          data={data}
          isKeyPresentAndEqualToY={isKeyPresentAndEqualToY}
        />
      </Modal>
    </div>
  ) : (
    <div className="container mx-auto text-center my-5 flex">
      <img style={{ width: "50%" }} src={logo} alt="" />
      <div
        className="position-relative bottom-0 mb-1"
        style={{ fontSize: "37px" }}
      >
        {/* <span>Loading...</span> */}
      </div>
      <div
        className="position-relative bottom-0 mb-1"
        style={{ fontSize: "37px" }}
      >
        {uploadError}
      </div>
    </div>
  );
}

export default TeamDetails;
