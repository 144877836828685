export function getRequiredFilters(filters) {
    return filters?.filter(item => item?.extra?.required)?.map(field => field?.f_name)
}

export function validateFilters(reqFields, filterData) {
    for (let i = 0; i < reqFields.length; i++)
        if (filterData[reqFields[i]] == '')
            return {status: false, data: reqFields[i]}

    return {status: true}
}