import { useEffect } from "react";
import config from "../../config";

function Logout() {
  useEffect(function () {
    if (localStorage.getItem("go-ticket")) {
      localStorage.removeItem("go-ticket");
    }
    window.location.href = config.baseUrl;
  }, []);

}

export default Logout;
