import { useEffect, useState } from "react";
import "./style.css";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import { apiCreateCompany } from "../../../api/master";
import companySchema from "../../../validations/Company";
import UploadIcon from "../../../images/upload.svg";
import { CloseIcon } from "../../../components/Common/Icons";

function CompanyAdd(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [selectedcode, setSelectedCode] = useState("");
  const [shownew, setShownew] = useState(false);

  const [logo, setLogo] = useState("");
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const handlecodeChange = (selectedcode) => {
    setSelectedCode(selectedcode);
  };

  const componyFormik = useFormik({
    initialValues: {
      comp_name: "",
      comp_logo: "",
    },
    validationSchema: companySchema.validateCompanyAdd(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setIsSubmit(true);
      if (!file) {
        setFileError("Please Select Logo");
        setIsSubmit(false);
        return;
      }
      const formData = new FormData();
      formData.append("comp_name", values.comp_name.trim());
      formData.append("comp_logo", file);

      await apiCreateCompany(formData)
        .then((data) => {
          setSuccess(data.data.message);
          setError("");
          setFileError("");
          setShownew(true);
          props.list();
        })
        .catch((err) => {
          setError(err.response.data.data.message);
          setSuccess("");
        })
        .finally((result) => {
          setIsSubmit(false);
        });
    },
    onReset: async (values) => {
      setFileError("");
      setSelectedCode(null);
      setShownew(false);
      setSuccess("");
      setFile(null);
      setLogo("");
    },
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(
    function () {
      const timer = setTimeout(() => {
        setSuccess("");
        setError("");
      }, 5000);
      return () => clearTimeout(timer);
    },
    [success, error]
  );

  return (
    <>
      <Modal.Header closeButton>
        <h4 style={{ marginLeft: "10rem", height: "4px" }} id="modalhead">
          Add Company
        </h4>
      </Modal.Header>
      <div className="container-fluid p-4" id="buyerAdd">
        <form
          className="row gx-5 gy-2"
          onSubmit={componyFormik.handleSubmit}
          onReset={componyFormik.handleReset}
          autoComplete="off"
        >
          <div className="col-md-6">
            <label htmlFor="branch_name">
              Company Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="comp_name"
              maxLength={100}
              {...componyFormik.getFieldProps("comp_name")}
            />
            {componyFormik.errors.comp_name && shownew != true ? (
              <div className="text-danger small">
                {componyFormik.errors.comp_name}
              </div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label htmlFor="comp_logo">
              Company logo <span className="text-danger">*</span>
            </label>
            <div className="uploadBox mt-1">
              {!logo ? (
                <label className="" for="comp_logo">
                  <div className="uploadThumbnial">
                    <img src={UploadIcon} alt="" />
                  </div>
                </label>
              ) : (
                <>
                  <span
                    className="removeImage"
                    role="button"
                    onClick={() => {
                      setLogo("");
                      setFile("");
                    }}
                  >
                    <CloseIcon />
                  </span>
                  <label className="" for="comp_logo">
                    <div className="previewBox">
                      <img src={logo} alt="" />
                    </div>
                  </label>
                </>
              )}
              <input
                type="file"
                className="form-control"
                accept="image/*"
                {...componyFormik.getFieldProps("comp_logo")}
                onChange={handleFileChange}
                id="comp_logo"
                autoComplete="off"
                name="comp_logo"
              />
            </div>
            {fileError ? (
              <div className="text-danger small">{fileError}</div>
            ) : null}
          </div>

          <div className="col-12 mt-4">
            {(error || success) && (
              <div
                className={`alert alert-${
                  error ? "danger" : "success"
                } py-2 small`}
                role="alert"
              >
                {error || success || ""}
              </div>
            )}
            <div style={{ float: "right" }}>
              <button
                className="branchModalCancel"
                onClick={props.hide}
                type="button"
              >
                Cancel
              </button>
              {shownew ? (
                <button
                  id="createNew"
                  type="reset"
                  style={{ backgroundColor: "#4263EB", color: "#fff" }}
                  // onClick={() => {
                  //   setSelectedCode(null);
                  //   componyFormik.resetForm();
                  //   setShownew(false);
                  //   setSuccess("");
                  //   setLogo("");
                  //   setFile(null);
                  // }}
                >
                  Add New
                </button>
              ) : (
                <button type="submit" disabled={isSubmit}>
                  {isSubmit && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CompanyAdd;
