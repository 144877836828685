import { request } from './request';

const loginToken = JSON.parse(localStorage.getItem("go-ticket"))?.token;

const headers = {
  headers: {
    Authorization: `Bearer ${loginToken}`,
  },
};

export const apiState = () =>
  request(
    'post',
    '/state/listState',
    {
      paginate: 0,
    },
    headers,
  );

export const apiCity = () =>
  request(
    'post',
    '/city/listCity',
    {
      paginate: 0,
    },
    headers,
  );
