import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "../Pagination";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const Commontable = ({
   isClear,
   setIsClear,
   context,
   showNumberOrder,
   ActionMenuItem,
   isLoading,
   data,
   columns,
   currentPage,
   setCurrentPage,
   totalPage,
   checkboxSelection,
   showStatusEnable,
   changeStatus,
   statusChangeKey,
   error,
}) => {
   const deep_value = (obj, path) =>
      path
         .replace(/\[|\]\.?/g, ".")
         .split(".")
         .filter((s) => s)
         .reduce((acc, val) => acc && acc[val], obj);

   const generateTd = (e, row) => {
      let feild = e.feild.split(".");
      return Object.keys(row).map((ColumnDataFeild, index) => {
         if (ColumnDataFeild === feild[0]) {
            if (feild.length > 1) {
               let rowDataObject = feild.filter((e, i) => i !== 0).toString();
               let rowDataPath = rowDataObject.replaceAll(",", ".");
               let ColumnObject = Object.values(row)[index];
               let ColumnValue = deep_value(ColumnObject, `.${rowDataPath}`);
               if (e.cellRenderer) {
                  return <td>aa{e.cellRenderer(ColumnValue)}</td>;
               } else {
                  return <td key={"key_" + index}>{ColumnValue}</td>;
               }
            }
            if (feild.length === 1) {
               if (e.cellRenderer) {
                  return <td>{e.cellRenderer(Object.values(row)[index])}</td>;
               } else {
                  return (
                     <td key={"key_" + index}>{Object.values(row)[index]}</td>
                  );
               }
            }
         }
      });
   };

   const [selectedArray, setSelectedArray] = useState([]);

   useEffect(() => {
      if (isClear) {
         setSelectedArray([]);
         context?.onCheckboxSelection([]);
         setIsClear(false);
      }
   }, [isClear]);

   useEffect(() => {
      context?.onCheckboxSelection &&
         context?.onCheckboxSelection(selectedArray);
   }, [selectedArray]);

   const onCheckBoxCheck = (row, e) => {
      let isChecked = e.target.checked;
      if (isChecked) {
         setSelectedArray((selectedArray) => selectedArray.concat(row));
      } else {
         setSelectedArray(
            selectedArray.filter(
               (roData) => JSON.stringify(roData) !== JSON.stringify(row)
            )
         );
      }
   };

   const onCheckBoxCheckAll = (e) => {
      let isChecked = e.target.checked;
      if (isChecked) {
         setSelectedArray(() => data.filter((row) => row.is_active != 0));
      } else {
         setSelectedArray(() => []);
      }
   };
   let TotalColumns = columns.length + 2;
   if (checkboxSelection) {
      TotalColumns += 1;
   }

   return (
      <>
         <div className="row mb-4">
            <div className="col">
               <Table
                  // responsive
                  className={`table ${error ? "my-1" : "mt-4"} rounded`}
               >
                  <colgroup>
                     {checkboxSelection && <col width={"50"} />}
                     {showNumberOrder && <col width={"50"} />}
                     {columns.map((e, i) => {
                        e.width ? (
                           <col width={e.width} />
                        ) : (
                           <col width="auto" />
                        );
                     })}
                     {showStatusEnable && <col width={"auto"} />}
                     <col />
                  </colgroup>
                  <thead>
                     <tr>
                        {checkboxSelection && (
                           <th>
                              <input
                                 id="checkAllInput"
                                 type="checkbox"
                                 onClick={(e) => onCheckBoxCheckAll(e)}
                              />
                           </th>
                        )}
                        {showNumberOrder && <th>#</th>}
                        {columns.map((e, i) => {
                           return <th key={i}>{e.header}</th>;
                        })}
                        {showStatusEnable && <th>Status</th>}
                        {ActionMenuItem && <th>Actions</th>}
                     </tr>
                  </thead>
                  <tbody>
                     {isLoading || data.length === 0 ? (
                        <tr>
                           <td
                              colSpan={data.length === 0 ? 10 : TotalColumns}
                              className="text-center"
                           >
                              {isLoading ? "Loading..." : "No Records"}
                           </td>
                        </tr>
                     ) : (
                        data.map((row, i) => {
                           return (
                              <tr
                                 key={`bl_` + i}
                                 style={{ verticalAlign: "middle" }}
                              >
                                 {checkboxSelection && (
                                    <td>
                                       {context?.route == "guest" ? (
                                          <>
                                             {row?.is_active !== 0 && (
                                                <input
                                                   type="checkbox"
                                                   checked={selectedArray.includes(
                                                      row
                                                   )}
                                                   className="table-checkbox"
                                                   onChange={(e) =>
                                                      onCheckBoxCheck(row, e)
                                                   }
                                                />
                                             )}
                                          </>
                                       ) : (
                                          <input
                                             type="checkbox"
                                             checked={selectedArray.includes(
                                                row
                                             )}
                                             className="table-checkbox"
                                             onChange={(e) =>
                                                onCheckBoxCheck(row, e)
                                             }
                                          />
                                       )}
                                    </td>
                                 )}
                                 {showNumberOrder && (
                                    <td>{currentPage * 10 - 10 + i + 1}</td>
                                 )}

                                 {columns.map((e) => generateTd(e, row))}
                                 {showStatusEnable && (
                                    <td>
                                       <Tippy content="Change Status">
                                          {row.is_active ? (
                                             <span
                                                className="badge text-bg-success"
                                                role="button"
                                                onClick={() =>
                                                   changeStatus(
                                                      row[statusChangeKey],
                                                      0
                                                   )
                                                }
                                             >
                                                Active
                                             </span>
                                          ) : (
                                             <span
                                                className="badge text-bg-danger"
                                                role="button"
                                                onClick={() =>
                                                   changeStatus(
                                                      row[statusChangeKey],
                                                      1
                                                   )
                                                }
                                             >
                                                In-Active
                                             </span>
                                          )}
                                       </Tippy>
                                    </td>
                                 )}
                                 {ActionMenuItem && (
                                    <td className="fitwidth">
                                       <ActionMenuItem
                                          context={context}
                                          data={row}
                                       />
                                    </td>
                                 )}
                              </tr>
                           );
                        })
                     )}
                  </tbody>
               </Table>
            </div>
         </div>
         {data && data.length > 0 && (
            <div className="row">
               <div className="col">
                  <Pagination
                     nPages={Math.ceil(totalPage / 10)}
                     currentPage={currentPage}
                     setCurrentPage={setCurrentPage}
                  />
               </div>
            </div>
         )}
      </>
   );
};
export default Commontable;
