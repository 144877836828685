import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb";
import Commontable from "../../components/Common/CommonTable";
import Layout from "../../components/Common/Layout";
import { apiEventList, apiEventDelete } from "../../api/master";
import CommonModal from "../../components/Common/Modal";
import EventForm from "../../components/Events/EventsForm";
import { apiCity, apiState } from "../../api/stateAndCity";
import Filter from "../../images/icons/filter.png";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
import AssignUserTable from "../../components/Events/AssignUserTable";
import ActionMenuItem from "../../components/Events/ActionMenuItem";
import EventCencelForm from "../../components/Events/EventCencelForm";
// import EventCencelForm from "../../components/Events/EventCencelForm";
import Select from "react-select";
import moment from "moment";

const EventsList = () => {
  const [checked, setChecked] = useState(false);
  const [reason, setReason] = useState("");
  const initialState = {
    name: "",
    is_active: "",
    past_event: "",
  };
  const [userRole, setUserRole] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState("");
  const [modalType, setModalType] = useState("isAdd");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [formInputs, setFormInputs] = useState(initialState);
  const [stateValue, setStateValue] = useState([]);
  const [eventList, getEventList] = useState(false);
  const [eventId, setEventId] = useState();
  const [error, setError] = useState("");

  const status = [
    { value: false, label: "In-Active", className: "danger" },
    { value: true, label: "Active", className: "success" },
  ];

  const checkStatus = (isAction) => {
    let statusObj = status.filter((e) => e.value === isAction)[0];
    return (
      <span
        className={`text-capitalize badge rounded-pill bg-${statusObj.className} `}
      >
        {statusObj.label}
      </span>
    );
  };

  const [city, setCity] = useState([]);
  const formatData = (date) => {
    return moment(date).format("DD-MM-YYYY h:mm A");
  };
  const columns = [
    { feild: "name", header: "Name" },
    { feild: "st_dt", header: "Start Date & Time", cellRenderer: formatData },
    { feild: "ed_dt", header: "End Date & Time", cellRenderer: formatData },
    { feild: "is_active", header: "Status", cellRenderer: checkStatus },
  ];
  const getList = (isFilter = true, pageNo = null) => {
    let json = {
      paginate: 1,
      page_no: (pageNo || currentPage) - 1,
      is_active: "",
    };
    if (isFilter) {
      json = { ...json, ...formInputs };
    }
    apiEventList(json)
      .then((res) => {
        setCurrentPage(pageNo || currentPage);
        setIsLoading(true);
        setList(res.data.data.list);
        setIsLoading(false);
        setTotalPage(res.data.data.count);
      })
      .catch((err) => setError(err.response.data.data.message));
  };
  async function getStateAndCity() {
    await apiState().then((result) => {
      let stateData = result?.data?.data.list;
      let newStateArray = stateData.map((item) => {
        return {
          label: item.name,
          value: item.state_id,
        };
      });
      setStateValue(newStateArray);
    });
    await apiCity()
      .then((result) => {
        let stateData = result?.data?.data.list;
        let newCityArray = stateData.map((item) => {
          return {
            label: item.city,
            value: item.city_id,
            state_id: item.state_id,
          };
        });
        setCity(newCityArray);
      })
      .catch((err) => setError(err.response.data.data.message));
  }
  useEffect(() => {
    getStateAndCity();
  }, []);
  useEffect(() => {
    getEventList(false);
  }, [eventList]);
  useEffect(() => {
    if (localStorage.getItem("go-ticket")) {
      setUserRole(
        JSON.parse(localStorage.getItem("go-ticket")).userData.role_id
      );
    }
  }, [currentPage]);

  useEffect(() => {
    if (eventList === false) getList();
  }, [eventList, currentPage]);

  useEffect(() => {
    let newList = list.map((event) => {
      event.reason = reason;
    });
    setList(newList);
  }, [reason]);

  function handleInputs(e) {
    setFormInputs({ ...formInputs, [e.target.id]: e.target.value });
  }

  const handleFilterVisibility = () => {
    if (filtersVisible) {
      setFiltersVisible(false);
      setFormInputs(initialState);
    } else {
      setFiltersVisible(true);
    }
  };
  const onClickActionItemClick = (data, e) => {
    const { id } = e.currentTarget;
    const type = id;
    if (type === "isView") {
      setIsOpen(true);
      setFormData(data);
      setModalType("isView");
    } else if (type === "isEdit") {
      setIsOpen(true);
      setFormData(data);
      setModalType("isEdit");
    } else if (type === "addMember") {
      setIsOpen(true);
      setEventId(data);
      setModalType("addMember");
    } else if (type === "isDelete") {
      setIsOpen(true);
      setFormData(data);
      setModalType("isDelete");
    }
  };
  return (
    <>
      <Layout>
        <Row className="align-items-center">
          <Col>
            <Breadcrumb path={["Events"]} />
          </Col>
          <Col className="text-end">
            <Tippy content="Filter">
              <img
                src={Filter}
                onClick={() => handleFilterVisibility()}
                className="img-fluid me-3"
                alt=""
                role="button"
                style={{ width: "2.5rem" }}
              />
            </Tippy>
            {userRole === 2 && (
              <Button
                type="button"
                onClick={() => {
                  setIsOpen(true);
                  setModalType("isAdd");
                }}
              >
                Add Event
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {filtersVisible && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  currentPage > 1 ? setCurrentPage(1) : getList(true, 1);
                }}
                autoComplete="off"
              >
                <div className="row filter">
                  <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="name">Event Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={formInputs.name}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <>
                        <label htmlFor="is_active">Status</label>
                        <Select
                          options={status}
                          value={
                            status.find(
                              (el) => el?.value === formInputs.is_active
                            ) || ""
                          }
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: "40px",
                              borderRadius: "8px",
                            }),

                            input: (baseStyles, state) => ({
                              ...baseStyles,
                              paddingLeft: "0px",
                            }),
                          }}
                          classNamePrefix="react-select"
                          tabSelectsValue
                          onChange={(selectedOption) => {
                            setFormInputs({
                              ...formInputs,
                              is_active: selectedOption.value,
                            });
                          }}
                          name="is_active"
                          id="is_active"
                        />
                      </>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12">
                    <div className="mt-4 pt-1">
                      <Form.Check
                        label="Past Event"
                        name="past_event"
                        id="past_event"
                        type="checkbox"
                        onChange={() => {
                          setChecked(!checked);
                          setFormInputs({
                            ...formInputs,
                            past_event: formInputs.past_event ? "" : 1,
                          });
                        }}
                        checked={checked}
                      />
                    </div>
                  </div>

                  <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12 ">
                    <label className="d-none d-lg-block"> &nbsp;</label>
                    <div className="mb-3">
                      <button className="btn btn-primary" type="submit">
                        {isLoading && (
                          <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        Search
                      </button>
                      <button
                        className="btn btn-primary rounded-3 px-3 ms-2"
                        type="button"
                        onClick={(e) => {
                          setFormInputs(initialState);
                          setChecked(false);
                          currentPage > 1
                            ? setCurrentPage(1)
                            : getList(false, 1);
                        }}
                        style={{ background: "#4263eb", fontSize: "14px" }}
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Col>
        </Row>
        <Commontable
          statusChangeKey={"event_id"}
          showStatusEnable={false}
          isLoading={isLoading}
          columns={columns}
          data={list}
          totalPage={totalPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          showNumberOrder={true}
          userRole={userRole}
          context={{
            userRole: { userRole },
            editAccess: [2, 3],
            deleteAccess: [2, 3],
            assignUserAccess: [2, 3],
            onClickActionItemClick: onClickActionItemClick,
          }}
          ActionMenuItem={ActionMenuItem}
          error={error}
        />
        {modalType === "addMember" ? (
          <CommonModal
            isOpen={isOpen}
            className="assign-user-modal"
            title="Event"
            modalType={modalType}
            modalSize="xl"
            setIsOpen={setIsOpen}
            setFormData={setFormData}
            eventId={eventId}
          >
            <AssignUserTable eventId={eventId} />
          </CommonModal>
        ) : (
          <CommonModal
            isOpen={isOpen}
            title="Event"
            modalType={modalType}
            setIsOpen={setIsOpen}
            setFormData={setFormData}
          >
            {modalType === "isDelete" ? (
              <EventCencelForm
                {...formData}
                reason={reason}
                setReason={setReason}
                getEventList={getEventList}
                modalType={modalType}
                setIsOpen={setIsOpen}
                setFormData={setFormData}
              />
            ) : (
              <EventForm
                reason={reason}
                setReason={setReason}
                getEventList={getEventList}
                {...formData}
                modalType={modalType}
                setIsOpen={setIsOpen}
                setFormData={setFormData}
                stateValue={stateValue}
                cityValue={city}
              />
            )}
          </CommonModal>
        )}
      </Layout>
    </>
  );
};
export default EventsList;
