import { inputOnlyNumber } from "../../../utils";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";

function ValidateMobile({ loginFormik, error, changeState }) {
  const location = useLocation();
  const team = location.state?.team || {};
  console.log("eeeeeeeeeeeee", changeState);

  let lastKey = null;
  function inputNumber(e) {
    if (lastKey == "Control" && e.key == "v") {
      lastKey = e.key;
      return true;
    }
    lastKey = e.key;
    let allowKeys = [
      "Tab",
      "Backspace",
      "Delete",
      "Enter",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "Control",
      "Number"
    ];
    if (!($.isNumeric(e.key) || allowKeys.includes(e.key))) e.preventDefault();
  }
  return (
    <>
      <form onSubmit={loginFormik.handleSubmit} autoComplete="off">
        <div className="card py-4 px-3">
          <div className="card-body">
            <p className="mb-2">
              <Link
                to="/login"
                className="text-primary text-decoration-none small">
                &#x2190; Back to Login
              </Link>
            </p>
            {/* <p className="mb-2">
              <Link
                // to="/forgot-pin"
                onClick={changeState}
                
                className="text-primary text-decoration-none small">
                &#x2190; Back to Login
              </Link>
            </p> */}
            <h1 className="mb-4">Reset Your Account PIN</h1>
            <div className="mb-5">
              <label htmlFor="mobile">Mobile No</label>
              <div className="row g-0">
                <div className="col">
                  <input
                    autoFocus
                    type="text"
                    className="form-control"
                    style={{ boxShadow: "none" }}
                    maxLength="12"
                    // value={team?.user_mob}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    // onKeyDown={(event) => {
                    //   const key = event.key;
                    //   const isNumber = /^\d$/.test(key);
                    //   const isAllowedKey =
                    //     key === "Backspace" ||
                    //     key === "Delete" ||
                    //     key === "ArrowLeft" ||
                    //     key === "ArrowRight";
                    //   if (!isNumber && !isAllowedKey) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    onKeyDown={inputNumber}
                    onChange={(event) => {
                      const re = /^[0-9]*$/;

                      if (
                        event.target.value === "" ||
                        re.test(event.target.value)
                      ) {
                        loginFormik.setFieldValue(
                          "guest_type",
                          event.target.value
                        );
                      }
                    }}
                    {...loginFormik.getFieldProps("mobile")}
                  />
                </div>
              </div>
            </div>

            {loginFormik.touched.mobile && loginFormik.errors.mobile ? (
              <div className="alert alert-danger text-center small py-1">
                {loginFormik.errors.mobile}
              </div>
            ) : null}

            {error ? (
              <div className="alert alert-danger text-center small py-1">
                {error}
              </div>
            ) : null}

            <div className="d-grid mb-3">
              <button
                // to={`/login` + loginFormik.team_id}
                // state={{ team: loginFormik }}
                style={{ background: "#FE2F7B", borderRadius: "8px" }}
                className="btn btn-primary"
                type="submit"
                disabled={loginFormik.isSubmitting}
              >
                {loginFormik.isSubmitting && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}
                Next
              </button>
            </div>
            <p className="text-grey text-center">
              We will be Sending an OTP to this Number
            </p>
          </div>
        </div>
      </form>
    </>
  );
}

export default ValidateMobile;
