import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb";
import Commontable from "../../components/Common/CommonTable";
import Layout from "../../components/Common/Layout";
import {
  apiCommunation,
  apiEventList,
  ApiGuestlist,
  apiGuestType,
} from "../../api/master";
import CommonModal from "../../components/Common/Modal";
import { toast } from "react-hot-toast";
import $ from "jquery";
import Filter from "../../images/icons/filter.png";
import Tippy from "@tippyjs/react";
import ActionMenuItem from "../../components/Guests/ActionMenuItem";
import UploadExcelForm from "../../components/Guests/UploadExcelForm";
import GuestForm from "../../components/Guests/GuestForm";
import Select from "react-select";
import GuestCancelForm from "../../components/Guests/GuestCancelForm";

const Guestlist = () => {
  const initialState = {
    name: "",
    mobile: "",
    email: "",
    event_id: "",
    guest_type_id: "",
    reffered_by: "",
    is_active: "",
    past_event: "",
  };
  const [checked, setChecked] = useState(false);
  const [userRole, setUserRole] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState("");
  const [modalType, setModalType] = useState("isAdd");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [formInputs, setFormInputs] = useState(initialState);
  const [isGuestList, setIsGuestList] = useState(false);
  const [filterError, setFilterError] = useState({
    mobile: "",
    email: "",
  });
  const [allEventList, setAllEventList] = useState([]);
  const [activeEventList, setActiveEventList] = useState([]);
  const [allGuestTypeList, setAllGuestTypeList] = useState([]);
  const [activeGuestTypeList, setActiveGuestTypeList] = useState([]);
  const [activeGuestTypeEventList, setActiveGuestTypeEventList] = useState([]);

  const [isFilterCall, setIsFilterCall] = useState(false);
  const [selecetedGuestsIds, setSelectedGuestsIds] = useState([]);
  const [isClear, setIsClear] = useState(false);
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
  const [selectedEventOption, setSelectedEventOption] = useState(null);
  const [selectedGuestTypeOption, setSelectedGuestTypeOption] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [error, setError] = useState("");

  const [city, setCity] = useState([]);
  const status = [
    { value: 0, label: "removed", className: "danger" },
    { value: 1, label: "new", className: "primary" },
    { value: 2, label: "invitation sent", className: "secondary" },
    { value: 3, label: "may be", className: "warning " },
    { value: 4, label: "confirm", className: "success" },
    { value: 5, label: "declined", className: "danger" },
    { value: 6, label: "paid", className: "success" },
    { value: 7, label: "attended", className: "success" },
  ];
  const checkStatus = (id) => {
    let statusObj = status.filter((e) => e.value === id)[0];
    return (
      <span
        className={`text-capitalize badge rounded-pill bg-${statusObj.className} `}
      >
        {statusObj.label}
      </span>
    );
  };
  const columns = [
    { feild: "name", header: "Name" },
    { feild: "mobile", header: "Mobile Number" },
    { feild: "reffered_by", header: "Referred By" },
    { feild: "mst_guest_type.guest_type", header: "Guest Type" },
    { feild: "is_active", header: "Status", cellRenderer: checkStatus },
    { feild: "mst_event.name", header: "Event Name" },
  ];
  const getList = (isFilter = true, pageNo = null) => {
    let json = { paginate: 1, page_no: (pageNo || currentPage) - 1 };
    if (isFilter) {
      json = { ...json, ...formInputs };
    }
    ApiGuestlist(json)
      .then((res) => {
        setCurrentPage(pageNo || currentPage);
        setIsLoading(true);
        setList(res.data.data.list);
        setIsLoading(false);
        setTotalPage(res.data.data.count);
      })
      .catch((err) => setError(err.response.data.data.message));
  };

  const checkEventActive = (event) => {
    if (event.is_active) {
      return event;
    }
  };
  const checkGuestTypeActive = (guestType) => {
    if (guestType.is_active) {
      return guestType;
    }
  };
  const checkGuestTypeAEvent = (guestType) => {
    if (guestType.event_id === currentEvent) {
      return guestType;
    }
  };

  useEffect(() => {
    setIsGuestList(false);
  }, [isGuestList]);

  useEffect(() => {
    if (localStorage.getItem("go-ticket")) {
      setUserRole(
        JSON.parse(localStorage.getItem("go-ticket")).userData.role_id
      );
    }

    if ($("#checkAllInput").length) {
      $("#checkAllInput").prop("checked", false);
      setSelectedGuestsIds([]);
    }
  }, [currentPage]);

  useEffect(() => {
    getEventList();
  }, [formInputs.past_event]);

  useEffect(() => {
    getListType();
  }, [currentEvent, formInputs.past_event]);

  useEffect(() => {
    if (isGuestList === false) getList();
  }, [isGuestList, currentPage]);

  const getListType = async () => {
    let json = { paginate: 0, past_event: formInputs.past_event };
    if (currentEvent) {
      json = { ...json, event_id: currentEvent };
    }

    await apiGuestType(json)
      .then((res) => {
        let allGuestTypeData = res?.data?.data.list;
        let activeGuestTypeData = null;
        if (currentEvent) {
          let guestTypeCurrEvent =
            res?.data?.data.list.filter(checkGuestTypeAEvent);
          activeGuestTypeData = guestTypeCurrEvent.filter(checkGuestTypeActive);
        } else {
          activeGuestTypeData =
            res?.data?.data.list.filter(checkGuestTypeActive);
        }
        let newAllGuestTypeArray = allGuestTypeData.map((item) => {
          return {
            label: item.guest_type,
            value: item.guest_type_id,
          };
        });
        let newActiveGuestTypeArray = activeGuestTypeData.map((item) => {
          return {
            label: item.guest_type,
            value: item.guest_type_id,
          };
        });
        setAllGuestTypeList(newAllGuestTypeArray);
        setActiveGuestTypeList(newActiveGuestTypeArray);
      })
      .catch((err) => {
        setError(err.response.data.data.message);
      });
  };

  const getEventList = async () => {
    let json = { paginate: 0, past_event: formInputs.past_event };
    await apiEventList(json)
      .then((res) => {
        let allEventData = res?.data?.data.list;
        let activeEventData = res?.data?.data.list.filter(checkEventActive);
        let newAllEventArray = allEventData.map((item) => {
          return {
            label: item.name,
            value: item.event_id,
          };
        });
        let newActiveEventArray = activeEventData.map((item) => {
          return {
            label: item.name,
            value: item.event_id,
          };
        });
        setAllEventList(newAllEventArray);
        setActiveEventList(newActiveEventArray);
      })
      .catch((err) => {
        setError(err.response.data.data.message);
      });
  };

  function handleInputs(e) {
    setFormInputs({ ...formInputs, [e.target.id]: e.target.value });
  }

  const handleFilterVisibility = () => {
    if (filtersVisible) {
      setFiltersVisible(false);
      setFormInputs(initialState);
    } else {
      setFiltersVisible(true);
    }
  };

  const commuFunction = async (data, type) => {
    if (type === "2") {
      if (data.filter((sg) => !sg.email).length === 0) {
        await apiCommunation({ selectedGuest: data, type: type })
          .then((res) => {
            toast.success("Notification Sent Successfully.");
          })
          .catch((err) => {
            setError(err.response.data.data.message);
            toast.error(err.response.data.data.message);
          });
      } else {
        toast.error("Email not provided for all guests");
      }
    } else {
      await apiCommunation({ selectedGuest: data, type: type })
        .then((res) => {
          toast.success("Notification Sent Successfully.");
        })
        .catch((err) => {
          setError(err.response.data.data.message);
          toast.error(err.response.data.data.message);
        });
    }
  };

  const onClickActionItemClick = (data, e) => {
    const dataType = e.target.getAttribute("data-type");
    const { id } = e.currentTarget;
    const type = id;
    if (type === "isView") {
      setIsOpen(true);
      setFormData(data);
      setModalType("isView");
    } else if (type === "notification") {
      e.target.closest(".dropdown").click();
      commuFunction([data], dataType);
    } else if (type === "isRemove") {
      setIsOpen(true);
      setFormData(data);
      setModalType("isRemove");
    }
  };

  const onCheckboxSelection = (row, e) => {
    console.log("row", row);
    setSelectedGuestsIds(row);
  };

  const handleFilter = (e) => {
    e.preventDefault();
    // setIsSubmit(true);
    currentPage > 1 ? setCurrentPage(1) : getList(true, 1);
  };
  return (
    <>
      <Layout>
        <Row className="align-items-center">
          <Col lg={3}>
            <Breadcrumb path={["Guests"]} />
          </Col>
          <Col lg={9} className="d-flex flex-wrap justify-content-end">
            <Tippy content="Filter">
              <img
                src={Filter}
                onClick={() => handleFilterVisibility()}
                className="img-fluid me-3"
                alt=""
                role="button"
                style={{ width: "2.5rem" }}
              />
            </Tippy>
            {(userRole === 2 || userRole === 3) && (
              <>
                <Dropdown className="me-2">
                  <Dropdown.Toggle
                    disabled={selecetedGuestsIds.length === 0}
                    variant="primary"
                  >
                    Notify with
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="div"
                      role="button"
                      data-name="notification"
                      data-type="2"
                      onClick={(e) =>
                        commuFunction(
                          selecetedGuestsIds,
                          e.target.getAttribute("data-type")
                        )
                      }
                    >
                      Email
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      role="button"
                      data-name="notification"
                      data-type="1"
                      onClick={(e) =>
                        commuFunction(
                          selecetedGuestsIds,
                          e.target.getAttribute("data-type")
                        )
                      }
                    >
                      Whatsapp
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="div"
                      role="button"
                      data-name="notification"
                      data-type="3"
                      onClick={(e) =>
                        commuFunction(
                          selecetedGuestsIds,
                          e.target.getAttribute("data-type")
                        )
                      }
                    >
                      Send SMS
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  type="button"
                  variant="secondary"
                  className="me-2"
                  onClick={() => {
                    setIsOpen(true);
                    setModalType("uploadExcel");
                  }}
                >
                  Upload Excel
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    setIsOpen(true);
                    setModalType("isAdd");
                  }}
                >
                  Add Guest
                </Button>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            {filtersVisible && (
              <form onSubmit={handleFilter} autoComplete="off">
                <div className="row filter">
                  <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="name">Guest Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={formInputs.name}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="mobile">Mobile Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="mobile"
                        id="mobile"
                        value={formInputs.mobile}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        value={formInputs.email}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="event_id">Select Event</label>
                      <Select
                        options={allEventList}
                        value={selectedEventOption}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "40px",
                            borderRadius: "8px",
                          }),

                          input: (baseStyles, state) => ({
                            ...baseStyles,
                            paddingLeft: "0px",
                          }),
                        }}
                        classNamePrefix="react-select"
                        tabSelectsValue
                        onChange={(selectedOption) => {
                          setFormInputs({
                            ...formInputs,
                            event_id: selectedOption.value,
                          });
                          setSelectedEventOption(selectedOption);
                        }}
                        name="event_id"
                        id="event_id"
                      />
                    </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="guest_type_id">Select Guest Type</label>
                      <Select
                        options={allGuestTypeList}
                        value={selectedGuestTypeOption}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "40px",
                            borderRadius: "8px",
                          }),

                          input: (baseStyles, state) => ({
                            ...baseStyles,
                            paddingLeft: "0px",
                          }),
                        }}
                        classNamePrefix="react-select"
                        tabSelectsValue
                        onChange={(selectedOption) => {
                          setFormInputs({
                            ...formInputs,
                            guest_type_id: selectedOption.value,
                          });
                          setSelectedGuestTypeOption(selectedOption);
                        }}
                        name="guest_type_id"
                        id="guest_type_id"
                      />
                    </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="is_active">Select Status</label>
                      <Select
                        value={selectedStatusOption}
                        options={status}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "40px",
                            borderRadius: "8px",
                          }),

                          input: (baseStyles, state) => ({
                            ...baseStyles,
                            paddingLeft: "0px",
                          }),
                        }}
                        classNamePrefix="react-select"
                        tabSelectsValue
                        onChange={(selectedOption) => {
                          setFormInputs({
                            ...formInputs,
                            is_active: selectedOption.value,
                          });
                          setSelectedStatusOption(selectedOption);
                        }}
                        name="is_active"
                        id="is_active"
                      />
                    </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="reffered_by">Referred By</label>
                      <input
                        type="text"
                        className="form-control"
                        id="reffered_by"
                        value={formInputs.reffered_by}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12">
                    <div className="mt-4 pt-1">
                      <Form.Check
                        label="Past Event"
                        name="past_event"
                        id="past_event"
                        type="checkbox"
                        onChange={() => {
                          setChecked(!checked);
                          setFormInputs({
                            ...formInputs,
                            past_event: formInputs.past_event ? "" : 1,
                          });
                        }}
                        checked={checked}
                      />
                    </div>
                  </div>

                  <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12 ">
                    <label className="d-none d-lg-block"> &nbsp;</label>
                    <div className="mb-3">
                      <button className="btn btn-primary" type="submit">
                        {isLoading && (
                          <span className="spinner-border spinner-border-sm me-2"></span>
                        )}
                        Search
                      </button>
                      <button
                        className="btn btn-primary rounded-3 px-3 ms-2"
                        type="button"
                        onClick={(e) => {
                          setChecked(false);
                          setFormInputs(initialState);
                          setSelectedStatusOption(null);
                          setSelectedEventOption(null);
                          setSelectedGuestTypeOption(null);
                          currentPage > 1
                            ? setCurrentPage(1)
                            : getList(false, 1);
                        }}
                        style={{
                          background: "#4263eb",
                          fontSize: "14px",
                        }}
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Col>
        </Row>
        <Commontable
          isLoading={isLoading}
          columns={columns}
          data={list}
          totalPage={totalPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          userRole={userRole}
          checkboxSelection={true}
          showNumberOrder={true}
          isClear={isClear}
          setIsClear={setIsClear}
          context={{
            userRole: { userRole },
            removedUser: [2, 3],
            communation: [2, 3],
            route: "guest",
            onCheckboxSelection: onCheckboxSelection,
            onClickActionItemClick: onClickActionItemClick,
          }}
          ActionMenuItem={ActionMenuItem}
          error={error}
        />

        <CommonModal
          isOpen={isOpen}
          title="Guest"
          modalType={modalType}
          setIsOpen={setIsOpen}
          setFormData={setFormData}
        >
          {modalType === "uploadExcel" ? (
            <UploadExcelForm
              {...formData}
              modalType={modalType}
              setIsGuestList={setIsGuestList}
              setIsOpen={setIsOpen}
              setFormData={setFormData}
            />
          ) : modalType === "isRemove" ? (
            <GuestCancelForm
              {...formData}
              modalType={modalType}
              setIsOpen={setIsOpen}
              setFormData={setFormData}
              setIsGuestList={setIsGuestList}
            />
          ) : (
            <GuestForm
              activeEventList={activeEventList}
              allEventList={allEventList}
              activeGuestTypeList={activeGuestTypeList}
              allGuestTypeList={allGuestTypeList}
              setIsGuestList={setIsGuestList}
              {...formData}
              modalType={modalType}
              setIsOpen={setIsOpen}
              setFormData={setFormData}
              currentEvent={currentEvent}
              setCurrentEvent={setCurrentEvent}
            />
          )}
        </CommonModal>
      </Layout>
    </>
  );
};
export default Guestlist;
