// import React, { useEffect, useState } from "react";
// import EventCard from "../EventCard/EventCard";
// import { Col, Nav, Row, Tab } from "react-bootstrap";
// import { apiPublishedList } from "../../api/master";

// const EventCardList = () => {
//   const [current, setCurrent] = useState();
//   const [comingSoon, setComingSoon] = useState();
//   const [past, setPast] = useState();
//   const [currloading, setCurrLoading] = useState(true);
//   const [pastLoading, setPastLoading] = useState(true);
//   const [upcomingLoading, setUpcomingLoading] = useState(true);
//   const [error, setError] = useState({
//     current: null,
//     past: null,
//     upcoming: null,
//   });
//   useEffect(() => {
//     setCurrLoading(true);
//     setPastLoading(true);
//     setUpcomingLoading(true);
//     apiPublishedList({ event_type: 1 })
//       .then((res) => {
//         setPast(res.data.data.list);
//         setPastLoading(false);
//         setError({ ...error, past: null });
//       })
//       .catch((err) => {
//         // console.log(err);
//         setError({ ...error, past: err });
//       });

//     apiPublishedList({ event_type: 2 })
//       .then((res) => {
//         setCurrent(res.data.data.list);
//         setCurrLoading(false);
//         setError({ ...error, current: null });
//       })
//       .catch((err) => {
//         // console.log(err);
//         setError({ ...error, current: err });
//       });

//     apiPublishedList({ event_type: 3 })
//       .then((res) => {
//         setComingSoon(res.data.data.list);
//         setUpcomingLoading(false);
//         setError({ ...error, upcoming: null });
//       })
//       .catch((err) => {
//         // console.log(err);
//         setError({ ...error, upcoming: err });
//       });
//   }, []);
//   return (
//     <>
//       <div className="my-3">
//         <h3 className="text-center mb-3">Discover live events</h3>
//         <Tab.Container id="event-cat" defaultActiveKey="current">
//           <Row>
//             <Col>
//               <Nav variant="pills" className="justify-content-center gap-2">
//                 <Nav.Item>
//                   <Nav.Link eventKey="current">Current</Nav.Link>
//                 </Nav.Item>
//                 <Nav.Item>
//                   <Nav.Link eventKey="coming-soon">Coming Soon</Nav.Link>
//                 </Nav.Item>
//                 <Nav.Item>
//                   <Nav.Link eventKey="past">Past</Nav.Link>
//                 </Nav.Item>
//               </Nav>
//             </Col>
//           </Row>
//           <Tab.Content>
//             <Tab.Pane eventKey="current">
//               {" "}
//               <div className="d-flex flex-wrap justify-content-center gap-lg-5">
//                 {currloading
//                   ? "Loading..."
//                   : current
//                   ? current.map((event) => (
//                       <EventCard key={event.event_id} event={event} />
//                     ))
//                   : error.current
//                   ? error.current
//                   : "No events found!"}
//               </div>
//             </Tab.Pane>
//             <Tab.Pane eventKey="coming-soon">
//               <div className="d-flex flex-wrap justify-content-center gap-lg-5">
//                 {upcomingLoading
//                   ? "Loading..."
//                   : comingSoon
//                   ? comingSoon.map((event) => (
//                       <EventCard key={event.event_id} event={event} />
//                     ))
//                   : error.upcoming
//                   ? error.upcoming
//                   : "No events found!"}
//               </div>
//             </Tab.Pane>
//             <Tab.Pane eventKey="past">
//               <div className="d-flex flex-wrap justify-content-center gap-lg-5">
//                 {pastLoading
//                   ? "Loading..."
//                   : past
//                   ? past.map((event) => (
//                       <EventCard
//                         key={event.event_id}
//                         event={event}
//                         is_past={1}
//                       />
//                     ))
//                   : error.past
//                   ? error.past
//                   : "No events found!"}
//               </div>
//             </Tab.Pane>
//           </Tab.Content>
//         </Tab.Container>
//       </div>
//     </>
//   );
// };

// export default EventCardList;



import React, { useEffect, useState } from "react";
import EventCard from "../EventCard/EventCard";
import { apiPublishedList } from "../../api/master";

const EventCardList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      apiPublishedList({ event_type: 2 }), // Current events
      apiPublishedList({ event_type: 3 }), // Coming soon events
    ])
      .then(([currentRes, comingSoonRes]) => {
        const currentEvents = currentRes.data.data.list;
        const comingSoonEvents = comingSoonRes.data.data.list;
        setEvents([...currentEvents, ...comingSoonEvents]);
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="my-3">
        <h3 className="text-center mb-3">Discover Events</h3>
        <div className="d-flex flex-wrap justify-content-center gap-lg-5">
          {loading
            ? "Loading..."
            : events.length > 0
            ? events.map((event) => (
                <EventCard key={event.event_id} event={event} />
              ))
            : error
            ? error.message
            : "No events found!"}
        </div>
      </div>
    </>
  );
};

export default EventCardList;
