import { useState, useEffect } from "react";
import "./style.css";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import UserSchema from "../../../validations/UserSchema";
import cancel from "../../../images/cancel.svg";
import { inputOnlyNumber } from "../../../utils";
import { apiEditUser } from "../../../api/master";
import config from "../../../config";

function UserEdit(props) {
  const { companyListMap } = props;
  const [user, setUser] = useState({});
  useEffect(function () {
    if (localStorage.getItem("go-ticket")) {
      setUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
    }
  }, []);

  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [roleSelect, setRoleSelect] = useState(Number(props.id.role_id));

  const UserFormik = useFormik({
    initialValues: {
      user_id: props.id?.user_id,
      user_name: props.id?.user_name,
      user_mob: props.id?.user_mob,
      role_id: props.id?.role_id,
      comp_id: props.id?.mst_comp?.comp_id,
      pin: props.id?.pin,
    },
    validationSchema: UserSchema.validateUserEdit(),
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: async (values) => {
      let input = {
        user_id: Number(values.user_id),
        user_name: values.user_name.trim(),
        user_mob: Number(values.user_mob),
        role_id: Number(values.role_id),
        comp_id: Number(values.comp_id),
        pin: Number(values.pin),
      };
      setIsSubmit(true);
      await apiEditUser(input)
        .then((data) => {
          setSuccess(data.data.message);
          setError("");
          props.list();
        })
        .catch((err) => {
          setError(err.response.data.data.message);
          setSuccess("");
        })
        .finally((result) => {
          setIsSubmit(false);
          setTimeout(() => {
            setSuccess("");
            setError("");
          }, 5000);
        });
    },
  });

  useEffect(() => {
    setRoleSelect(Number(UserFormik?.values?.role_id));
  }, [UserFormik?.values?.role_id]);

  // useEffect(function () {}, [success, error]);

  return (
    <>
      <Modal.Header closeButton>
        <h4 style={{ marginLeft: "12rem", height: "4px" }} id="modalhead">
          Edit User
        </h4>
      </Modal.Header>
      <div className="container-fluid p-4" id="userAdd">
        <form
          className="row gx-5 gy-2"
          onSubmit={UserFormik.handleSubmit}
          autoComplete="off"
        >
          <div className="col-md-6">
            <label htmlFor="user_name">
              User Name <span className="text-danger">*</span>
            </label>
            <input
              maxLength={50}
              type="text"
              className="form-control"
              id="user_name"
              {...UserFormik.getFieldProps("user_name")}
            />
            {UserFormik.errors.user_name ? (
              <div className="text-danger small">
                {UserFormik.errors.user_name}
              </div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label htmlFor="user_mob">
              Mobile No <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              maxLength={10}
              onKeyDown={inputOnlyNumber}
              className="form-control"
              id="user_mob"
              {...UserFormik.getFieldProps("user_mob")}
              // disabled
            />
            {UserFormik.errors.user_mob ? (
              <div className="text-danger small">
                {UserFormik.errors.user_mob}
              </div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label htmlFor="role_id">
              User Role <span className="text-danger">*</span>
            </label>
            <select
              className="form-select"
              id="role_id"
              {...UserFormik.getFieldProps("role_id")}
            >
              <option value="" hidden>
                Select...
              </option>

              {config.roleListIng.map((list, index) => {
                if (
                  user?.role_id == 1
                    ? // || user?.role_id == 3
                      user?.role_id < list.role_id
                    : user?.role_id <= list.role_id
                ) {
                  return <option value={list.id}>{list.title}</option>;
                }
              })}
            </select>
            {UserFormik.errors.role_id ? (
              <div className="text-danger small">
                {UserFormik.errors.role_id}
              </div>
            ) : null}
          </div>

          {user.role_id === 1 ? (
            <div className="col-md-6">
              <label htmlFor="comp_id">
                Company list
                {/* <span className="text-danger">*</span> */}
              </label>
              <select
                className="form-select"
                id="comp_id"
                {...UserFormik.getFieldProps("comp_id")}
              >
                <option value="" hidden>
                  Select...
                </option>
                {companyListMap.map((list) => {
                  return <option value={list.value}>{list.label}</option>;
                })}
              </select>
              {UserFormik.errors.comp_id ? (
                <div className="text-danger small">
                  {UserFormik.errors.comp_id}
                </div>
              ) : null}
            </div>
          ) : (
            ""
          )}
          <div className="col-md-6">
            <label htmlFor="pin">
              Pin <span className="text-danger">*</span>
            </label>
            <input
              maxLength={4}
              type="text"
              onKeyDown={inputOnlyNumber}
              className="form-control"
              placeholder="4 Digit Pin"
              id="pin"
              {...UserFormik.getFieldProps("pin")}
            />
            {UserFormik.errors.pin ? (
              <div className="text-danger small">{UserFormik.errors.pin}</div>
            ) : null}
          </div>
          <div className="col-12 mt-4">
            {(error || success) && (
              <div
                className={`alert alert-${
                  error ? "danger" : "success"
                } py-2 small`}
                role="alert"
              >
                {error || success || ""}
              </div>
            )}
            <div style={{ float: "right" }}>
              <button
                className="branchModalCancel"
                onClick={props.hide}
                src={cancel}
                type="button"
              >
                Cancel
              </button>
              <button type="submit" disabled={isSubmit}>
                {isSubmit && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default UserEdit;
