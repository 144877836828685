import Sidebar from "../../components/partials/Sidebar";
import Header from "../../components/partials/Header";
import Breadcrumb from "../../components/Breadcrumb";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import filter from "../../images/icons/filter.png";
import Pagination from "../../components/Pagination";
import editIcon from "../../images/icons/edit.png";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Modal from "react-bootstrap/Modal";
import view from "../../images/view.png";
import UserAdd from "./Add";
import UserEdit from "./Edit";
import Swal from "sweetalert2";
import iteamView from "../../images/icons/iteamAdd.png";
import {
  apiCancelTeam,
  apiCancelUser,
  apiEventList,
  apiListCompany,
  apiListUser,
  apiTeamList,
} from "../../api/master";
import config from "../../config";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { createSubstring } from "../../utils";
import Select from "react-select";
import TeamAdd from "./Add";
import TeamEdit from "./Edit";

function TeamList() {
  const initialState = {
    team_name: "",
    event_id: "",
    is_active: "",
    past_event: 0,
  };

  const [user, setUser] = useState({});

  useEffect(function () {
    if (localStorage.getItem("go-ticket")) {
      setUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
    }
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showId, setShowId] = useState(0);
  const [editId, setEditId] = useState("");
  const [list, setList] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [roleList, setRoleList] = useState([]);

  const [formInputs, setFormInputs] = useState(initialState);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [filterError, setFilterError] = useState();
  const [isFilterCall, setIsFilterCall] = useState(false);

  const [eventList, setEventList] = useState([]);
  const [selectedEventOption, setSelectedEventOption] = useState(null);
  const [checked, setChecked] = useState(false);

  const status = [
    { value: false, label: "In-Active", className: "danger" },
    { value: true, label: "Active", className: "success" },
  ];

  function changeStatus(team_id, status) {
    let target = window.event.target;
    let oldText = target.innerText;
    target.innerText = "Updating...";
    apiCancelTeam({ team_id: team_id, is_active: status })
      .then((data) => {
        setError("");
      })
      .catch((err) => {
        setError(err?.response?.data?.data?.message);
        setTimeout(function () {
          setError("");
        }, 3000);
      })
      .finally(() => {
        target.innerText = oldText;
        getList(filtersVisible);
      });
  }

  async function getList(isFilter = true, pageNo = null) {
    let json = { paginate: 1, page_no: (pageNo || currentPage) - 1 };
    if (isFilter) {
      json = { ...json, ...formInputs };
    }
    await apiTeamList(json)
      .then((data) => {
        setCurrentPage(pageNo || currentPage);
        setIsSubmit(false);
        setList(data?.data?.data?.list);
        setIsLoading(false);
        setTotalPage(data?.data?.data?.count);
      })
      .catch(function (err) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.message,
          confirmButtonText: "OK",
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          focusConfirm: true,
        });
        setIsLoading(false);
      });
  }

  async function getEventList() {
    let payload =
      showId === 3
        ? {
            is_active: true,
            req_team: 1,
          }
        : {
            is_active: true,
          };
    await apiEventList(payload).then((result) => {
      let eventData = result?.data?.data.list;
      let newEventArray = eventData.map((item) => {
        return {
          label: item.name,
          value: item.event_id,
        };
      });
      setEventList(newEventArray);
    });
  }

  useEffect(() => {
    getEventList();
  }, [showId]);

  useEffect(
    function () {
      if (!isFilterCall) {
        getList(true);
      }
    },
    [currentPage]
  );

  function handleInputs(e) {
    setFormInputs({ ...formInputs, [e.target.id]: e.target.value });
  }

  function handleFilterVisibility() {
    if (filtersVisible) {
      setFiltersVisible(false);
      setFormInputs(initialState);
    } else {
      setFiltersVisible(true);
    }
  }

  return (
    <>
      <div className="d-flex min-vh-100" style={{ background: "#DAE5FF" }}>
        <Sidebar />
        <div className="content m-1 ms-1 p-4">
          <Header />

          <main className="bg-white rounded-4" id="user">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col">
                  <Breadcrumb path={["Team"]} />
                </div>
                <div className="col text-end">
                  <Tippy content="Filter">
                    <img
                      src={filter}
                      onClick={() => handleFilterVisibility()}
                      className="img-fluid me-3"
                      alt=""
                      role="button"
                      style={{ width: "2.5rem" }}
                    />
                  </Tippy>

                  <Link
                    onClick={(v) => {
                      handleShow();
                      setShowId(1);
                    }}
                    className="btn btn-primary px-4 py-2"
                  >
                    Add Team
                  </Link>
                </div>
              </div>
              {filtersVisible && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // setIsSubmit(true);
                    currentPage > 1 ? setCurrentPage(1) : getList(true, 1);
                  }}
                  autoComplete="off"
                >
                  <div className="row filter">
                    <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="team_name">Team Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="team_name"
                          value={formInputs.team_name}
                          onChange={handleInputs}
                        />
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-2 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="event_id">Select Event</label>
                        <Select
                          options={eventList}
                          value={selectedEventOption}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: "40px",
                              borderRadius: "8px",
                            }),

                            input: (baseStyles, state) => ({
                              ...baseStyles,
                              paddingLeft: "0px",
                            }),
                          }}
                          classNamePrefix="react-select"
                          tabSelectsValue
                          onChange={(selectedOption) => {
                            setFormInputs({
                              ...formInputs,
                              event_id: selectedOption.value,
                            });
                            setSelectedEventOption(selectedOption);
                          }}
                          name="event_id"
                          id="event_id"
                        />
                      </div>
                    </div>

                    {/* <div className="col-xxl-3 col-xl-2 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="status">Status</label>
                        <select
                          className="form-select"
                          id="is_active"
                          name="is_active"
                          value={formInputs.is_active}
                          onChange={(e) =>
                            setFormInputs({
                              ...formInputs,
                              is_active: e.target.value,
                            })
                          }
                        >
                          {[
                            { id: "", name: "All" },
                            { id: "1", name: "Active" },
                            {
                              id: "0",
                              name: "In-Active",
                            },
                          ].map((v, i) => (
                            <option key={i} value={v.id}>
                              {v.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="is_active">Select Status</label>
                        <Select
                          value={
                            status.find(
                              (el) => el?.value === formInputs.is_active
                            ) || ""
                          }
                          options={status}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: "40px",
                              borderRadius: "8px",
                            }),

                            input: (baseStyles, state) => ({
                              ...baseStyles,
                              paddingLeft: "0px",
                            }),
                          }}
                          classNamePrefix="react-select"
                          tabSelectsValue
                          onChange={(selectedOption) => {
                            setFormInputs({
                              ...formInputs,
                              is_active: selectedOption.value,
                            });
                          }}
                          name="is_active"
                          id="is_active"
                        />
                      </div>
                    </div>

                    {/* <div className="col-xxl-3 col-xl-2 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="past_event">Past</label>
                        <select
                          className="form-select"
                          id="past_event"
                          name="past_event"
                          value={formInputs.past_event}
                          onChange={(e) =>
                            setFormInputs({
                              ...formInputs,
                              past_event: parseInt(e.target.value),
                            })
                          }
                        >
                          {[
                            { id: 0, name: "No" },
                            {
                              id: 1,
                              name: "Yes",
                            },
                          ].map((v, i) => (
                            <option key={i} value={v.id}>
                              {v.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12">
                      <div className="mt-4 pt-1">
                        <Form.Check
                          label="Past Team"
                          name="past_event"
                          id="past_event"
                          type="checkbox"
                          onChange={() => {
                            setChecked(!checked);
                            setFormInputs({
                              ...formInputs,
                              past_event: formInputs.past_event ? "" : 1,
                            });
                          }}
                          checked={checked}
                        />
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12 ">
                      <label className="d-none d-lg-block"> &nbsp;</label>
                      <div className="mb-3">
                        <button className="btn btn-primary" type="submit">
                          {isLoading && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                          )}
                          Search
                        </button>
                        <button
                          className="btn btn-primary rounded-3 px-3 ms-2"
                          type="button"
                          onClick={(e) => {
                            setFormInputs(initialState);
                            setChecked(false);
                            setSelectedEventOption(null);
                            currentPage > 1
                              ? setCurrentPage(1)
                              : getList(false, 1);
                          }}
                          style={{ background: "#4263eb", fontSize: "14px" }}
                        >
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              {error && (
                <div className="row mt-3">
                  <div className="col">
                    <div
                      className={`alert alert-danger py-1 small`}
                      role="alert"
                    >
                      {error || ""}
                    </div>
                  </div>
                </div>
              )}

              {/* table */}
              <div className="row mb-4">
                <div className="col">
                  <div className="table-responsive">
                    <table
                      className={`table ${error ? "my-1" : "mt-4"} rounded`}
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Team Name</th>
                          <th>Size</th>
                          <th>Colour</th>
                          <th>Event</th>
                          <th>Leader Name</th>
                          <th>Leader Mobile</th>
                          <th>Status</th>
                          <th>Actions</th>
                          <th>Pending</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(isLoading || list.length === 0) && (
                          <tr>
                            <td colSpan="12" className="text-center">
                              {isLoading ? "Loading..." : "No Records"}
                            </td>
                          </tr>
                        )}
                        {list.map((v, i) => {
                          // if(v.role_id !== 1){
                          return (
                            <tr key={`ul_` + i}>
                              <td>{currentPage * 10 - 10 + i + 1}</td>
                              <td>{v?.team_name}</td>
                              <td>{v?.slots || 0}</td>
                              <td>{v?.team_color}</td>

                              {/* <td>
                                <div
                                  style={{
                                    width: "60px",
                                    height: "20px",
                                    backgroundColor: v?.team_color || "white",
                                    borderRadius: "5px",
                                  }}
                                ></div>
                              </td> */}
                              <td>
                                {v?.mst_event?.name.length > 20 ? (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip_` + i}>
                                        {v?.mst_event?.name}
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      {createSubstring(v?.mst_event?.name)}{" "}
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  v?.mst_event?.name
                                )}
                              </td>
                              <td>
                                {v?.manager_name.length > 20 ? (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip_` + i}>
                                        {v?.manager_name}
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      {createSubstring(v?.manager_name)}{" "}
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  v?.manager_name
                                )}
                              </td>
                              <td>{v?.mobile}</td>

                              <td>
                                {/* {v.user_id !== user.user_id ? ( */}
                                <Tippy content="Change Status">
                                  {v.is_active ? (
                                    <span
                                      className="badge text-bg-success"
                                      role="button"
                                      onClick={() =>
                                        changeStatus(v?.team_id, 0)
                                      }
                                    >
                                      Active
                                    </span>
                                  ) : (
                                    <span
                                      className="badge text-bg-danger"
                                      role="button"
                                      onClick={() =>
                                        changeStatus(v?.team_id, 1)
                                      }
                                    >
                                      In-Active
                                    </span>
                                  )}
                                </Tippy>
                                {/* ) : (
                                  ""
                                )} */}
                              </td>

                              <td>
                                <Tippy content="View" trigger="mouseenter">
                                  <a
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setEditId(v);
                                      handleShow();
                                      setShowId(3);
                                    }}
                                  >
                                    <img
                                      src={view}
                                      className="img-fluid me-2"
                                      alt=""
                                    />
                                  </a>
                                </Tippy>
                                {v.user_id !== user.user_id ? (
                                  <Tippy content="Edit" trigger="mouseenter">
                                    <Link
                                      // to={`/user/edit/` + v.user_id}
                                      onClick={() => {
                                        setEditId(v);
                                        handleShow();
                                        setShowId(2);
                                      }}
                                      className={`link-info ${
                                        v.is_active ? "" : "d-none"
                                      }`}
                                    >
                                      <img
                                        src={editIcon}
                                        className="img-fluid me-2"
                                        alt=""
                                      />
                                    </Link>
                                  </Tippy>
                                ) : (
                                  ""
                                )}
                                <Tippy content="Members" trigger="mouseenter">
                                  <Link
                                    to={`/team/review/` + v.team_id}
                                    state={{ team: v }}
                                    className={`link-info ${
                                      v.is_active ? "" : "d-none"
                                    }`}
                                  >
                                    <img
                                      src={iteamView}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </Link>
                                </Tippy>
                              </td>
                              <td>{v?.total_pending || "0"}</td>
                            </tr>
                          );
                          // }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  {/* <Pagination
                    // nPages={Math.ceil(list.length / 10)}
                    nPages={Math.ceil(totalPage / 10)}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  /> */}

                  <Pagination
                    // nPages={Math.ceil(list.length / 10)}
                    nPages={Math.ceil(totalPage / 10)}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                backdrop="static"
              >
                {showId == 1 ? (
                  <TeamAdd
                    id={editId}
                    list={getList}
                    hide={handleClose}
                    roles={roleList}
                    // companyListMap={companyListMap}
                  />
                ) : showId == 2 ? (
                  <TeamEdit
                    showId={showId}
                    id={editId}
                    list={getList}
                    hide={handleClose}
                    roles={roleList}
                    readOnly={false}
                    // companyListMap={companyListMap}
                  />
                ) : showId == 3 ? (
                  <TeamEdit
                    showId={showId}
                    id={editId}
                    list={getList}
                    hide={handleClose}
                    roles={roleList}
                    readOnly={true}
                    // companyListMap={companyListMap}
                  />
                ) : null}
              </Modal>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default TeamList;
