import { useEffect, useState } from "react";
import "./style.css";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import UserSchema from "../../../validations/UserSchema";
import { inputOnlyNumber } from "../../../utils";
import { apiCreateUser } from "../../../api/master";
import Select from "react-select";
import config from "../../../config";

function UserAdd(props) {
  const { companyListMap } = props;
  const [user, setUser] = useState({});

  useEffect(function () {
    if (localStorage.getItem("go-ticket")) {
      setUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
    }
  }, []);

  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [selectedcode, setSelectedCode] = useState("");
  const [shownew, setShownew] = useState(false);
  const [roleSelect, setRoleSelect] = useState();

  const UserFormik = useFormik({
    initialValues: {
      user_name: "",
      user_mob: "",
      role_id: "",
      comp_id: "",
      pin: "",
    },

    validationSchema:
      user.role_id === 1
        ? UserSchema.validateUserAddMaster
        : UserSchema.validateUserAdd(),
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: async (values) => {
      setIsSubmit(true);
      const decod = {
        user_name: values.user_name.trim(),
        user_mob: Number(values.user_mob),
        comp_id: Number(values.comp_id),
        role_id: Number(values.role_id),
        pin: Number(values.pin),
      };
      await apiCreateUser(decod)
        .then((data) => {
          setSuccess(data.data.message);
          setError("");
          setShownew(true);
          props.list();
        })
        .catch((err) => {
          setError(err.response.data.data.message);
          setSuccess("");
        })
        .finally((result) => {
          setIsSubmit(false);
        });
    },
    onReset: async (values) => {
      setSelectedCode(null);
      setShownew(false);
      setSuccess("");
    },
  });

  useEffect(() => {
    setRoleSelect(Number(UserFormik?.values?.role_id));
  }, [UserFormik?.values?.role_id]);

  return (
    <>
      <Modal.Header closeButton>
        <h4 style={{ marginLeft: "12rem", height: "4px" }} id="modalhead">
          Add User
        </h4>
      </Modal.Header>

      <div className="container-fluid p-4" id="userAdd">
        <form
          className="row gx-5 gy-2"
          onSubmit={UserFormik.handleSubmit}
          onReset={UserFormik.handleReset}
          autoComplete="off"
        >
          <div className="col-md-6">
            <label htmlFor="user_name">
              User Name <span className="text-danger">*</span>
            </label>
            <input
              maxLength={50}
              type="text"
              className="form-control"
              id="user_name"
              {...UserFormik.getFieldProps("user_name")}
            />
            {UserFormik.errors.user_name ? (
              <div className="text-danger small">
                {UserFormik.errors.user_name}
              </div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label htmlFor="mobile">
              Mobile No <span className="text-danger">*</span>
            </label>
            <input
              maxLength={10}
              type="text"
              onKeyDown={inputOnlyNumber}
              className="form-control"
              id="mobile"
              {...UserFormik.getFieldProps("user_mob")}
            />
            {UserFormik.errors.user_mob ? (
              <div className="text-danger small">
                {UserFormik.errors.user_mob}
              </div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label htmlFor="role_id">
              User Role <span className="text-danger">*</span>
            </label>
            <select
              type="text"
              className="form-select"
              id="role_id"
              onSelect={(value) => {}}
              {...UserFormik.getFieldProps("role_id")}
            >
              <option value="" hidden>
                Select...
              </option>
              {config.roleListIng.map((list, index) => {
                if (
                  user?.role_id == 1
                    ? // || user?.role_id == 3
                      user?.role_id < list.role_id
                    : user?.role_id <= list.role_id
                ) {
                  return <option value={list.id}>{list.title}</option>;
                }
              })}
            </select>
            {UserFormik.errors.role_id ? (
              <div className="text-danger small">
                {UserFormik.errors.role_id}
              </div>
            ) : null}
          </div>
          {user.role_id === 1 && (
            <div className="col-md-6">
              <label htmlFor="comp_id">
                Company list <span className="text-danger">*</span>
                {/* <span className="text-danger">*</span> */}
              </label>

              <select
                className="form-select"
                id="comp_id"
                disabled={companyListMap.length === 0 ? true : false}
                onChange={(selectedOption) => {
                  UserFormik.setFieldValue("comp_id", selectedOption.value);
                }}
                {...UserFormik.getFieldProps("comp_id")}
              >
                <option value="" hidden>
                  Select...
                </option>
                {companyListMap
                  .filter((e) => e.is_active === true)
                  .map((list) => {
                    return <option value={list.value}>{list.label}</option>;
                  })}
              </select>
              {UserFormik.errors.comp_id ? (
                <div className="text-danger small">
                  {UserFormik.errors.comp_id}
                </div>
              ) : null}
            </div>
          )}
          <div className="col-md-6">
            <label htmlFor="pin">
              Pin <span className="text-danger">*</span>
            </label>
            <input
              maxLength={4}
              type="text"
              placeholder="4 Digit Pin"
              onKeyDown={inputOnlyNumber}
              className="form-control"
              id="pin"
              {...UserFormik.getFieldProps("pin")}
            />
            {UserFormik.errors.pin ? (
              <div className="text-danger small">{UserFormik.errors.pin}</div>
            ) : null}
          </div>
          <div className="col-12 mt-4">
            {(error || success) && (
              <div
                className={`alert alert-${
                  error ? "danger" : "success"
                } py-2 small`}
                role="alert"
              >
                {error || success || ""}
              </div>
            )}
            <div style={{ float: "right" }}>
              <button
                className="branchModalCancel"
                onClick={props.hide}
                type="button"
              >
                Cancel
              </button>
              {shownew ? (
                <button
                  style={{ backgroundColor: "#4263EB", color: "#fff" }}
                  // onClick={() => {
                  //   setSelectedCode(null);
                  //   UserFormik.resetForm();
                  //   UserFormik.setErrors({})
                  //   setShownew(false);
                  //   setSuccess("");
                  // }}
                  id="createNew"
                  type="reset"
                >
                  Add New
                </button>
              ) : (
                <button type="submit" disabled={isSubmit}>
                  {isSubmit && (
                    <span className="spinner-border spinner-border-sm me-2"></span>
                  )}
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default UserAdd;
