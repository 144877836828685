import "./style.css";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

function CompanyViews(props) {
  const [logo, setLogo] = useState("");
  const [file, setFile] = useState(null);

  return (
    <>
      <Modal.Header closeButton>
        <h4 style={{ marginLeft: "12rem", height: "4px" }} id="modalhead">
          View Company
        </h4>
      </Modal.Header>
      <div className="container-fluid p-4" id="buyerAdd">
        <div className="row gy-3">
          <div className="col-md-6">
            <label htmlFor="buyer_contact">Company Name</label>
            <input
              disabled
              type="text"
              className="form-control"
              id="comp_name"
              maxLength={100}
              value={props.id?.comp_name}
            />
          </div>
          {props?.id?.comp_logo && (
            <div className="col-md-6">
              <label htmlFor="comp_logo">Company logo</label>
              <div className="uploadBox mt-1">
                <>
                  <label>
                    <div className="previewBox border">
                      <img src={props?.id?.comp_logo} alt="" />
                    </div>
                  </label>
                </>
              </div>
            </div>
          )}

          <div className="col-12 mt-4">
            <button
              className="branchModalCancel"
              onClick={props.hide}
              style={{ float: "right" }}
              to="/branch"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyViews;
