import appConfig from "../config";
import axios from "axios";
import { AxiosRequestConfig, Method } from "axios";

const axiosInstance = axios.create({
  timeout: 6000,
});

export const request = (method, url, data, config) => {
  // const prefix = '/api'
  const prefix = appConfig.apiUrl;

  url = prefix + url;

  // if (method === 'post') {
  return axiosInstance.post(url, data, config);
  // } else {
  //   return axiosInstance.get(url, {
  //     params: data,
  //     ...config,
  //   });
  // }
};
