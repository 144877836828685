import moment from "moment";

const Input = (props) => {
    const data = props.data;
    const error = props.error;
    const state = props.state;

    return (
        <>
            <div className="col-md-3">
                <label htmlFor={data?.f_name}>{data?.f_lable || ""} {data?.extra?.required && <span className="text-danger">*</span>}</label>
                <input className={data?.class || "form-control"} type={data?.f_type} name={data?.f_name} id={data?.f_name}
                       max={data?.f_type == 'date' ? moment().format('YYYY-MM-DD') : ''}
                       value={state?.[data?.f_name]}
                       onChange={e => props?.handleInput(e)}/>
                <span className="text-danger">{error}</span>
            </div>
        </>
    )
}

export default Input;