import config from "../../config";
import $ from "jquery";
import Sidebar from "../../components/partials/Sidebar";
import Header from "../../components/partials/Header";
import Breadcrumb from "../../components/Breadcrumb";
import { useContext, useEffect, useState } from "react";
import Pagination from "./components/Pagination";
import Table from "./components/Table";
import Filter from "./components/Filter";
import "./style.css";
import Tippy from "@tippyjs/react";
import filterIcon from "../../images/icons/filter.png";
import ExcelDownload from "./components/ExcelDownload";
import { useParams } from "react-router-dom";
import { getRequiredFilters, validateFilters } from "./utils";
import { ReportContext } from "../../App";

const ReportList = () => {
  const { reportId } = useParams();
  // const [reportId, setReportId] = useState(initialReportId);
  // const reportList = useContext(ReportContext);

  const [isMounted, setIsMounted] = useState(false);
  const [showFilter, setShowFilter] = useState(true);

  const [title, setTitle] = useState("");
  const [filters, setFilters] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [columns, setColumns] = useState([]);
  const [headers, setHeaders] = useState([]);

  console.log("filterData", filterData);

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isclear, setIsclear] = useState(false);

  const getReportHeader = async (data) => {
    let payload = { paginate: 0, ...data };

    await $.post(
      `${config.apiUrl}/reports/listReportHeader`,
      JSON.stringify(payload)
    )
      .done(function (res) {
        let reportData = res?.data?.list;
        reportData = reportData.find((ele) => ele.report_id == reportId);

        setTitle(() => reportData?.title || "No Report");
        setColumns(
          () => reportData?.columns?.split(",")?.map((v) => v?.trim()) || []
        );
        setHeaders(
          () => reportData?.headers?.split(",")?.map((v) => v?.trim()) || []
        );
        setFilters(() => reportData?.filters || []);
        setFilterData(() => {
          let result = {};
          reportData?.filters?.forEach((filter) => {
            result = { ...result, [filter?.f_name]: "" };
          });
          return result;
        });
        setErrors(() => {
          let result = {};
          reportData?.filters?.forEach((filter) => {
            result = { ...result, [filter?.f_name]: "" };
          });
          return result;
        });
      })
      .catch(function (err) {
        console.log(err.responseJSON);
      });
  };

  const getReportData = async (data) => {
    let payload = { report_id: reportId, page_no: currentPage - 1, ...data };

    let reqFields = getRequiredFilters(filters);
    let filterValidation = validateFilters(reqFields, filterData);
    if (!filterValidation?.status) {
      setErrors((prevState) => ({
        ...prevState,
        [filterValidation?.data]: `This field is required.`,
      }));
      return false;
    } else {
      setErrors(() => {
        let result = {};
        filters?.forEach((filter) => {
          result = { ...result, [filter?.f_name]: "" };
        });
        return result;
      });
    }

    await $.post(
      `${config.apiUrl}/reports/listReportData`,
      JSON.stringify(payload)
    )
      .done(function (res) {
        let reportData = res?.data;

        setTotalRecords(() => reportData?.count || 0);
        setRows(() => reportData?.list);
      })
      .catch(function (err) {
        console.log(err.responseJSON);

        setTotalRecords(() => 0);
        setRows(() => []);
      });
  };

  const searchReportData = async () => {
    currentPage == 1 ? getReportData(filterData) : setCurrentPage(() => 1);
  };

  const clearReportData = async () => {
    setIsclear(!isclear);
    setFilterData(() => {
      let result = {};
      filters?.forEach((filter) => {
        result = { ...result, [filter?.f_name]: "" };
      });
      return result;
    });
    setErrors(() => {
      let result = {};
      filters?.forEach((filter) => {
        result = { ...result, [filter?.f_name]: "" };
      });
      return result;
    });

    if (validateFilters(getRequiredFilters(filters), filterData)?.status) {
      currentPage == 1
        ? getReportData({ report_id: reportId, page_no: 0 })
        : setCurrentPage(() => 1);
    }
  };

  useEffect(() => {
    getReportHeader({ report_id: Number(reportId) }).then(() =>
      setIsMounted(() => true)
    );
  }, [reportId]);
  useEffect(() => {
    setRows(() => []);
  }, [reportId]);

  useEffect(() => {
    setFilterData(() => {
      let result = {};
      filters?.forEach((filter) => {
        result = { ...result, [filter?.f_name]: "" };
      });
      return result;
    });
  }, [reportId]);

  useEffect(() => {
    let isValid = validateFilters(
      getRequiredFilters(filters),
      filterData
    )?.status;

    if (isMounted && isValid) {
      getReportData(filterData);
    }
  }, [isMounted, currentPage]);

  return (
    <>
      <div className="d-flex min-vh-100" style={{ background: "#DAE5FF" }}>
        <Sidebar />
        <div className="content m-1 p-4">
          <Header />
          <main className="bg-white rounded-4" id="reports">
            <div className="container-fluid p-4">
              <div className="row">
                <div className="col-md">
                  <Breadcrumb path={["Reports", title]} />
                </div>

                <div className="col-md text-end">
                  <Tippy content="Filter" trigger="mouseenter">
                    <img
                      src={filterIcon}
                      className="img-fluid mx-2 icon"
                      width="45px"
                      height="45px"
                      alt=""
                      role="button"
                      onClick={(e) => setShowFilter(!showFilter)}
                    />
                  </Tippy>

                  <ExcelDownload
                    link={`${config.apiUrl}/reports/listReportDownload`}
                    filters={{
                      ...filterData,
                      report_id: reportId,
                      paginate: 0,
                    }}
                    filename={title}
                  />
                </div>
              </div>

              <Filter
                show={showFilter}
                filters={filters}
                errors={errors}
                filterData={filterData}
                setFilterData={setFilterData}
                isclear={isclear}
                handler={{ searchReportData, clearReportData }}
                reportId={reportId}
              />

              <Table columns={columns} headers={headers} rows={rows} />

              <Pagination
                nPages={Math.ceil(totalRecords / 10)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default ReportList;
