import Sidebar from "../../../components/partials/Sidebar";
import Header from "../../../components/partials/Header";
import Breadcrumb from "../../../components/Breadcrumb";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import filter from "../../../images/icons/filter.png";
import Pagination from "../../../components/Pagination";
import editIcon from "../../../images/icons/edit.png";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Modal from "react-bootstrap/Modal";
import view from "../../../images/view.png";
import UserAdd from "./Add";
import UserEdit from "./Edit";
import UserEditView from "./view";
import Swal from "sweetalert2";
import {
  apiCancelUser,
  apiListCompany,
  apiListUser,
} from "../../../api/master";
import config from "../../../config";
import Select from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function UserList() {
  const initialState = {
    user_name: "",
    user_mob: "",
    is_active: "",
  };

  const [user, setUser] = useState({});

  useEffect(function () {
    if (localStorage.getItem("go-ticket")) {
      setUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
    }
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showId, setShowId] = useState(0);
  const [editId, setEditId] = useState("");
  const [list, setList] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [roleList, setRoleList] = useState([]);

  const [formInputs, setFormInputs] = useState(initialState);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [filterError, setFilterError] = useState();
  const [isFilterCall, setIsFilterCall] = useState(false);

  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
  const status = [
    { value: false, label: "In-Active", className: "danger" },
    { value: true, label: "Active", className: "success" },
  ];

  function changeStatus(user_id, status) {
    let target = window.event.target;
    let oldText = target.innerText;
    target.innerText = "Updating...";
    apiCancelUser({ user_id: user_id, is_active: status })
      .then((data) => {
        setError("");
      })
      .catch((err) => {
        setError(err.response.data.data.message);
        setTimeout(function () {
          setError("");
        }, 3000);
      })
      .finally(() => {
        target.innerText = oldText;
        getList(filtersVisible);
      });
  }

  async function getList(isFilter = true, pageNo = null) {
    let json = { paginate: 1, page_no: (pageNo || currentPage) - 1 };
    if (isFilter) {
      json = { ...json, ...formInputs };
    }
    await apiListUser(json)
      .then((data) => {
        setCurrentPage(pageNo || currentPage);
        setIsSubmit(false);
        setList(data.data.data.list);
        setIsLoading(false);
        setTotalPage(data.data.data.count);
      })
      .catch(function (err) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.message,
          confirmButtonText: "OK",
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          focusConfirm: true,
        });
      });
  }

  const [compList, setCompList] = useState([]);

  async function CompanyList() {
    await apiListCompany({ paginate: 0 }).then((result) =>
      setCompList(result?.data?.data.list)
    );
  }

  const companyListMap = compList?.flatMap((el) => {
    return {
      value: el?.comp_id,
      label: el.comp_name,
      is_active: el.is_active,
    };
  });

  useEffect(
    function () {
      if (user.role_id === 1) {
        CompanyList();
      }
    },
    [user]
  );

  useEffect(
    function () {
      if (!isFilterCall) {
        getList(true);
      }
    },
    [currentPage]
  );

  function handleInputs(e) {
    if (e.target.name === "user_mob") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormInputs({ ...formInputs, [e.target.id]: e.target.value });
      }
    } else {
      setFormInputs({ ...formInputs, [e.target.id]: e.target.value });
    }
  }

  function handleFilterVisibility() {
    if (filtersVisible) {
      setFiltersVisible(false);
      setFormInputs(initialState);
    } else {
      setFiltersVisible(true);
    }
  }

  return (
    <>
      <div className="d-flex min-vh-100" style={{ background: "#DAE5FF" }}>
        <Sidebar />
        <div className="content m-1 ms-1 p-4">
          <Header />

          <main className="bg-white rounded-4" id="user">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col">
                  <Breadcrumb path={["User"]} />
                </div>
                <div className="col text-end">
                  <Tippy content="Filter">
                    <img
                      src={filter}
                      onClick={() => handleFilterVisibility()}
                      className="img-fluid me-3"
                      alt=""
                      role="button"
                      style={{ width: "2.5rem" }}
                    />
                  </Tippy>

                  <Link
                    onClick={(v) => {
                      handleShow();
                      setShowId(1);
                    }}
                    className="btn btn-primary px-4 py-2">
                    Add User
                  </Link>
                </div>
              </div>
              {filtersVisible && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const data = () => {
                      setIsFilterCall(true);
                      getList(true, 1);
                      setCurrentPage(1);
                    };
                    setIsSubmit(true);
                    currentPage > 1 ? data() : getList(true, 1);
                  }}
                  autoComplete="off">
                  <div className="row filter">
                    <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="user_name">User Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="user_name"
                          value={formInputs.user_name}
                          onChange={handleInputs}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="user_name">Mobile Number</label>
                        <input
                          type="text"
                          className="form-control"
                          maxLength={10}
                          name="user_mob"
                          id="user_mob"
                          value={formInputs.user_mob}
                          onChange={handleInputs}
                        />
                        {filterError && filterError.user_mob && (
                          <>
                            <div className="text-danger small">
                              {filterError?.user_mob}{" "}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="status">Status</label>
                        <select
                          className="form-select"
                          id="is_active"
                          name="is_active"
                          value={formInputs.is_active}
                          onChange={(e) =>
                            setFormInputs({
                              ...formInputs,
                              is_active: e.target.value,
                            })
                          }
                        >
                          {[
                            { id: "", name: "All" },
                            { id: 1, name: "Active" },
                            {
                              id: 0,
                              name: "In-Active",
                            },
                          ].map((v, i) => (
                            <option key={i} value={v.id}>
                              {v.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className="col-xxl-3 col-xl-3 col-lg-6  col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="is_active">Select Status</label>
                        <Select
                          value={
                            status.find(
                              (el) => el?.value === formInputs.is_active
                            ) || ""
                          }
                          options={status}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: "40px",
                              borderRadius: "8px",
                            }),

                            input: (baseStyles, state) => ({
                              ...baseStyles,
                              paddingLeft: "0px",
                            }),
                          }}
                          classNamePrefix="react-select"
                          tabSelectsValue
                          onChange={(selectedOption) => {
                            setFormInputs({
                              ...formInputs,
                              is_active: selectedOption.value,
                            });
                          }}
                          name="is_active"
                          id="is_active"
                        />
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-4 col-lg-6  col-sm-12 ">
                      <label className="d-none d-xxl-block d-xl-none d-lg-block">
                        {" "}
                        &nbsp;
                      </label>
                      <div className="mb-3">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={isSubmit}>
                          {isSubmit && (
                            <span className="spinner-border spinner-border-sm me-2"></span>
                          )}
                          Search
                        </button>
                        <button
                          className="btn btn-primary rounded-3 px-3 ms-2"
                          type="button"
                          onClick={(e) => {
                            setFormInputs(initialState);
                            currentPage > 1
                              ? setCurrentPage(1)
                              : getList(false, 1);
                          }}
                          style={{ background: "#4263eb", fontSize: "14px" }}>
                          Clear Filters
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              {error && (
                <div className="row mt-3">
                  <div className="col">
                    <div
                      className={`alert alert-danger py-1 small`}
                      role="alert">
                      {error || ""}
                    </div>
                  </div>
                </div>
              )}

              <div className="row mb-4">
                <div className="col">
                  <div className="table-responsive">
                    <table
                      className={`table ${error ? "my-1" : "mt-4"} rounded`}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Mobile</th>
                          {user.role_id === 1 && <th>Company Name</th>}
                          <th>Role</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(isLoading || list.length === 0) && (
                          <tr>
                            <td colSpan="7" className="text-center">
                              {isLoading ? "Loading..." : "No Records"}
                            </td>
                          </tr>
                        )}
                        {list.map((v, i) => {
                          // if(v.role_id !== 1){
                          return (
                            <tr key={`ul_` + i}>
                              <td>{currentPage * 10 - 10 + i + 1}</td>
                              <td>
                                {/* <span title={v?.user_name}>
                                  {v?.user_name?.toString()?.length > 20 ? (
                                    <>
                                      {v?.user_name
                                        ?.toString()
                                        ?.substring(0, 20)}
                                      ...
                                    </>
                                  ) : (
                                    <> {v?.user_name}</>
                                  )}
                                </span> */}
                                {v?.user_name?.length > 20 ? (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip_` + i}>
                                        {v?.user_name}
                                      </Tooltip>
                                    }>
                                    <span>
                                      {v?.user_name?.length > 20
                                        ? v?.user_name?.substring(0, 20) + "..."
                                        : v?.user_name}{" "}
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  v?.user_name
                                )}
                              </td>
                              <td>{v.user_mob}</td>
                              {user.role_id === 1 && (
                                <td>{v?.mst_comp?.comp_name}</td>
                              )}
                              <td>
                                {config.roleListIng.find(
                                  (list) => list.id === v.role_id
                                )?.title || "N/A"}
                              </td>
                              <td>
                                {v.user_id !== user.user_id ? (
                                  <Tippy content="Change Status">
                                    {v.is_active ? (
                                      <span
                                        className="badge text-bg-success"
                                        role="button"
                                        onClick={() =>
                                          changeStatus(v.user_id, 0)
                                        }>
                                        Active
                                      </span>
                                    ) : (
                                      <span
                                        className="badge text-bg-danger"
                                        role="button"
                                        onClick={() =>
                                          changeStatus(v.user_id, 1)
                                        }>
                                        In-Active
                                      </span>
                                    )}
                                  </Tippy>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                <Tippy content="View" trigger="mouseenter">
                                  <a
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setEditId(v);
                                      handleShow();
                                      setShowId(3);
                                    }}>
                                    <img
                                      src={view}
                                      className="img-fluid me-2"
                                      alt=""
                                    />
                                  </a>
                                </Tippy>
                                {v.user_id !== user.user_id ? (
                                  <Tippy content="Edit" trigger="mouseenter">
                                    <Link
                                      // to={`/user/edit/` + v.user_id}
                                      onClick={() => {
                                        setEditId(v);
                                        handleShow();
                                        setShowId(2);
                                      }}
                                      className={`link-info ${
                                        v.is_active ? "" : "d-none"
                                      }`}>
                                      <img
                                        src={editIcon}
                                        className="img-fluid me-2"
                                        alt=""
                                      />
                                    </Link>
                                  </Tippy>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                          // }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <Pagination
                    // nPages={Math.ceil(list.length / 10)}
                    nPages={Math.ceil(totalPage / 10)}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
              <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                backdrop="static">
                {showId == 1 ? (
                  <UserAdd
                    id={editId}
                    list={getList}
                    hide={handleClose}
                    roles={roleList}
                    companyListMap={companyListMap}
                  />
                ) : showId == 2 ? (
                  <UserEdit
                    id={editId}
                    list={getList}
                    hide={handleClose}
                    roles={roleList}
                    companyListMap={companyListMap}
                  />
                ) : showId == 3 ? (
                  <UserEditView
                    id={editId}
                    list={getList}
                    hide={handleClose}
                    companyListMap={companyListMap}
                  />
                ) : null}
              </Modal>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default UserList;
