import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import NavBar from "../../components/partials/Navbar";
import Footer from "../../components/partials/Footer";
import imgAbout from "./about.jpg";
import logo from "../../images/icons/2.jpg";
import { FaWhatsapp } from "react-icons/fa";

const About = () => {
  const [user, setUser] = useState({});
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("go-ticket");
    if (storedUser) {
      setUser(JSON.parse(storedUser).userData);
    } else {
      setUser(null);
    }
  }, []);

  const subHeaderStyle = {
    height: "50vh",
    width: "100%",
    // backgroundImage:
    //   "linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)), url(Images/GIP_HBG.png)",
    background: "#fff3f3",
    backgroundPosition: "center",
    backgroundSize: "cover",
    textAlign: "center",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  const subHeaderTitleStyle = {
    color: "#f44336",
  };

  const aboutUsStyle = {
    width: "80%",
    margin: "auto",
    paddingTop: "80px",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "space-between",
  };

  const aboutColStyle = {
    flexBasis: "48%",
    padding: "30px 2px",
  };

  const aboutColImageStyle = {
    flexBasis: "50%",
  };

  const aboutColTextStyle = {
    padding: "15px 0 25px",
    color: "#000000",
    wordSpacing: "normal",
    letterSpacing: "normal",
  };

  const buttonStyle = {
    border: `2px solid ${isHovered ? "#00AB66" : "#00AB66"}`,
    background: isHovered ? "#00AB66" : "#00AB66",
    color: "#fff",
    padding: "12px 34px",
    fontSize: "16px",
    textDecoration: "none",
    cursor: "pointer",
    transition: "all 0.3s ease",
    fontWeight: "400",
  };

  // Function to open WhatsApp with a predefined message
  const openWhatsApp = () => {
    const phoneNumber = "+917710058282";
    const message = "Hi";
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div>
      <NavBar user={user} />
      <section style={subHeaderStyle}>
        <img
          src={logo}
          alt=""
          style={{
            position: "absolute",
            top: "100px",
            left: "50px",
            height: "150px",
            width: "150px",
            borderRadius: "10px",
            boxShadow: "0 0 2px gray",
          }}
        />
        <h1 style={subHeaderTitleStyle}>ABOUT US</h1>
      </section>

      <section style={aboutUsStyle}>
        <div style={aboutColStyle}>
          <h1>goInvite.pro is a Software As A Solution (SAAS)</h1>
          <p style={aboutColTextStyle}>
            goInvite.pro is a unit of Burange MediaWorks Pvt. Ltd. It is super
            easy to use for Event Crew, Team Management and Event Registration.
            It takes away all the hassle of Event Registrations and Team/Crew
            Management from Event Organisers, Banquets and Hotels handling their
            event guests & event crew. We create QR Coded and Easily
            Customizable Invitations and Event Crew Batches/ID Cards for any
            Event (Private and Government), Exhibition Invitation, Registration
            & Event Team Management needs. The best part of this Solution is
            that you (the End User/ Guest or Event Crew) do not need to download
            any separate Application or Software; it simply works with WhatsApp
            Messaging App.
          </p>

          <NavLink
            // to="/contact"
            style={buttonStyle}
            onClick={openWhatsApp}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            let's Connect <FaWhatsapp />
          </NavLink>
        </div>
        <div style={aboutColImageStyle}>
          <img src={imgAbout} alt="About" style={{ width: "100%" }} />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;
