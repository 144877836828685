import { Link, NavLink, useLocation } from "react-router-dom";
import "./style.css";
// import logo from "../../images/icons/logo.png";
import dashboard from "../../images/icons/dashboard.png";
import settings from "../../images/icons/settings.png";
import userPic from "../../images/icons/user_pic.png";
import { useContext, useEffect, useState } from "react";
import { capitalize } from "../../utils";
import { confirmAlert } from "react-confirm-alert";
import config from "../../config";
import Modal from "react-bootstrap/Modal";
import { ReportContext } from "../../App";
import { ArrowLeftShort } from "react-bootstrap-icons";

function Sidebar(props) {
  const [user, setUser] = useState({});
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const reportList = useContext(ReportContext);

  useEffect(function () {
    if (localStorage.getItem("go-ticket")) {
      setUser(JSON.parse(localStorage.getItem("go-ticket")).userData);
    }
  }, []);

  function changeStatus() {
    confirmAlert({
      title: "Are you sure?",
      message: "You want to logout.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (localStorage.getItem("go-ticket")) {
              localStorage.removeItem("go-ticket");
            }
            window.location.href = config.baseUrl;
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  const links = {
    dashboard: {
      label: "Dashboard",
      href: "/dashboard",
      icon: dashboard,
      isVisible: [2, 3, 4, 5],
    },
    masters: [
      {
        label: "Company",
        href: "/company",
        icon: settings,
        isVisible: [1],
        showList: [1, 2, 3, 4, 5],
      },
      {
        label: "User",
        href: "/user",
        icon: settings,
        isVisible: [1, 2, 3],
        showList: [1, 2, 3, 4, 5],
      },
      {
        label: "Events",
        href: "/events",
        icon: settings,
        isVisible: [2, 3],
        showList: [1, 2, 3, 4, 5],
      },
      {
        label: "Event Area",
        href: "/event-area",
        icon: settings,
        isVisible: [2, 3],
        showList: [1, 2, 3, 4, 5],
      },
      {
        label: "Guest Type",
        href: "/guestType",
        icon: settings,
        isVisible: [2, 3],
        showList: [1, 2, 3, 4, 5],
      }, // {
      //   label: "Member Iteam",
      //   href: "/view",
      //   icon: settings,
      //   isVisible: [2, 3],
      //   showList: [1, 2, 3, 4, 5],
      // },
      {
        label: "Guests",
        href: "/guests",
        icon: settings,
        isVisible: [2, 3],
        showList: [1, 2, 3, 4, 5],
      },

      // {label: "Item", href: "/item", icon: settings, isVisible: [2, 3, 4], showList: [1, 2, 3, 4, 5]},
      // {label: "Supplier", href: "/buyer", icon: settings, isVisible: [2, 3, 4], showList: [1, 2, 3, 4, 5]},
      // {label: "Branch", href: "/branch", icon: settings, isVisible: [2], showList: [1, 2, 3, 4, 5]},
      // {label: "Godown", href: "/godown", icon: settings, isVisible: [2, 3, 4], showList: [1, 2, 3, 4, 5]},
      // {label: "Customer", href: "/customer", icon: settings, isVisible: [2, 3, 4], showList: [1, 2, 3, 4, 5]},
      // {label: "Item Category", href: "/item-category", icon: settings, isVisible: [2, 3, 4], showList: [1, 2, 3, 4, 5]},
    ],

    team: {
      label: "Team",
      href: "/team",
      icon: settings,
      isVisible: [2, 3],
    },
    reports: reportList.map((report) => {
      return {
        label: report.title,
        href: "/reports/" + report.report_id,
        icon: settings,
        isVisible: [2, 3, 4],
        showList: [2, 3, 4],
      };
    }),
    logout: {
      label: "Logout",
      href: "/logout",
      icon: settings,
      isVisible: [1, 2, 3, 4, 5],
    },
  };

  const path = {
    masters: links.masters.map((v) => v.href), // purchases: links.purchases.map((v) => v.href),
    // challan: links.challan.map((v) => v.href),
    // stock: links.stock.map((v) => v.href),
    // expenses: links.expenses.map((v) => v.href),
    // reports: links.reports.map((v) => v.href),
    reports: links.reports.map((v) => v.href),
  };

  return (
    <>
      <nav className="sidebar m-1 p-4" id="sidebar">
        <Link to={"/"} className="text-decoration-none text-white">
          <ArrowLeftShort size={24} /> Go to Home
        </Link>
        <ul className="nav nav-pills flex-column flex-row">
          <li className="py-3 d-flex align-items-center">
            <img
              src={userPic}
              className="img-fluid me-2"
              alt=""
              role="button"
            />
            <div>
              <p className="text-light fw-lighter mb-1">Account</p>
              <p className="text-light fw-bolder">
                Hi, {user?.user_name || "Guest"}
              </p>
            </div>
            {/*<div className="d-flex align-items-center mar " style={{width: "5rem", marginLeft: "0.8rem"}}>
                            <img src={notification} alt="" data-bs-auto-close="true" role="button"/>
                        </div>*/}
          </li>
          <hr className="mt-0" />
          {Object.keys(links).map((ov, oi) => {
            return (
              <>
                {Array.isArray(links[ov]) ? (
                  <>
                    {links[ov][0]?.showList?.includes(user?.role_id) ? (
                      <li className={`nav-item my-1`}>
                        <a
                          className="flex-fill nav-link rounded-0 py-2 text-light"
                          data-bs-toggle="collapse"
                          href={`#collapse_${ov}`}
                          role="button"
                        >
                          <img
                            src={settings}
                            className="img-fluid pe-2"
                            alt=""
                          />
                          {capitalize(ov)}
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    <ul
                      className={`collapse list-unstyled ${path[ov].includes(pathname) ||
                          path[ov].includes("/" + pathname.split("/")[1])
                          ? "show"
                          : ""
                        }`}
                      id={`collapse_${ov}`}
                    >
                      {links[ov].map((iv, ii) => {
                        if (iv.isVisible.includes(user?.role_id)) {
                          return (
                            <>
                              <li className="nav-item ms-3">
                                <NavLink
                                  to={iv.href}
                                  className="flex-fill nav-link rounded-0 py-2 text-light"
                                >
                                  <img
                                    src={iv.icon}
                                    className="img-fluid pe-2"
                                    alt=""
                                  />
                                  {iv.label}
                                </NavLink>
                              </li>
                            </>
                          );
                        } else {
                        }
                      })}
                    </ul>
                  </>
                ) : (
                  <>
                    {links[ov]?.isVisible?.includes(user?.role_id) && (
                      <li className="nav-item my-1">
                        {/* <button  onClick={()=>{
                      if (links[ov].label === "Logout") {
                        changeStatus();
                      }else{
                        window.location.href = `/mpos/expense`
                      }
                    }} className="flex-fill nav-link rounded-0 py-2 text-light">
                    <img
                        src={links[ov].icon}
                        className="img-fluid pe-2"
                        alt=""
                      />
                      {links[ov].label}
                    </button> */}

                        <NavLink
                          onClick={() => {
                            if (links[ov].label === "Logout") {
                              setShow(!show);
                            } else {
                              return;
                            }
                          }}
                          to={
                            links[ov].label === "Logout" ? null : links[ov].href
                          }
                          className="flex-fill nav-link rounded-0 py-2 text-light"
                        >
                          <img
                            src={links[ov].icon}
                            className="img-fluid pe-2"
                            alt=""
                          />
                          {links[ov].label}
                        </NavLink>
                      </li>
                    )}
                  </>
                )}
              </>
            );
          })}
        </ul>
      </nav>

      <Modal
        show={show}
        onHide={() => setShow(!show)}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h4
            style={{ marginLeft: "5rem", height: "4px", textAlign: "center" }}
            id="modalhead"
          >
            Are you sure you want to log Out ?
          </h4>
        </Modal.Header>
        <div style={{ textAlign: "center", padding: "30px" }}>
          <button
            style={{
              marginRight: "22px",
              borderRadius: "8px",
              fontsize: "14px",
            }}
            onClick={() => setShow(!show)}
            type="button"
            className="btn btn-secondary"
            data-mdb-dismiss="modal"
          >
            CANCEL
          </button>
          <button
            style={{ backgroundColor: "#3734FE", borderRadius: "8px" }}
            onClick={() => {
              if (localStorage.getItem("go-ticket")) {
                localStorage.removeItem("go-ticket");
              }
              window.location.href = config.baseUrl;
            }}
            type="button"
            className="btn btn-primary"
          >
            LOGOUT
          </button>
        </div>
        {/* <div
          className="modal top fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
         >
          <div className="modal-dialog   modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Log Out
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-mdb-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div className="modal-body">
                Are you sure you want to log-off?
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => setShow(!show)}
                  type="button"
                  className="btn btn-secondary"
                  data-mdb-dismiss="modal">
                  Close
                </button>
                <button
                  onClick={() => {
                    if (localStorage.getItem("go-ticket")) {
                      localStorage.removeItem("mPOS");
                    }
                    window.location.href = config.baseUrl;
                  }}
                  type="button"
                  className="btn btn-primary">
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </Modal>
    </>
  );
}

export default Sidebar;
