import Input from "./Input";
import Select from "./Select";

const Filter = (props) => {
  const filters = props.filters;
  const errors = props.errors;
  const handler = props.handler;
  const show = props.show;
  const filterData = props.filterData;
  const isclear = props.isclear;
  const reportId = props.reportId;

  const handleInput = (e) => {
    console.log("e.target.value", e.target.value);
    props.setFilterData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  console.log("errors", errors);

  return (
    <>
      <div className={`row mb-4 ${show ? "" : "d-none"}`}>
        {filters?.map((filter) => (
          <>
            {["text"].includes(filter?.f_type) && (
              <Input
                data={filter}
                error={errors?.[filter?.f_name]}
                state={props.filterData}
                handleInput={handleInput}
              />
            )}
            {["date"].includes(filter?.f_type) && (
              <Input
                data={filter}
                error={errors?.[filter?.f_name]}
                state={props.filterData}
                handleInput={handleInput}
              />
            )}
            {["select"].includes(filter?.f_type) && (
              <Select
                data={filter}
                error={errors?.[filter?.f_name]}
                state={props.filterData}
                handleInput={handleInput}
                filterData={filterData}
                isclear={isclear}
                reportId={reportId}
              />
            )}
          </>
        ))}
        <div className="col-md mt-4">
          <button
            className="btn btn-primary mx-1"
            type="button"
            onClick={(e) => handler.searchReportData()}
          >
            Search
          </button>
          <button
            className="btn btn-primary mx-1"
            type="button"
            onClick={(e) => handler.clearReportData()}
          >
            Clear
          </button>
        </div>
      </div>
    </>
  );
};

export default Filter;
