import React from "react";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
import view from "../../images/view.png";
import editIcon from "../../images/icons/edit.png";
import addIcon from "../../images/icons/add.png";
import deleteIcon from "../../images/icons/delete.png";

const ActionMenuItem = ({ data, context }) => {
  return (
    <>
      <Tippy content="View" trigger="mouseenter">
        <img
          id="isView"
          onClick={(e) => {
            context.onClickActionItemClick(data, e);
            console.log(e);
          }}
          src={view}
          className="img-fluid me-2"
          alt=""
        />
      </Tippy>

      {context.editAccess.map((e, i) => {
        return (
          <React.Fragment key={"at_" + i}>
            {e === context.userRole.userRole && data.is_active === true && (
              <Tippy content="Edit" trigger="mouseenter">
                <Link
                  id="isEdit"
                  onClick={(e) => context.onClickActionItemClick(data, e)}>
                  <img src={editIcon} className="img-fluid me-2" alt="" />
                </Link>
              </Tippy>
            )}
          </React.Fragment>
        );
      })}
      {context.editAccess.map((e, i) => {
        return (
          <React.Fragment key={"at_" + i}>
            {e === context.userRole.userRole && data.is_active === true && (
              <Tippy content="Assign User" trigger="mouseenter">
                <Link
                  id="addMember"
                  onClick={(e) => context.onClickActionItemClick(data, e)}>
                  <img src={addIcon} className="img-fluid me-2" alt="" />
                </Link>
              </Tippy>
            )}
          </React.Fragment>
        );
      })}
      {context.editAccess.map((e, i) => {
        return (
          <React.Fragment key={"at_" + i}>
            {e === context.userRole.userRole && data.is_active === true && (
              <Tippy content="Delete Event" trigger="mouseenter">
                <Link
                  id="isDelete"
                  onClick={(e) => context.onClickActionItemClick(data, e)}>
                  <img src={deleteIcon} className="img-fluid me-2" alt="" />
                </Link>
              </Tippy>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
export default ActionMenuItem;
