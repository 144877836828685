import home from "../images/icons/home.png"
import "./style.css"

function Breadcrumb(props) {
    return (
        <>
            <nav id="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <div className="d-flex align-items-center">
                            <img src={home} alt="" className="img-fluid me-2"/>
                            <span className={`${props?.path?.length == 0 ? `active` : ''}`}>Home</span>
                        </div>
                    </li>
                    {props.path.map((v, i) => {
                        return <li key={"bc_" + i} className="breadcrumb-item active">{v}</li>;
                    })}
                </ol>
            </nav>
        </>
    );
}

export default Breadcrumb;