import React from "react";
// import cardImg from "../../images/Imagecard.png";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const EventCard = ({ event, is_past }) => {
  const eventDate = new Date(event?.st_dt);
  const day = eventDate.toLocaleDateString("en-US", {
    weekday: "short",
  });
  const date = eventDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  const time = eventDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  return (
    <Link
      to={`/event-details/${event?.event_id}`}
      className="text-decoration-none"
      state={{ event, is_past }}
    >
      <Card
        style={{ width: "18rem" }}
        className="m-2 shadow-none bg-theme-cardgray p-2"
      >
        <Card.Body className="bg-theme-cardgray rounded-2 on-hover d-flex flex-column justify-content-between gap-2">
          {/* <div className="pb-2 position-relative"> */}
          <Card.Img
            src={event?.event_banner}
            className="bg-theme-cardgray"
            width={20}
            height={128}
          />
          {/* <span
              className="badge-bg-lightpink position-absolute px-2 py-1 rounded-5 text-theme-pink"
              style={{ bottom: "9%", left: "6%", fontSize: "80%" }}
            >
              Concerts
            </span> */}
          {/* </div> */}
          <Card.Title className="text-black">{event?.name}</Card.Title>
          <Card.Text>
            <p className="text-theme-pink card-date mb-2">
              {day} • {date}, {time}
            </p>
            <p className="text-theme-gray mb-2 address-clamp">
              {event?.address}
            </p>
          </Card.Text>
          {!is_past && (
            <button className="bg-theme-pink text-white border-0 rounded-2 py-2">
              {event?.is_paid ? `₹ ${event?.amount} Onwards` : "Free"}
            </button>
          )}
        </Card.Body>
      </Card>
    </Link>
  );
};

export default EventCard;
